import { omit } from 'lodash'
import {
  SET_USER,
  VERIFY_USER,
  RESET_PASSWORD_SENT,
  CLEAR_RESET_PASSWORD_SENT,
  UPDATE_USER_IMAGE,
  UPDATE_USER_ACCOUNT_COMPANIES,
  DELETE_COMPANY,
  MARK_NOTIFICATION_READ,
  TURN_OFF_SOUND,
  TURN_ON_SOUND
} from '../actions/types'
import { normalizeUser } from '../utils/normalizers'

const initialState = {
  resetPasswordSent: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        account: normalizeUser(action.payload)
      }
    // case SET_ADVISOR_COMPANIES:
    //   return {
    //     ...state,
    //     advisorCompanies: normalizeCompanies(action.payload)
    //   }
    case VERIFY_USER: {
      return {
        ...state,
        account: {
          ...state.account,
          verified: true
        }
      }
    }
    case RESET_PASSWORD_SENT: {
      return {
        ...state,
        resetPasswordSent: true
      }
    }
    case CLEAR_RESET_PASSWORD_SENT: {
      return {
        ...state,
        resetPasswordSent: false
      }
    }
    case UPDATE_USER_IMAGE: {
      return {
        ...state,
        account: {
          ...state.account,
          settings: {
            ...state.account.settings,
            accountImageId: action.payload
          }
        }
      }
    }
    case TURN_OFF_SOUND: {
      return {
        ...state,
        account: {
          ...state.account,
          settings: {
            ...state.account.settings,
            soundEffectsOn: false
          }
        }
      }
    }
    case TURN_ON_SOUND: {
      return {
        ...state,
        account: {
          ...state.account,
          settings: {
            ...state.account.settings,
            soundEffectsOn: true
          }
        }
      }
    }
    case UPDATE_USER_ACCOUNT_COMPANIES: {
      return {
        ...state,
        account: updateCompany(state.account, action)
      }
    }
    case MARK_NOTIFICATION_READ: {
      return {
        ...state,
        account: markNotificationRead(state.account, action)
      }
    }
    case DELETE_COMPANY: {
      return {
        ...state,
        account: deleteCompany(state.account, action)
      }
    }
    default:
      return state
  }
}

function updateCompany(state, action) {
  return {
    ...state,
    companies: {
      ...state.companies,
      [action.payload.id]: action.payload
    }
  }
}

function markNotificationRead(state, action) {
  const update = state.notifications.map(n => {
    if (n._id === action.payload) {
      n.read = true
    }

    return n
  })
  return {
    ...state,
    notifications: update
  }
}

function deleteCompany(state, action) {
  const companies = omit(state.companies, action.payload.id)

  return {
    ...state,
    companies: {
      ...companies
    }
  }
}