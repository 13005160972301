import { push } from 'react-router-redux'
import { isEmpty, omit } from 'lodash'
import axios from 'axios'
import ReactGA from 'react-ga'
import {
  CLEAR_COMPANY,
  DELETE_COMPANY,
  SET_COMPANY,
  SET_USER,
  SET_PLAN,
  SET_FINANCIALS,
  SET_FORECASTS,
  SET_COMPANY_LOADING,
  UPDATE_USER_ACCOUNT_COMPANIES,
  SET_CANVAS,
} from './types'
import Query from '../graphql'
import { handleErrors } from '../utils/helpers'
import { normalizePlan, normalizeFinancials } from '../utils/normalizers'
import { getWebSocket } from '../utils/socket'
import { getResourceIdFromPath } from './lock.actions'
import { showFlashMessage } from './flash.actions'
import strings from '../assets/strings'
// import { setPublicShareContent } from './publicShare.actions'

export const setCompany = (id, redirectToDashboard = true) => {
  return async function (dispatch, getState, { graphqlClient }) {
    try {
      // call action to set loading company value in redux
      dispatch(setCompanyLoading(true))
      const {
        data: { getCompany },
      } = await graphqlClient.query({
        query: Query.getCompanyQuery,
        fetchPolicy: 'network-only',
        variables: {
          id,
        },
      })

      dispatch(setCompanyData(getCompany))
      if (redirectToDashboard) {
        dispatch(push('/dashboard'))
      }
      dispatch(setCompanyLoading(false))

      ReactGA.event({
        category: 'Company',
        action: 'Load',
      })
    } catch (error) {
      throw error
    }
  }
}

export const setCompanyAndViewPlan = (id, url) => {
  return async function (dispatch, getState, { graphqlClient }) {
    try {
      // call action to set loading company value in redux
      dispatch(setCompanyLoading(true))

      await graphqlClient.query({
        query: Query.getCompanyQuery,
        fetchPolicy: 'network-only',
        variables: {
          id,
        },
      })

      dispatch(push(url || `/dashboard/plan/view`))
      dispatch(setCompanyLoading(false))

      ReactGA.event({
        category: 'Company',
        action: 'Load',
      })
    } catch (error) {
      throw error
    }
  }
}

export function launchCompany() {
  return function (dispatch) {
    dispatch(push('/dashboard/company/launch'))
  }
}

export const createCompany = (values, onboarding) => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    const {
      name,
      settings: {
        industry,
        forecastStartMonth,
        forecastStartYear,
        forecastLength = 3,
        currency,
      },
    } = values

    const input = {
      name,
      settings: {
        industry,
        forecastStartMonth: parseInt(forecastStartMonth, 10),
        forecastStartYear: parseInt(forecastStartYear, 10),
        forecastLength: parseInt(forecastLength, 10),
        currency,
      },
    }

    try {
      const {
        data: { createCompany },
      } = await graphqlClient.mutate({
        mutation: Query.createCompanyMutation,
        variables: {
          input,
        },
      })

      dispatch({
        type: SET_USER,
        payload: createCompany,
      })

      const newCompany =
        createCompany.companies[createCompany.companies.length - 1]

      dispatch(setCompanyData(newCompany))

      dispatch(push('/dashboard'))

      resolve()
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const deleteCompany = id => (dispatch, getState, { graphqlClient }) => {
  return new Promise(async function (resolve, reject) {
    try {
      await graphqlClient.mutate({
        mutation: Query.deleteCompanyMutation,
        variables: {
          input: {
            companyId: id,
          },
        },
      })

      dispatch({
        type: DELETE_COMPANY,
        payload: {
          id,
        },
      })

      const userCompanies = getState().user.account.companies

      if (!isEmpty(userCompanies)) {
        const firstCompany = Object.keys(userCompanies)[0]

        dispatch(setCompanyData(userCompanies[firstCompany]))
      }

      dispatch(push('/dashboard'))

      ReactGA.event({
        category: 'Company',
        action: 'Delete',
      })

      resolve()
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const updateCompanySettings = values => (
  dispatch,
  getState,
  { graphqlClient, intl },
) => {
  return new Promise(async function (resolve, reject) {
    const state = getState()
    const input = Object.assign(
      omit(values, [
        'settings.forecastStart',
        'settings.logoId',
        'teamMembers',
      ]),
      { companyId: values.id },
    )

    try {
      const {
        data: { updateCompanySettings },
      } = await graphqlClient.mutate({
        mutation: Query.updateCompanySettingsMutation,
        variables: {
          input,
        },
      })

      dispatch(setCompanyData(updateCompanySettings))

      dispatch(
        showFlashMessage(
          intl(state).formatMessage(strings.companySettingsUpdated),
          'success',
        ),
      )

      ReactGA.event({
        category: 'Company',
        action: 'Update',
        label: 'Settings',
      })

      resolve()
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const uploadCompanyLogo = (userId, companyId, file) => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const formData = new FormData()
      formData.append('file', file)
      formData.append(
        'upload_preset',
        process.env.REACT_APP_CLOUDINARY_COMPANY_UPLOAD_PRESET,
      )

      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_NAME}/image/upload/`,
        formData,
      )

      const {
        data: { uploadCompanyLogo },
      } = await graphqlClient.mutate({
        mutation: Query.uploadCompanyLogo,
        variables: {
          input: {
            userId,
            companyId,
            logoId: response.data.public_id,
          },
        },
      })

      dispatch(setCompanyData(uploadCompanyLogo))

      ReactGA.event({
        category: 'Company',
        action: 'Update',
        label: 'Logo',
      })

      resolve(uploadCompanyLogo)
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const getShareContent = id => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { getShareContent },
      } = await graphqlClient.query({
        query: Query.getShareContent,
        fetchPolicy: 'network-only',
        variables: {
          id,
        },
      })

      dispatch(setCompanyData(getShareContent))

      ReactGA.event({
        category: 'Company',
        action: 'Public Share',
        label: 'View',
      })

      resolve(getShareContent)
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const getCanvasShareContent = id => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { getCanvasShareContent },
      } = await graphqlClient.query({
        query: Query.getCanvasShareContent,
        fetchPolicy: 'network-only',
        variables: {
          id,
        },
      })

      dispatch(setCompanyData(getCanvasShareContent))

      ReactGA.event({
        category: 'Company',
        action: 'Public Canvas Share',
        label: 'View',
      })

      resolve(getCanvasShareContent)
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const toggleCanvasSharing = id => (
  dispatch,
  getState,
  { graphqlClient, intl },
) => {
  return new Promise(async function (resolve, reject) {
    const state = getState()
    const userId = state.user.account.id
    try {
      const {
        data: { toggleCanvasSharing },
      } = await graphqlClient.mutate({
        mutation: Query.toggleCanvasSharing,
        variables: {
          input: {
            id: userId,
            companyId: id,
          },
        },
      })

      dispatch(setCompanyData(toggleCanvasSharing))

      ReactGA.event({
        category: 'Company',
        action: 'Update',
        label: 'Public Sharing',
      })

      dispatch(
        showFlashMessage(
          intl(state).formatMessage(strings.companySettingsUpdated),
          'success',
        ),
      )

      resolve(toggleCanvasSharing)
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const toggleSharingEnabled = id => (
  dispatch,
  getState,
  { graphqlClient, intl },
) => {
  return new Promise(async function (resolve, reject) {
    const state = getState()
    const userId = state.user.account.id
    try {
      const {
        data: { toggleSharingEnabled },
      } = await graphqlClient.mutate({
        mutation: Query.toggleSharingEnabled,
        variables: {
          input: {
            id: userId,
            companyId: id,
          },
        },
      })

      dispatch(setCompanyData(toggleSharingEnabled))

      ReactGA.event({
        category: 'Company',
        action: 'Update',
        label: 'Public Sharing',
      })

      dispatch(
        showFlashMessage(
          intl(state).formatMessage(strings.companySettingsUpdated),
          'success',
        ),
      )

      resolve(toggleSharingEnabled)
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const inviteTeamMember = values => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    const { email, companyId } = values

    try {
      const {
        data: { inviteTeamMember },
      } = await graphqlClient.mutate({
        mutation: Query.inviteTeamMember,
        variables: {
          input: {
            email,
            companyId,
          },
        },
      })

      dispatch(setCompanyData(inviteTeamMember))

      ReactGA.event({
        category: 'Company',
        action: 'Team Member',
        label: 'Invite',
      })

      resolve(inviteTeamMember)
    } catch (err) {
      reject(handleErrors(err))
    }
  })
}

export const removeTeamMember = (id, companyId) => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { removeTeamMember },
      } = await graphqlClient.mutate({
        mutation: Query.removeTeamMember,
        variables: {
          input: {
            id,
            companyId,
          },
        },
      })

      dispatch(setCompanyData(removeTeamMember))

      ReactGA.event({
        category: 'Company',
        action: 'Team Member',
        label: 'Remove',
      })

      resolve(removeTeamMember)
    } catch (err) {
      reject(handleErrors(err))
    }
  })
}

export const removeSelfAsTeamMember = companyId => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { removeSelfAsTeamMember },
      } = await graphqlClient.mutate({
        mutation: Query.removeSelfAsTeamMember,
        variables: {
          input: {
            companyId,
          },
        },
      })

      dispatch(push('/dashboard'))
      dispatch({
        type: SET_USER,
        payload: removeSelfAsTeamMember,
      })

      ReactGA.event({
        category: 'Company',
        action: 'Team Member',
        label: 'Remove Self',
      })

      if (
        Array.isArray(removeSelfAsTeamMember.companies) &&
        removeSelfAsTeamMember.companies.length
      ) {
        dispatch(setCompanyData(removeSelfAsTeamMember.companies[0]))
      } else {
        dispatch(cleanCompanyData())
      }

      resolve(removeSelfAsTeamMember)
    } catch (err) {
      reject(handleErrors(err))
    }
  })
}

export const changeCompanyOwner = (teamMemberId, companyId) => (
  dispatch,
  getState,
  { graphqlClient, intl },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { changeCompanyOwner },
      } = await graphqlClient.mutate({
        mutation: Query.changeCompanyOwner,
        variables: {
          input: {
            teamMemberId,
            companyId,
          },
        },
      })

      const {
        data: { me },
      } = await graphqlClient.query({
        query: Query.meQuery,
        fetchPolicy: 'network-only',
      })

      dispatch({
        type: SET_USER,
        payload: me,
      })

      ReactGA.event({
        category: 'Company',
        action: 'Change Owner',
      })

      dispatch(setCompanyData(changeCompanyOwner))

      const state = getState()
      dispatch(
        showFlashMessage(
          intl(state).formatMessage(strings.companyOwnerUpdatedSuccessfully),
          'success',
        ),
      )

      resolve(changeCompanyOwner)
    } catch (err) {
      reject(handleErrors(err))
    }
  })
}

export const acceptInvite = inviteId => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { acceptInvite },
      } = await graphqlClient.mutate({
        mutation: Query.acceptInvite,
        variables: {
          input: {
            inviteId,
          },
        },
      })

      ReactGA.event({
        category: 'Company',
        action: 'Join Company Invite',
        label: 'Accepted',
      })

      resolve(acceptInvite)
    } catch (err) {
      reject(handleErrors(err))
    }
  })
}

export const setCompanyLoading = value => {
  return function (dispatch, getState) {
    dispatch({
      type: SET_COMPANY_LOADING,
      payload: value,
    })
  }
}

export const setCompanyData = company => {
  return function (dispatch, getState) {
    const routerState = getState().router

    // Not needed when setting company data for public share.
    // TODO - separate the redux state for public share view
    if (routerState && routerState.location && !routerState.location.pathname.includes('share')) {
      const options = {
        companyId: company.id,
        userId: getState().user.account.id,
        resourceId: getResourceIdFromPath(routerState && routerState.location),
      }

      getWebSocket(options).joinCompany(company.id, getState().user.account.id)

      dispatch({
        type: UPDATE_USER_ACCOUNT_COMPANIES,
        payload: company,
      })
    }

    dispatch({
      type: SET_COMPANY,
      payload: company,
    })

    const normalizedPlan = normalizePlan(company.plan)

    const normalizedFinancials = normalizeFinancials(company.financials)

    dispatch({
      type: SET_PLAN,
      payload: normalizedPlan,
    })

    dispatch({
      type: SET_CANVAS,
      payload: company.canvas,
    })

    dispatch({
      type: SET_FINANCIALS,
      payload: normalizedFinancials,
    })

    dispatch({
      type: SET_FORECASTS,
      payload: company.forecasts,
    })
  }
}

export const cleanCompanyData = () => {
  return function (dispatch) {
    dispatch({
      type: CLEAR_COMPANY,
    })
  }
}
