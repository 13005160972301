import { defineMessages } from 'react-intl'

const tours = defineMessages({
  tourCardPlanNav: {
    id: 'tours.tourCardPlanNav',
    defaultMessage: 'Navigate around the different sections of this chapter using these tabs or you can go back to the chapter overview.'
  },
  tourCardPlanInfo: {
    id: 'tours.tourCardPlanInfo',
    defaultMessage: 'You can view specific infomation about the business plan section here as well as change the section name if you need to.'
  },
  tourCardCanvasNav: {
    id: 'tours.tourCardCanvasNav',
    defaultMessage: 'Navigate around the different sections of your business model Canvas using these tabs or you can go back to the Canvas overview.'
  },
  tourCardCanvasInfo: {
    id: 'tours.tourCardCanvasInfo',
    defaultMessage: 'You can view specific infomation about the selected Canvas section.'
  },
  tourCardSave: {
    id: 'tours.tourCardSave',
    defaultMessage: 'As you type into the editor, your work is automatically saved. You can see the latest status here and close the editor.'
  },
  tourCardCanvasEditor: {
    id: 'tours.tourCardCanvasEditor',
    defaultMessage: 'Our editor for entering your info for each section.'
  },
  tourCardPlanEditor: {
    id: 'tours.tourCardPlanEditor',
    defaultMessage: 'Our canvas for writing your business plan.'
  },
  tourCardComplete: {
    id: 'tours.tourCardComplete',
    defaultMessage: `When you've completed a section, mark it complete.`
  },
  tourCardExample: {
    id: 'tours.tourCardExample',
    defaultMessage: 'Examples for this section are displayed here.'
  },
  tourFinancialsNav: {
    id: 'tours.tourFinancialsNav',
    defaultMessage: 'These are the three financial statements we will create forecasts for. Access each one using these tabs.'
  },
  tourFinancialsForms: {
    id: 'tours.tourFinancialsForms',
    defaultMessage: 'Each financial statement has different types of financial data you can enter to create these forecasts. Click start on a section to get started adding financial data. As you enter information, you will see the statements and accompanying charts appear.'
  },
  tourFinancialsStartingBalances: {
    id: 'tours.tourFinancialsStartingBalances',
    defaultMessage: 'Set your starting balances as a basis for your financial forecasts.'
  },
  tourFinancialsAssumptions: {
    id: 'tours.tourFinancialsAssumptions',
    defaultMessage: 'Set your different financial assumptions as a basis for your financial forecasts.'
  },
  tourPlanChapters: {
    id: 'tours.tourPlanChapters',
    defaultMessage: 'Your plan is made up of chapters which you can navigate through using these tabs.'
  },
  tourPlanSections: {
    id: 'tours.tourPlanSections',
    defaultMessage: `Each chapter in your plan is made up of different sections displayed here. Each section's tile allows you access to view and edit that section.`
  },
  tourPlanChapterAdd: {
    id: 'tours.tourPlanChapterAdd',
    defaultMessage: 'Create and add new chapters to your business plan.'
  },
  tourPlanChapterOrder: {
    id: 'tours.tourPlanChapterOrder',
    defaultMessage: 'Change the order a chapter appears in your plan here.'
  },
  tourPlanSectionAdd: {
    id: 'tours.tourPlanSectionAdd',
    defaultMessage: 'Create and add a new section to the currently selected chapter here.'
  },
  tourCanvas: {
    id: 'tours.tourCanvas',
    defaultMessage: `Get started building out your business model canvas to show off the business's value with a glance.`
  },
  tourPlan: {
    id: 'tours.tourPlan',
    defaultMessage: `When you're ready to start writing the content of your business plan, pick a section and begin.`
  },
  tourFinancials: {
    id: 'tours.tourFinancials',
    defaultMessage: `Build out your company's financial projections and statements. You supply the details and we do the rest.`
  },
  tourCompanySettings: {
    id: 'tours.tourCompanySettings',
    defaultMessage: `Edit and update your company's settings. You can add collaborators and share your plan online.`
  },
})

export default tours
