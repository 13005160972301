import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import strings from '../../assets/strings'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '../buttons/AppButton'
import Logo from '../../assets/images/logo_vertical'

const styles = theme => ({
  center: {
    position: 'absolute',
    paddingBottom: '25vh',
    top: '15vh',
    left: '50vw',
    transform: 'translateX(-50%)',
    textAlign: 'center',
  },
  paper: {
    padding: '3rem',
  },
  heading: {
    color: theme.palette.primary.main,
    fontSize: '4rem',
  },
  subheading: {
    marginBottom: '2rem',
  },
  text: {
    marginBottom: '2rem',
  },
})

class NotFound extends Component {
  render() {
    const { classes, intl } = this.props

    return (
      <div className={classes.center}>
        <Logo />
        <Paper className={classes.paper}>
          <Typography variant="headline" className={classes.heading}>
            404
          </Typography>
          <Typography variant="headline" className={classes.subheading}>
            Four Oh Four
          </Typography>
          <Typography variant="body2" className={classes.text}>
            {intl.formatMessage(strings.notFoundErrorMessage)}
          </Typography>
          <Button component={Link} to="/">
            {intl.formatMessage(strings.backToHomepage)}
          </Button>
        </Paper>
      </div>
    )
  }
}

export default withStyles(styles)(injectIntl(NotFound))
