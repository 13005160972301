import gql from 'graphql-tag'
import * as Fragments from '../fragments'

export const signinMutation = gql`
  mutation signin($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      ok
      me {
        ...MeFragment
      }
    }
  }
  ${Fragments.MeFragment}
`

export const signupMutation = gql`
  mutation signup($input: SignupInput) {
    signup(input: $input) {
      ...UserPayloadFragment
    }
  }
  ${Fragments.UserPayloadFragment}
`

export const setSubscriptionMutation = gql`
  mutation setSubscription($input: SubscriptionInput) {
    setSubscription(input: $input) {
      ...MeFragment
    }
  }
  ${Fragments.MeFragment}
`

export const updateSubscriptionMutation = gql`
  mutation updateSubscription($input: SubscriptionInput) {
    updateSubscription(input: $input) {
      ...MeFragment
    }
  }
  ${Fragments.MeFragment}
`

export const uploadUserImage = gql`
  mutation uploadUserImage($input: UploadUserImageInput) {
    uploadUserImage(input: $input)
  }
`

export const accountSettingsMutation = gql`
  mutation updateAccountSettings($input: UpdateAccountSettingsInput) {
    updateAccountSettings(input: $input) {
      ...UserPayloadFragment
    }
  }
  ${Fragments.UserPayloadFragment}
`

export const markNotificationRead = gql`
  mutation markNotificationRead($input: IdInput) {
    markNotificationRead(input: $input) {
      ok
    }
  }
`

export const updateToursSeenMutation = gql`
  mutation updateToursSeen($input: UpdateToursSeenInput) {
    updateToursSeen(input: $input) {
      ...UserPayloadFragment
    }
  }
  ${Fragments.UserPayloadFragment}
`

export const updateSoundEffectsMutation = gql`
  mutation updateSoundEffectsSetting($input: UpdateSoundEffectsInput) {
    updateSoundEffectsSetting(input: $input) {
      ...UserPayloadFragment
    }
  }
  ${Fragments.UserPayloadFragment}
`

export const submitContactUsForm = gql`
  mutation submitContactUsForm($input: SubmitContactUsFormInput) {
    submitContactUsForm(input: $input) {
      ok
    }
  }
`

export const changePasswordMutation = gql`
  mutation changePassword($input: ChangePasswordInput) {
    changePassword(input: $input)
  }
`

export const meQuery = gql`
  query me {
    me {
      ...MeFragment
    }
  }
  ${Fragments.MeFragment}
`

export const getExportHistory = gql`
  query getExportHistory($input: GetExportHistoryInput) {
    getExportHistory(input: $input) {
      ...Ticket
    }
  }
  ${Fragments.TicketFragment}
`

export const resendVerificationCode = gql`
  query resendVerificationCode {
    resendVerificationCode
  }
`

export const checkPromoCode = gql`
  query checkPromoCode($input: CheckPromoCodeInput) {
    checkPromoCode(input: $input) {
      amountOff
      duration
      durationInMonths
      maxRedemptions
      percentOff
      valid
    }
  }
`

export const checkVerifyLink = gql`
  query checkVerifyLink($input: CheckVerifyUrlInput) {
    checkVerifyLink(input: $input)
  }
`

export const checkVerificationCode = gql`
  mutation checkVerificationCode($input: CheckVerificationCodeInput) {
    checkVerificationCode(input: $input)
  }
`

export const cancelSubscription = gql`
  mutation cancelSubscription($input: CancelSubscriptionInput) {
    cancelSubscription(input: $input) {
      ...MeFragment
    }
  }
  ${Fragments.MeFragment}
`

export const deleteAccount = gql`
  mutation deleteAccount($input: DeleteAccountInput) {
    deleteAccount(input: $input)
  }
`

export const checkResetPasswordToken = gql`
  query checkResetPasswordToken($input: CheckResetPasswordTokenInput) {
    checkResetPasswordToken(input: $input)
  }
`

export const forgotPassword = gql`
  query forgotPassword($input: String) {
    forgotPassword(input: $input)
  }
`

export const resetPassword = gql`
  mutation resetPassword($input: ResetPasswordInput) {
    resetPassword(input: $input)
  }
`

export const submitFeedbackMutation = gql`
  mutation submitFeedback($input: SubmitFeedbackInput) {
    submitFeedback(input: $input)
  }
`
