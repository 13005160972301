import axios from 'axios'
import ReactGA from 'react-ga'
import strings from '../assets/strings'
import { cleanGraphQLError } from '../utils/helpers'
import { UNAUTH_USER, AUTH_ERROR, USER_IDENTIFIED } from './types'
import { getUserAndSignin } from './user.actions'

export const signin = (email, password) => (dispatch, getState, { intl }) => {
  return new Promise(async function(resolve, reject) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/signin`,
        { email, password },
      )

      const { headers } = response

      const token = headers['x-token']
      const refreshToken = headers['x-refresh-token']

      if (token) {
        localStorage.setItem('token', token)
      }

      if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken)
      }

      dispatch(getUserAndSignin())

      ReactGA.event({
        category: 'Account',
        action: 'Sign In',
      })

      resolve()
    } catch (err) {
      if (err.response) {
        const state = getState()
        dispatch(authError(intl(state).formatMessage(strings.signinError)))
        reject()
      } else {
        dispatch(authError(cleanGraphQLError(err.message)))
        reject()
      }
    }
  })
}

export function signoutUser() {
  ReactGA.event({
    category: 'Account',
    action: 'Sign Out',
  })

  return {
    type: UNAUTH_USER,
  }
}

export function userIdentified() {
  return {
    type: USER_IDENTIFIED,
  }
}

export function authError(error) {
  return {
    type: AUTH_ERROR,
    payload: error,
  }
}
