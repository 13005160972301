import { defineMessages } from 'react-intl'

const user = defineMessages({
  myAccount: {
    id: 'user.myAccount',
    defaultMessage: 'My account',
  },
  accountCreatedSuccessfully: {
    id: 'user.accountCreatedSuccessfully',
    defaultMessage: 'Your account has been created successfully',
  },
  accountCreatedFailure: {
    id: 'user.accountCreatedFailure',
    defaultMessage: 'We are unable to create your account at this time',
  },
  subscriptionSetupSuccess: {
    id: 'user.subscriptionSetupSuccess',
    defaultMessage: 'Your subscription has been set up successfully',
  },
  subscriptionSetupFailure: {
    id: 'user.subscriptionSetupFailure',
    defaultMessage: 'Your subscription was unable to be set up at this time',
  },
  verified: {
    id: 'user.verified',
    defaultMessage: 'Verified',
  },
  passwordRequirements: {
    id: 'user.passwordRequirements',
    defaultMessage:
      'Passwords must be a minimum of 8 characters and contain a letter, a number, and a symbol.',
  },
  forgotPasswordDescription: {
    id: 'user.forgotPasswordDescription',
    defaultMessage: `Brain blocked? Enter your email here and we'll send a link to reset your password.`,
  },
  reset: {
    id: 'user.reset',
    defaultMessage: 'Reset',
  },
  resetPassword: {
    id: 'user.resetPassword',
    defaultMessage: 'Reset password',
  },
  resetPasswordDescription: {
    id: 'user.resetPasswordDescription',
    defaultMessage: `Hey there, go ahead and enter a new password. We'll send you back to signin after.`,
  },
  resetPasswordSent: {
    id: 'user.resetPasswordSent',
    defaultMessage:
      'If an account for {email} exists, a link to reset the password has been sent.',
  },
  resetPasswordSuccess: {
    id: 'user.resetPasswordSuccess',
    defaultMessage: 'Password was reset successuflly.',
  },
  returnToSignin: {
    id: 'user.returnToSignin',
    defaultMessage: 'Return to Sign in',
  },
  yourProfilePhoto: {
    id: 'user.yourProfilePhoto',
    defaultMessage: 'Your profile photo',
  },
  changeAccountSettings: {
    id: 'user.changeAccountSettings',
    defaultMessage: 'Change your account settings',
  },
  changePassword: {
    id: 'user.changePassword',
    defaultMessage: 'Change your password',
  },
  settingsSavedSuccessfully: {
    id: 'user.settingsSavedSuccessfully',
    defaultMessage: 'Your settings has been successfully updated!',
  },
  passwordSavedSuccessfully: {
    id: 'user.passwordSavedSuccessfully',
    defaultMessage: 'Your password has been successfully updated!',
  },
  resendVerificationCodeSuccess: {
    id: 'user.resendVerificationCodeSuccess',
    defaultMessage: 'Your new verification code was sent successfully.',
  },
  resendVerificationCodeFailure: {
    id: 'user.resendVerificationCodeFailure',
    defaultMessage:
      'Sorry, we are not able to send you a new verification code at this time. Please reach out to support.',
  },
  signupToAcceptInvite: {
    id: 'user.signupToAcceptInvite',
    defaultMessage: `Please sign up to accept the invite.`,
  },
  youAreAddedToCompany: {
    id: 'user.youAreAddedToCompany',
    defaultMessage: `You've successfully started a company!`,
  },
  acceptedCompanyInvite: {
    id: 'user.acceptedCompanyInvite',
    defaultMessage: `The invite was accepted!`,
  },
  inviteAccepted: {
    id: 'user.inviteAccepted',
    defaultMessage: `Invite accepted`,
  },
  acceptedCompanyInviteDescription: {
    id: 'user.acceptedCompanyInviteDescription',
    defaultMessage: `You've successfully accepted the invite to join. Head to your dashboard and look for the company in the company menu.`,
  },
  accepted: {
    id: 'user.accepted',
    defaultMessage: `Accepted`,
  },
  notAccepted: {
    id: 'user.notAccepted',
    defaultMessage: `Not accepted`,
  },
  verify: {
    id: 'user.verify',
    defaultMessage: `Verify`,
  },
  verifyAccount: {
    id: 'user.verifyAccount',
    defaultMessage: `Verify your account`,
  },
  verifyAccountDescription: {
    id: 'user.verifyAccountDescription',
    defaultMessage: `Please enter the verification code we sent to your email.`,
  },
  cancelSubscription: {
    id: 'user.cancelSubscription',
    defaultMessage: `Cancel your subscription`,
  },
  cancelSubscriptionConfirmation: {
    id: 'user.cancelSubscriptionConfirmation',
    defaultMessage: `Are you sure you want to cancel your account? You will no longer be able to access your companies.`,
  },
  subscriptionCanceledSuccessfully: {
    id: 'user.subscriptionCanceledSuccessfully',
    defaultMessage: `Your subscription has been canceled.`,
  },
  deleteAccount: {
    id: 'user.deleteAccount',
    defaultMessage: `Delete account`,
  },
  deleteUserAccount: {
    id: 'user.deleteUserAccount',
    defaultMessage: `Delete user account`,
  },
  deleteAccountConfirmation: {
    id: 'user.deleteAccountConfirmation',
    defaultMessage: `Are you sure you want to delete your account? This will erase all company and plan data associated with your account.`,
  },
  deleteOrgAccountConfirmation: {
    id: 'user.deleteOrgAccountConfirmation',
    defaultMessage: `Are you sure you want to delete this user account? This will erase all company and plan data that this user is the owner of.`,
  },
  trialExpired: {
    id: 'user.trialExpired',
    defaultMessage: `Trial expired`,
  },
  trialExpiredDescription: {
    id: 'user.trialExpiredDescription',
    defaultMessage: `Your free trial has expired. Let's not let all that hardwork go to waste. Subscribe now and keep writing your business plan.`,
  },
  paywall: {
    id: 'user.paywall',
    defaultMessage: `Subscribe today`,
  },
  paywallDescription: {
    id: 'user.paywallDescription',
    defaultMessage: `{0} is only available with a paid subscription to LaunchPlan. Subscribe now and finish building your business plan!`,
  },
  thisFeature: {
    id: 'user.thisFeature',
    defaultMessage: `This feature`,
  },
  theBusinessPlanFeature: {
    id: 'user.theBusinessPlanFeature',
    defaultMessage: `The business plan builder`,
  },
  theFinancialsFeature: {
    id: 'user.theFinancialsFeature',
    defaultMessage: `The business financials`,
  },
  thePublicShareFeature: {
    id: 'user.thePublicShareFeature',
    defaultMessage: `Sharing business plans and canvases online`,
  },
  theTeamMembersFeature: {
    id: 'user.theTeamMembersFeature',
    defaultMessage: `Collaborating with team members`,
  },
  joinOrganizationInvite: {
    id: 'user.joinOrganizationInvite',
    defaultMessage: `Invite to join organization`,
  },
  joinOrganizationInviteDescription: {
    id: 'user.joinOrganizationInviteDescription',
    defaultMessage: `You've been invited to join the following organization. Accepting this invite will share all company data for the companies that you are the owner of on LaunchPlan with this organization.`,
  },
  signinToViewYourInvite: {
    id: 'user.signinToViewYourInvite',
    defaultMessage: `Sign in to access organization subscription invite`,
  },
  accept: {
    id: 'user.accept',
    defaultMessage: `Accept`,
  },
  decline: {
    id: 'user.decline',
    defaultMessage: `Decline`,
  },
  freeTrialLength: {
    id: 'user.freeTrialLength',
    defaultMessage: `Your free trial length`,
  },
  viewYourAccountSettings: {
    id: 'user.viewYourAccountSettings',
    defaultMessage: `Go to your account`,
  },
  canStillAccessOtherCompanies: {
    id: 'user.canStillAccessOtherCompanies',
    defaultMessage: `You can still access and work on companies that you are an invited team member of.`,
  },
  days: {
    id: 'user.days',
    defaultMessage: `days`,
  },
  months: {
    id: 'user.months',
    defaultMessage: `months`,
  },
  notAcceptedYet: {
    id: 'user.notAcceptedYet',
    defaultMessage: `Not accepted yet`,
  },
  invalidPromoCode: {
    id: 'user.invalidPromoCode',
    defaultMessage: `Invalid promo code`,
  },
  promoCodeApplied: {
    id: 'user.promoCodeApplied',
    defaultMessage: `Promo code applied`,
  },
  duration: {
    id: 'user.duration',
    defaultMessage: `Duration`,
  },
  forever: {
    id: 'user.forever',
    defaultMessage: `Forever`,
  },
  oneTime: {
    id: 'user.oneTime',
    defaultMessage: `One-time`,
  },
  amountOff: {
    id: 'user.amountOff',
    defaultMessage: `Amount off`,
  },
  percentageOff: {
    id: 'user.percentageOff',
    defaultMessage: `Percentage off`,
  },
  promotion: {
    id: 'user.promotion',
    defaultMessage: `Promotion`,
  },
  changeSubscription: {
    id: 'user.changeSubscription',
    defaultMessage: `Change subscription`,
  },
  subscriptionUpdateSuccess: {
    id: 'user.subscriptionUpdateSuccess',
    defaultMessage: `Your subscription has been successfully updated`,
  },
  subscriptionUpdateFailure: {
    id: 'user.subscriptionUpdateFailure',
    defaultMessage: `We're sorry, your subscription can't be updated at this time. Please reach out to support.`,
  },
  lastLogin: {
    id: 'user.lastLogin',
    defaultMessage: `Last Login`,
  },
  joined: {
    id: 'user.joined',
    defaultMessage: `Joined`,
  },
  resendAccountInvite: {
    id: 'user.resendAccountInvite',
    defaultMessage: `Resend Account Invite`,
  },
  resend: {
    id: 'user.resend',
    defaultMessage: `Resend`,
  },
  resendInviteConfirmation: {
    id: 'user.resendInviteConfirmation',
    defaultMessage: `Are you sure you want to resend an invite to this user?`,
  },
  imageUploadInstructions: {
    id: 'user.imageUploadInstructions',
    defaultMessage: `Drag image or click here to upload new profile photo`,
  },
})

export default user
