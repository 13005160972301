import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { ConnectedRouter } from 'react-router-redux'
import Loadable from 'react-loadable'
import { Helmet } from 'react-helmet'
import RequireAuth from './hocs/Require_authentication'
import withTracker from './hocs/withTracker'
import ErrorBoundary from '../components/errors/ErrorBoundary'
import Loading from '../components/micros/Loading'
import NotFound from '../components/security/NotFound'
import FlashMessage from './micros/FlashMessage'

const Signout = Loadable({
  loader: () => import('./auth/Signout'),
  loading: Loading,
})

const TermsOfService = Loadable({
  loader: () => import('../components/legal/TermsOfService'),
  loading: Loading,
})

const PrivacyPolicy = Loadable({
  loader: () => import('../components/legal/PrivacyPolicy'),
  loading: Loading,
})

const ForgotPassword = Loadable({
  loader: () => import('../components/security/ForgotPassword'),
  loading: Loading,
})

const ResetPassword = Loadable({
  loader: () => import('../components/security/ResetPassword'),
  loading: Loading,
})

const Signin = Loadable({
  loader: () => import('./auth/Signin'),
  loading: Loading,
})

const AcceptInvite = Loadable({
  loader: () => import('./company/collaboration/AcceptInvite'),
  loading: Loading,
})

const AcceptOrgInvite = Loadable({
  loader: () => import('./org/AcceptOrgInvite'),
  loading: Loading,
})

const VerifyAccount = Loadable({
  loader: () => import('./security/VerifyAccount'),
  loading: Loading,
})

const PlanPublicSharePage = Loadable({
  loader: () => import('./publicShare/PlanPublicSharePage'),
  loading: Loading,
})

const CanvasPublicSharePage = Loadable({
  loader: () => import('./publicShare/CanvasPublicSharePage'),
  loading: Loading,
})

const DashboardPage = Loadable({
  loader: () => import('./dashboard/DashboardPage'),
  loading: Loading,
})

const SignupForm = Loadable({
  loader: () => import('../components/signup/SignupForm'),
  loading: Loading,
})

const CompanyOnboarding = Loadable({
  loader: () => import('./onboarding/CompanyOnboarding'),
  loading: Loading,
})

const SubscriptionWizard = Loadable({
  loader: () => import('./onboarding/SubscriptionWizard'),
  loading: Loading,
})

class App extends Component {
  componentDidMount() {
    const ele = document.getElementById('ipl-progress-indicator')
    if (ele) {
      // fade out
      ele.classList.add('available')
      setTimeout(() => {
        // remove from DOM
        ele.outerHTML = ''
      }, 1500)
    }
  }
  render() {
    const { history } = this.props
    return (
      <div className="launchplan_app">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            LaunchPlan Application | Smart and Simple Business Plan Software
          </title>
          <meta
            name="description"
            content="LaunchPlan Application | Smart and Simple Business Plan Software"
          />
          <meta
            property="og:title"
            content="LaunchPlan Application | Smart and Simple Business Plan Software"
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://app.launchplan.com" />
          <meta
            property="og:image"
            content="https://launchplan.com/launchplan-logo-vertical.png"
          />
          <meta
            property="og:image:alt"
            content="The LaunchPlan logo, a rocket launching"
          />
        </Helmet>
        <ErrorBoundary>
          <ConnectedRouter history={history}>
            <Switch>
              <Route exact path="/" component={withTracker(Signin)} />
              <Route exact path="/signup" component={withTracker(SignupForm)} />
              <Route
                exact
                path="/signup/:inviteId"
                component={withTracker(SignupForm)}
              />
              <Route exact path="/signout" component={withTracker(Signout)} />
              <Route
                exact
                path="/verify/:id"
                component={withTracker(VerifyAccount)}
              />
              <Route
                exact
                path="/forgot-password"
                component={withTracker(ForgotPassword)}
              />
              <Route
                exact
                path="/reset-password"
                component={withTracker(ResetPassword)}
              />
              <Route
                exact
                path="/accept-invite/:inviteId"
                component={withTracker(AcceptInvite)}
              />
              <Route
                exact
                path="/accept-org-invite/:inviteId"
                component={withTracker(AcceptOrgInvite)}
              />
              <Route
                exact
                path="/create-company"
                component={RequireAuth(withTracker(CompanyOnboarding))}
              />
              <Route
                exact
                path="/subscription"
                component={RequireAuth(withTracker(SubscriptionWizard))}
              />
              <Route
                exact
                path="/terms-of-service"
                component={withTracker(TermsOfService)}
              />
              <Route
                exact
                path="/privacy-policy"
                component={withTracker(PrivacyPolicy)}
              />
              <Route
                exact
                path="/share/canvas/:id"
                component={withTracker(CanvasPublicSharePage)}
              />
              <Route
                path="/share/:id"
                component={withTracker(PlanPublicSharePage)}
              />
              <Route
                path="/dashboard"
                component={RequireAuth(withTracker(DashboardPage))}
              />
              <Route component={NotFound} />
            </Switch>
          </ConnectedRouter>
          <FlashMessage />
        </ErrorBoundary>
      </div>
    )
  }
}

export default App
