import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { routerReducer } from 'react-router-redux'
import adminReducer from './admin.reducer'
import authReducer from './auth.reducer'
import companyReducer from './company.reducer'
import planReducer from './plan.reducer'
import userReducer from './user.reducer'
import intlReducer from './intl.reducer'
import financialsReducer from './financials.reducer'
import layoutReducer from './layout.reducer'
import socketReducer from './socket.reducer'
import publicShareReducer from './publicShare.reducer'
import exportsReducer from './exports.reducer'
import canvasReducer from './canvas.reducer'
import orgReducer from './org.reducer'
import { UNAUTH_USER } from '../actions/types'

const appReducer = combineReducers({
  admin: adminReducer,
  auth: authReducer,
  canvas: canvasReducer,
  company: companyReducer,
  dataExports: exportsReducer,
  financials: financialsReducer,
  form: formReducer,
  intl: intlReducer,
  org: orgReducer,
  layout: layoutReducer,
  socket: socketReducer,
  plan: planReducer,
  publicShare: publicShareReducer,
  router: routerReducer,
  user: userReducer,
})

const rootReducer = (state, action) => {
  if (action.type === UNAUTH_USER) {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('state')

    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
