import { RESOURCE_LOCK_UPDATE } from './types'
import { getWebSocket } from '../utils/socket'

export function resourceLockListUpdated(resourceLocks) {
  return async function (dispatch) {
    // console.log('resourceLockListUpdated action', resourceLocks)
    if (resourceLocks) {
      dispatch({
        type: RESOURCE_LOCK_UPDATE,
        payload: {
          resourceLocks
        }
      })
    }
  }
}

export const getResourceIdFromPath = location => {
  if (!location) return null

  const { pathname } = location
  const pathArray = pathname.split('/').map(value => value)

  if (pathArray.includes('edit')) {
    return pathArray[4]
  } else if (pathArray.includes('canvas')) {
    return pathArray[3]
  }

  return null
}

export function checkResourceLock(location, prevLocation) {
  return async function (dispatch, getState) {
    // Only check for resource locks on plan and canvas paths
    if (location.pathname.indexOf('plan') === -1 && location.pathname.indexOf('canvas') === -1) return

    const resourceId = getResourceIdFromPath(location)
    const prevResourceId = getResourceIdFromPath(prevLocation)

    if (!resourceId && !prevResourceId) {
      return
    }

    // console.log('resourceId', resourceId)

    const isAlreadyLocked = getState().socket.resourceLocks.some(lock => lock.resourceId === resourceId && lock.userId !== getState().user.account._id)
    // console.log('isAlreadyLocked', isAlreadyLocked)

    if (resourceId && !isAlreadyLocked) getWebSocket().sendMessage('lockResource', { companyId: getState().company.currentCompany.id, resourceId })
    if (!resourceId && (prevResourceId && !isAlreadyLocked)) getWebSocket().sendMessage('unlockResource', { companyId: getState().company.currentCompany.id, resourceId: prevResourceId })
  }
}