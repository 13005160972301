import { defineMessages } from 'react-intl'

const Admin = defineMessages({
  adminArea: {
    id: 'admin.adminArea',
    defaultMessage: 'Admin Panel',
  },
  programName: {
    id: 'admin.programName',
    defaultMessage: 'Program Name',
  },
  addProgram: {
    id: 'admin.addProgram',
    defaultMessage: 'Add program',
  },
  freeYearProgram: {
    id: 'admin.freeYearProgram',
    defaultMessage: 'Free Year Programs',
  },
  noProgramsAdded: {
    id: 'admin.noProgramsAdded',
    defaultMessage: 'No programs currently added',
  },
  enterEmailDomain: {
    id: 'admin.enterEmailDomain',
    defaultMessage:
      'Enter the email domain you would like to enable free year signups for (ex. @example.edu)',
  },
  programAdded: {
    id: 'admin.programAdded',
    defaultMessage: 'Program added successfully',
  },
  emailDomain: {
    id: 'admin.emailDomain',
    defaultMessage: 'Email Domain',
  },
  deletePromotionConfirmation: {
    id: 'admin.deletePromotionConfirmation',
    defaultMessage: 'Are you sure you want to delete this promotion?',
  },
  freeYearProgramDescription: {
    id: 'admin.freeYearProgramDescription',
    defaultMessage:
      'Add an email domain here to allow any email from that domain to get a free full year trial to LaunchPlan when they signup.',
  },
  currentlyOngoingPrograms: {
    id: 'admin.currentlyOngoingPrograms',
    defaultMessage: 'Currently ongoing programs',
  },
})

export default Admin
