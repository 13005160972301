import gql from 'graphql-tag'
import * as Fragments from '../fragments'

export const getAdminData = gql`
  query getAdminData {
    getAdminData {
      users {
        ...UserFragment
      }
      orgs {
        ...OrgFragment
      }
      promotions {
        _id
        name
        type
        value
      }
      userStats {
        signedUpLast7Days
        signedUpLast30Days
        signedUpLast60Days
        signedUpLast90Days
        signedUpLast365Days
        activeInLast7Days
        activeInLast30Days
        activeInLast60Days
        activeInLast90Days
        freeUsers
        monthlyPlanUsers
        sixMonthPlanUsers
        yearlyPlanUsers
        orgUsers
        orgAdvisorUsers
        orgAdminUsers
      }
    }
  }
  ${Fragments.UserFragment}
  ${Fragments.OrgFragment}
`

export const addAnnualProgramEmail = gql`
  mutation addAnnualProgramEmail($input: AddAnnualProgramInput) {
    addAnnualProgramEmail(input: $input) {
      _id
      name
      type
      value
    }
  }
`

export const deleteAnnualProgramEmail = gql`
  mutation deleteAnnualProgramEmail($input: DeleteAnnualProgramInput) {
    deleteAnnualProgramEmail(input: $input)
  }
`
