import { createMuiTheme } from '@material-ui/core/styles'

const primary_color = '#2D6CFF'
const text_color = '#2f2f2f'
const text_color_light = '#627984'
const border_color = '#e4e4e4'
const border_color_light = '#f3f5f7'
const background = '#f3f5f7'
const background_tint = '#fafbfc'
const background_avatar = '#d5dbe0'
const gray = '#BBBBBB'
const white = '#ffffff'
const red = '#f44336'
const green = '#39B278'
const yellow = '#FEBC00'
const orange = '#FF6747'
// const purple = '#685999'

export const lineTransform =
  'transform .4s cubic-bezier(0.190,1.000,0.220,1.000)'

export default createMuiTheme({
  typography: {
    fontFamily: '"Circular Std", "Helvetica", "Arial", sans-serif',
    fontVariantLigatures: 'none',
  },
  palette: {
    primary: {
      main: primary_color,
      light: white,
      white,
      dark: '#000',
      contrastText: white,
      borderColor: border_color,
      borderColorLight: border_color_light,
      textColor: text_color,
      textColorLight: text_color_light,
      error: red,
      success: green,
      yellow,
      orange: orange
    },
    secondary: {
      main: text_color,
      light: white,
      darkText: text_color,
      textColorLight: text_color_light,
      contrastText: text_color,
    },
    background: {
      default: background,
    },
    text: {
      primary: text_color,
      secondary: white,
    },
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    divider: border_color,
  },
  spacing: {
    unit: 5,
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        boxShadow: '0 0 25px 5px rgba(0,0,0,0.1)',
        overflow: 'hidden',
      },
      rounded: {
        borderRadius: 4,
      },
      elevation2: {
        boxShadow:
          '0px 1px 30px 5px rgba(0, 0, 0, 0.03), 0px 2px 2px 0px rgba(0, 0, 0, 0.01), 0px 3px 1px -2px rgba(0, 0, 0, 0.02)',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
      },
    },
    MuiDrawer: {
      paper: {
        boxShadow: '2px 0 20px 0 rgba(0,0,0,0.1)',
      },
      docked: {
        position: 'fixed',
        zIndex: 0,
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: 0,
        height: '40px',
        width: '40px',

        '&:hover': {
          backgroundColor: 'none',
          color: primary_color,
        },
        '&:focus': {
          color: primary_color,
        },
      },
    },
    MuiPopover: {
      paper: {
        minWidth: '220px',
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: '55px !important',
      },
    },
    MuiTab: {
      root: {
        borderRight: `1px solid ${border_color}`,
        padding: '1rem 0',
        textTransform: 'none',
        flex: 1,
        maxWidth: 'none',

        '&:after': {
          transform: 'scaleX(0)',
          transformOrigin: '50% 50%',
          transition: 'transform .2s ease-out',
          content: '""',
          height: 2,
          position: 'absolute',
          right: 0,
          width: '100%',
          top: 0,
          backgroundColor: text_color,
        },

        '&:hover, &:focus': {
          '&:after': {
            transition: 'transform .5s cubic-bezier(0.190,1.000,0.220,1.000)',
            transform: 'scaleX(1)',
          },
        },

        '&:last-of-type': {
          borderRight: 0,
        },
      },
      label: {
        fontSize: '0.68rem',
        fontWeight: 600,

        '@media (min-width:600px)': {
          fontSize: '0.86rem',
        },

        '@media (min-width:960px)': {
          fontSize: '0.94rem',
        },
      },
      labelWrapped: {
        fontSize: '0.75rem',
        fontWeight: 600,

        '@media (min-width:600px)': {
          fontSize: '0.86rem',
        },

        '@media (min-width:960px)': {
          fontSize: '0.94rem',
        },
      },
      textColorPrimary: {
        color: text_color_light,
      },
      selected: {
        color: `${text_color} !important`,
      },
    },
    MuiTabs: {
      root: {
        borderRadius: 4,
      },
      indicator: {
        top: 0,
        bottom: 'auto',
      },
      scrollButtons: {
        flex: 0,
        padding: 0,

        '& svg': {
          fill: text_color_light,
          height: '2.4rem',
          width: '2.4rem',
        },
      },
    },
    MuiCollapse: {
      wrapper: {
        backgroundColor: background_tint,
      },
    },
    MuiExpansionPanel: {
      expanded: {
        margin: 0,
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        borderBottom: `1px solid ${border_color_light}`,
        borderTop: `1px solid ${border_color_light}`,
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        margin: '0 auto',
        maxWidth: 550,
        padding: '1rem 2rem',
      },
    },
    MuiTabScrollButton: {
      root: {
        color: text_color,
        padding: '0 0.5rem',
      },
    },
    MuiFormHelperText: {
      root: {
        marginBottom: '0.4rem',
        textAlign: 'center',
      },
    },
    MuiSwitch: {
      checked: {
        '&$colorSecondary + $bar': {
          backgroundColor: `${green} !important`,
          opacity: 0.4,
        },
      },
      icon: {
        color: border_color_light,
      },
      iconChecked: {
        color: green,
      },
    },
    MuiStep: {
      root: {
        '&:first-of-type': {
          '& span span:before': {
            backgroundColor: primary_color,
            left: 'calc(-50% + 6px)',
            top: '7px',
            // Doing the other way around crash on IE11 '''' https://github.com/cssinjs/jss/issues/242
            content: '""',
            height: 1,
            position: 'absolute',
            zIndex: -1,
            right: 'calc(50% + 2px)',
          },
        },
        '&:last-of-type': {
          '& span span:before': {
            backgroundColor: border_color,
            left: 'calc(50% + 6px)',
            top: '7px',
            // Doing the other way around crash on IE11 '''' https://github.com/cssinjs/jss/issues/242
            content: '""',
            height: 1,
            position: 'absolute',
            zIndex: -1,
            right: 'calc(-50% + 2px)',
          },
        },
      },
      completed: {
        '& > div span': {
          '&:after': {
            transform: 'scaleX(1)',
          },
        },
      },
    },
    MuiStepConnector: {
      line: {
        borderColor: border_color,

        '&:after': {
          backgroundColor: primary_color,
          left: 0,
          bottom: 0,
          // Doing the other way around crash on IE11 '''' https://github.com/cssinjs/jss/issues/242
          content: '""',
          height: 1,
          position: 'absolute',
          right: 0,
          transform: 'scaleX(0)',
          transformOrigin: '0 50%',
          transition: lineTransform,
          pointerEvents: 'none', // Transparent to the hover style.
        },
      },
      alternativeLabel: {
        top: '7px',
        left: 'calc(50%)',
        right: 'calc(-50% + 2px)',
      },
    },
    MuiStepLabel: {
      root: {
        '& svg': {
          fill: primary_color,
          transition: 'fill 0.4s cubic-bezier(0.190,1.000,0.220,1.000)',
        },
      },
      label: {
        color: text_color,
      },
      iconContainer: {
        zIndex: 1,
      },
      disabled: {
        '& svg': {
          fill: border_color,
        },
      },
      completed: {
        '& svg': {
          fill: primary_color,
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiListItem: {
      default: {
        paddingTop: '8px',
        paddingBottom: '8px',
      },
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: background_avatar,
      },
      img: {
        height: 'initial',
      },
    },
    MuiButtonBase: {
      root: {
        outline: 'initial',
        outlineStyle: 'initial',
      },
    },
    MuiButton: {
      root: {
        transitionDuration: 0.15,
      },
    },
    MuiSnackbarContent: {
      root: {
        padding: '0.2rem 1.5rem',
        backgroundColor: green,
        borderRadius: '0 4px 4px 0',

        '@media (min-width:960px)': {
          borderRadius: '0 0 4px 4px',
        },
      },
      message: {
        margin: '0 auto',
        textAlign: 'center',
      },
    },
    MuiFormControl: {
      root: {
        display: 'flex',
      },
      marginNormal: {
        marginTop: 0,
        marginBottom: '1.6rem',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '1.1rem',
        fontWeight: 500,
        lineHeight: '1.46429em',
        color: text_color,

        '&$error': {
          color: text_color_light,
        },
      },
    },
    MuiInputLabel: {
      formControl: {
        position: 'static',
        top: 4,
        left: '1rem',
        color: text_color,
        transform: 'translate(0, 12px) scale(1)',
      },
      shrink: {
        zIndex: 1,
        top: 4,
        left: '1rem',
      },
      animated: {
        position: 'absolute',
        color: text_color_light,

        '& + div': {
          marginBottom: '0.4rem',

          '& input': {
            paddingTop: '1.8rem',
          },
        },
      },
    },
    MuiInputAdornment: {
      root: {
        marginTop: '0 !important',
        alignSelf: 'center',

        '& p': {
          color: text_color_light,
          fontSize: '1.1rem',
        },
      },
      positionStart: {
        margin: '0 0 0 10px',
        marginRight: 0,
      },
      positionEnd: {
        margin: '0 6px',
      },
    },
    MuiInput: {
      formControl: {
        boxShadow:
          '0px 1px 30px 5px rgba(0, 0, 0, 0.03), 0px 2px 2px 0px rgba(0, 0, 0, 0.01), 0px 3px 1px -2px rgba(0, 0, 0, 0.02)',
        borderRadius: 4,
        overflow: 'auto',
        backgroundColor: white,

        'label + &': {
          marginTop: '0.5rem',
        },
      },
      input: {
        padding: '0.7rem 0.9rem',
        boxSizing: 'border-box',
      },
      inputType: {
        height: 'initial',
      },
      inputMultiline: {
        minHeight: 120,
      },
      error: {
        color: text_color_light,

        '&:after': {
          backgroundColor: red,
          borderBottom: 0,
        },
      },
      underline: {
        '&:after': {
          backgroundColor: primary_color,
          borderBottom: 0,
          left: 0,
          bottom: 0,
          // Doing the other way around crash on IE11 '''' https://github.com/cssinjs/jss/issues/242
          content: '""',
          height: 2,
          position: 'absolute',
          right: 0,
          transform: 'scaleX(0)',
          transformOrigin: '0 50%',
          transition: lineTransform,
          pointerEvents: 'none', // Transparent to the hover style.
        },
        '&$focused:after': {
          transform: 'scaleX(1)',
        },
        '&:before': {
          backgroundColor: border_color,
          borderBottom: 0,
          left: 0,
          bottom: 0,
          // Doing the other way around crash on IE11 '''' https://github.com/cssinjs/jss/issues/242
          content: '""',
          height: 2,
          position: 'absolute',
          right: 0,
          transition: 'none',
          pointerEvents: 'none', // Transparent to the hover style.
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          backgroundColor: text_color,
          borderBottom: 0,
        },
        '&$disabled:before': {
          background: 'transparent',
          backgroundImage: `linear-gradient(to right, ${gray} 33%, transparent 0%)`,
          backgroundPosition: 'left top',
          backgroundRepeat: 'repeat-x',
          backgroundSize: '5px 1px',
        },
      },
    },
    MuiSelect: {
      select: {
        height: 'initial',
        width: '100%',
        paddingRight: '40px',

        '&:focus': {
          background: 'none',
        },
      },
      icon: {
        right: '10px',
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 6,
        height: 8,
      },
      colorPrimary: {
        backgroundColor: '#c9d9ff',
      },
      bar1Determinate: {
        borderRadius: 8,
      },
    },
    MuiTypography: {
      root: {
        fontVariantLigatures: 'none',
      },
      display4: {
        fontSize: '0.825rem',
        fontWeight: 300,
        lineHeight: '1.46429em',
        letterSpacing: '0.01em',
        color: text_color_light,
      },
      display3: {
        fontSize: '1.1rem',
        fontWeight: 500,
        lineHeight: '1.46429em',
        color: text_color,
      },
      display2: {
        fontSize: '2.2125rem',
        fontWeight: 600,
        lineHeight: '1.06667em',
        marginLeft: '-.04em',
        color: white,
      },
      display1: {
        fontSize: '2.6rem',
        fontWeight: 500,
        letterSpacing: '-.04em',
        lineHeight: '1.20588em',
        marginLeft: '-.04rem',
        marginBottom: '0.6rem',
        color: text_color,
      },
      headline: {
        fontSize: '1.8rem',
        fontWeight: 600,
        lineHeight: '1.35417em',
      },
      title: {
        fontSize: '1.5rem',
        fontWeight: 500,
        lineHeight: '1.16667em',
      },
      subtitle1: {
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '1.16667em',
        letterSpacing: 1,
      },
      subtitle2: {
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '1.16667em',
        letterSpacing: 1,
        color: text_color_light,
      },
      subheading: {
        fontSize: '1.2rem',
        fontWeight: 400,
        lineHeight: '1.5em',
        color: text_color_light,
      },
      body2: {
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '1.71429em',
      },
      body1: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: '1.46429em',
      },
      caption: {
        fontSize: '0.9rem',
        fontWeight: 500,
        lineHeight: '1.375em',
        color: text_color_light,
      },
      button: {
        color: 'inherit',
        fontFamily: '"Circular Std", "Helvetica", "Arial", sans-serif',
        fontSize: '0.9rem',
        fontWeight: 300,
        textTransform: 'none',
      },
      gutterBottom: {
        marginBottom: '1rem',
      },
    },
  },
})
