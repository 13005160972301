import { defineMessages } from 'react-intl'

const feedback = defineMessages({
  feedback: {
    id: 'feedback.feedback',
    defaultMessage: 'Feedback'
  },
  feedbackIntro: {
    id: 'feedback.feedbackIntro',
    defaultMessage: 'How are we doing?'
  },
  feedbackThankyou: {
    id: 'feedback.feedbackThankyou',
    defaultMessage:
      'Thank you so much for providing your feedback! We truly appreciate it and will do what we can to improve your experience.'
  },
  question1: {
    id: 'feedback.question1',
    defaultMessage:
      'On login for the first time , did you have any difficulty getting a plan started and adding team members? If so, what could we have done better?'
  },
  question2: {
    id: 'feedback.question2',
    defaultMessage:
      'What additional functionality would be useful on the Overview page?'
  },
  question3: {
    id: 'feedback.question3',
    defaultMessage:
      'While working on the Plan sections of the business plan, do you feel like we provided you enough information in each section to fully understand what you needed to write? If not, what could we add to improve your experience here? Where did you get the information you did need?'
  },
  question4: {
    id: 'feedback.question4',
    defaultMessage: 'Would comments be valuable on the Plan section?'
  },
  question5: {
    id: 'feedback.question5',
    defaultMessage:
      'Have you ever created financial projections before? If so, how did you create them and how does it compare to LaunchPlan? If you haven’t, did you feel comfortable working through the steps we created, and did you understand what you were doing?'
  },
  question6: {
    id: 'feedback.question6',
    defaultMessage:
      'Did you feel the site layout was easy to navigate? If not, what could be improved?'
  },
  question7: {
    id: 'feedback.question7',
    defaultMessage:
      'Overall, what additional functionality would make this product even better?'
  },
  question8: {
    id: 'feedback.question8',
    defaultMessage:
      'On a scale from 1-10 (1 being you strongly disliked it  – 10 being you loved it) how was your overall experience using LaunchPlan to create your business plan? Please explain why.'
  },
  question9: {
    id: 'feedback.question9',
    defaultMessage:
      'Would you use LaunchPlan again or recommend it to other entrepreneurs?'
  }
})

export default feedback
