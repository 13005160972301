import { defineMessages } from 'react-intl'


const Types = defineMessages({
  UNIT_SALES: {
    id: 'types.unitSales',
    defaultMessage: 'Unit sales'
  },
  BILLABLE_HOURS: {
    id: 'types.billableHours',
    defaultMessage: 'Billable hours'
  },
  RECURRING_CHARGES: {
    id: 'types.recurringCharges',
    defaultMessage: 'Recurring charges'
  },
  REVENUE_ONLY: {
    id: 'types.revenueOnly',
    defaultMessage: 'Revenue only'
  },
  LONG_TERM: {
    id: 'types.longTerm',
    defaultMessage: 'Long term'
  },
  SHORT_TERM: {
    id: 'types.shortTerm',
    defaultMessage: 'Short term'
  },
  PHYSICAL_SPACE: {
    id: 'types.physicalSpace',
    defaultMessage: 'Physical space'
  },
  EQUITMENT: {
    id: 'types.equitment',
    defaultMessage: 'Equitment'
  },
  MARKETING: {
    id: 'types.marketing',
    defaultMessage: 'Marketing'
  },
  OTHER: {
    id: 'types.other',
    defaultMessage: 'Other'
  },
})

export default Types
