import { OPEN_FLASH_MESSAGE, CLOSE_FLASH_MESSAGE } from './types'

export function showFlashMessage(message, variant) {
  return function(dispatch) {
    dispatch({
      type: OPEN_FLASH_MESSAGE,
      payload: {
        message,
        variant
      }
    })
  }
}

export function hideFlashMessage() {
  return function(dispatch) {
    dispatch({
      type: CLOSE_FLASH_MESSAGE
    })
  }
}
