import admin from './admin'
import assumptions from './assumptions'
import assets from './assets'
import canvas from './canvas'
import common from './common'
import errors from './errors'
import company from './company'
import user from './user'
import financials from './financials'
import plan from './plan'
import financialsHelp from './financialsHelp'
import legal from './legal'
import marketing from './marketing'
import feedback from './feedback'
import types from './types'
import employees from './employees'
import pageTitles from './pageTitles'
import startingBalances from './startingBalances'
import taxes from './taxes'
import tours from './tours'
import org from './org'

export default {
  ...admin,
  ...assumptions,
  ...assets,
  ...canvas,
  ...common,
  ...company,
  ...errors,
  ...user,
  ...financials,
  ...plan,
  ...financialsHelp,
  ...legal,
  ...marketing,
  ...feedback,
  ...types,
  ...taxes,
  ...tours,
  ...pageTitles,
  ...employees,
  ...startingBalances,
  ...org,
}
