import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import strings from '../../assets/strings'
import { withStyles } from '@material-ui/core/styles'
import { logErrorToBackend } from '../../actions/lifecycle.actions'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '../buttons/AppButton'
import Logo from '../../assets/images/logo_vertical'

const styles = theme => ({
  center: {
    position: 'absolute',
    paddingBottom: '25vh',
    top: '15vh',
    left: '50vw',
    transform: 'translateX(-50%)',
    textAlign: 'center',
  },
  paper: {
    padding: '3rem',

    '& a': {
      display: 'inline-block',
      margin: '1rem 0',
    },
  },
  heading: {
    color: theme.palette.primary.main,
    fontSize: '4rem',
  },
  subheading: {
    marginBottom: '2rem',
  },
  text: {
    marginBottom: '2rem',
  },
})
class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    const { logErrorToBackend } = this.props

    this.setState({ hasError: true })

    logErrorToBackend(error, info)
  }

  render() {
    const { classes, intl } = this.props

    if (this.state.hasError) {
      return (
        <div>
          <div className={classes.center}>
            <Paper className={classes.paper}>
              <Logo />
              <Typography variant="headline" className={classes.heading}>
                Whoops
              </Typography>
              <Typography variant="headline" className={classes.subheading}>
                An error has occurred
              </Typography>
              <Typography variant="body2" className={classes.text}>
                {intl.formatMessage(strings.errorNotification)}
              </Typography>
              <div>
                <Button component={'a'} href="/">
                  {intl.formatMessage(strings.backToHomepage)}
                </Button>
              </div>
              <div>
                <Button component={'a'} href="/signout">
                  {intl.formatMessage(strings.signout)}
                </Button>
              </div>
            </Paper>
          </div>
        </div>
      )
    }
    return this.props.children
  }
}

const mapDispatchToProps = {
  logErrorToBackend,
}

export default connect(
  null,
  mapDispatchToProps,
)(withStyles(styles)(injectIntl(ErrorBoundary)))
