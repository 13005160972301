import {
  GET_EXPORT_HISTORY,
  ADD_NEW_EXPORT_TICKET,
  SET_COMPANY,
} from '../actions/types'

const initialState = {}

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_EXPORT_HISTORY:
      return action.payload || {}
    case ADD_NEW_EXPORT_TICKET:
      return {
        ...state,
        [action.payload._id]: action.payload
      }
    case SET_COMPANY:
      return initialState
    default:
      return state
  }
}
