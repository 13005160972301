import { defineMessages } from 'react-intl'

const Legal = defineMessages({
  youAcceptTOSandPP: {
    id: 'legal.youAcceptTOSandPP',
    defaultMessage: 'By signing up, you accept the {tos} and {pp}.'
  },
  termsOfService: {
    id: 'legal.termsOfService',
    defaultMessage: `Terms of service`,
  },
  termsOfServiceDescription: {
    id: 'legal.termsOfServiceDescription',
    defaultMessage: `You agree to these terms of service when using LaunchPlan`,
  },
  privacyPolicy: {
    id: 'legal.privacyPolicy',
    defaultMessage: `Privacy policy`,
  },
  privacyPolicyDescription: {
    id: 'legal.privacyPolicyDescription',
    defaultMessage: `Your privacy is important to us, please let us know if you have any questions at all`,
  },
  termsOfServiceContent: {
    id: 'legal.termsOfServiceContent',
    defaultMessage: `
    <h2>OVERVIEW</h2>\n
    
    This website is operated by LaunchPlan LLC. Throughout the site, the terms “we”, “us” and “our” refer to LaunchPlan LLC. LaunchPlan LLC offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.\n
    
    By visiting our site and/or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply  to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.\n
    
    Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.\n
    
    Any new features or tools which are added to the current site shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.\n
    
    
    <h2>SECTION 1 - SITE TERMS</h2>\n
    
    By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.\n
    
    You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).\n
    
    You must not transmit any worms or viruses or any code of a destructive nature.\n
    
    A breach or violation of any of the Terms will result in an immediate termination of your Services.\n
    
    
    <h2>SECTION 2 - GENERAL CONDITIONS</h2>\n
    
    We reserve the right to refuse service to anyone for any reason at any time.\n
    
    You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.\n
    
    You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.\n
    
    The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.\n
    
    
    <h2>SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</h2>\n
    
    We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.\n
    
    This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.\n
    
    
    <h2>SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES</h2>\n
    
    Prices for our products are subject to change without notice.\n
    
    We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.\n
    
    We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.\n
    
    
    <h2>SECTION 5 - PRODUCTS OR SERVICES (if applicable)</h2>\n
    
    Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.\n
    
    We have made every effort to display as accurately as possible the colors and images of our products that appear on the site or in exports from the site. We cannot guarantee that your computer monitor's display of any color will be accurate.\n
    
    We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.\n
    
    We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.\n
    
    
    <h2>SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION</h2>\n
    
    We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.\n
    
    You agree to provide current, complete and accurate purchase and account information for all purchases made at our site. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.\n
    
    
    <h2>SECTION 7 - OPTIONAL TOOLS</h2>\n
    
    We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.\n
    
    You acknowledge and agree that we provide access to such tools ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.\n
    
    Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).\n
    
    We may also, in the future, offer new services and/or features through the website (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of Service.\n
    
    
    <h2>SECTION 8 - THIRD-PARTY LINKS</h2>\n
    
    Certain content, products and services available via our Service may include materials from third-parties.\n
    
    Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.\n
    
    We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.\n
    
    
    <h2>SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</h2>\n
    
    If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.\n
    
    We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.\n
    
    You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.\n
    
    
    <h2>SECTION 10 - PERSONAL INFORMATION</h2>\n
    
    Your submission of personal information through the site is governed by our Privacy Policy.\n
    
    
    <h2>SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS</h2>\n
    
    Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).\n
    
    We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.\n
    
    
    
    <h2>SECTION 12 - PROHIBITED USES</h2>\n
    
    In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.\n
    
    
    <h2>SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</h2>\n
    
    We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.\n
    
    We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.\n
    
    You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.\n
    
    You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.\n
    
    In no case shall LaunchPlan LLC, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.\n
    
    
    
    <h2>SECTION 14 - INDEMNIFICATION</h2>\n
    
    You agree to indemnify, defend and hold harmless LaunchPlan LLC and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.\n
    
    
    <h2>SECTION 15 - SEVERABILITY</h2>\n
    
    In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.\n
    
    
    <h2>SECTION 16 - TERMINATION</h2>\n
    
    The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.\n
    
    These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.\n
    
    If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).\n
    
    
    <h2>SECTION 17 - ENTIRE AGREEMENT</h2>\n
    
    The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.\n
    
    These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).\n
    
    Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.\n
    
    
    <h2>SECTION 18 - GOVERNING LAW</h2>\n
    
    These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of 33 Park View Ave, Jersey City, NJ, 07302, United States.\n
    
    
    <h2>SECTION 19 - CHANGES TO TERMS OF SERVICE</h2>\n
    
    You can review the most current version of the Terms of Service at any time at this page.\n
    
    We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.\n
    
    
    <h2>SECTION 20 - CONTACT INFORMATION</h2>\n
    
    Questions about the Terms of Service should be sent to us at team@launchplan.com.\n
    `,
  },
  privacyPolicyContent: {
    id: 'legal.privacyPolicyContent',
    defaultMessage: `
    This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from https://launchplan.com (the “Site”).\n
    
    <h2>PERSONAL INFORMATION WE COLLECT</h2>\n

    We utilize a couple of different third parties to allow us to get a better view of how customers and potential customers interact with our online properties. Those services may also automatically collect some of the information below, as well as your IP address, access times, browser type and language, device type, device identifiers, pages visited, and network information. We refer to this information as "Device Information." If you have questions about what information those services collect, we have specified those services below:\n
    
    We collect Device Information using the following technologies:\n

    - Google Analytics (https://policies.google.com/privacy)\n
    - LogRocket (https://logrocket.com/privacy/), LogRocket allows us to record a video of your screen while using our product to help us track down and fix bugs, provide better support to you on your specific issue, and improve our product's user expereience and design.\n
    - “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.\n
    - “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.\n
    
    When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number. If you choose to refer a friend to our Services, we may also collect your friend’s email address so that we may send them a referral or promotional code to sign up for our Services. We refer to this information as "Account Information."

    Additionally when you make a purchase or attempt to make a purchase through the Site, that information is directed to our third-party payment processor, Stripe. We do not store your financial account information on our systems; however, we have access to, and may retain, subscriber information through our third-party payment processor. We refer to this informatoin as "Payment Information."\n

    - Stripe (Stripe Inc). Stripe is a payment service provided by Stripe Inc. (https://stripe.com/terms/US)
    
    When we talk about “Personal Information” in this Privacy Policy, we are talking about Account Infomation, Device Information and Payment Information.\n
    
    <h2>HOW DO WE USE YOUR PERSONAL INFORMATION?</h2>\n
    
    We use the Payment Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, and providing you with invoices and/or order confirmations).  Additionally, we use this Payment Information to:\n
    Communicate with you;\n
    Screen our orders for potential risk or fraud; and\n
    When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.\n
    We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).\n
    
    <h2>SHARING YOUR PERSONAL INFORMATION</h2>\n
    
    We share your Personal Information with third parties to help us use your Personal Information, as described above. We also use Google Analytics to help us understand how our customers use the Site--you can read more about how Google uses your Personal Information here:  https://www.google.com/intl/en/policies/privacy/.  You can also opt-out of Google Analytics here:  https://tools.google.com/dlpage/gaoptout.\n
    
    Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.\n

    <h2>INFORMATION STORAGE</h2>

    We share your data and store your data using the following services.

    - DigitalOcean (DigitalOcean Inc.) DigitalOcean is a hosting service provided by DigitalOcean Inc. (https://www.digitalocean.com/legal/privacy/)
    - Google Cloud Platform. Google Cloud Platform is a hosting service provided by Google LLC. (https://cloud.google.com/security/compliance/)
    - MongoDB Atlas. MongoDB Atlas is a MongoDB-as-a-service provided by MongoDB, Inc. (https://www.mongodb.com/legal/privacy-policy)
    - Cloudinary. Cloudinary is a media hosting service provided by Cloudinary Ltd. (https://cloudinary.com/privacy)
    
    <h2>DO NOT TRACK</h2>\n
    Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.\n
    
    <h2>YOUR RIGHTS UNDER THE GENERAL DATA PROTECTION REGULATION (GDPR)</h2>\n
    If you are a resident of the EEA, you have the following data protection rights:\n
    
    If you wish to access, correct, update, or request deletion of your personal information, you can do so at any time by emailing team@launchplan.com.\n
    In addition, you can object to the processing of your personal information, ask us to restrict the processing of your personal information, or request portability of your personal information. Again, you can exercise these rights by emailing team@launchplan.com.\n
    You have the right to opt-out of marketing communications we send you at any time. You can exercise this right by clicking on the "unsubscribe" or "opt-out" link in the marketing emails we send you. To opt-out of other forms of marketing, please contact us by emailing team@launchplan.com.\n
    Similarly, if we have collected and process your personal information with your consent, then you canwithdraw your consent at any time.  Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.\n
    You have the right to complain to a data protection authority about our collection and use of your personal information. For more information, please contact your local data protection authority.\n
    We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws.\n

    <h2>DATA TRANSFER</h2>\n
    LaunchPlan is a global business. We may transfer personal information to countries other than the country in which the data was originally collected. These countries may not have the same data protection laws as the country in which you initially provided the information. When we transfer your personal information to other countries, we will protect that information as described in this Privacy Policy.\n
    
    <h2>DATA RETENTION</h2>\n
    We retain personal information we collect from you where we have an ongoing legitimate business need to do so (for example, to provide you with a service you have requested or to comply with applicable legal, tax, or accounting requirements).\n

    When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.\n
    
    <h2>CHANGES</h2>\n
    We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.\n
    
    <h2>CONTACT US</h2>\n
    For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at team@launchplan.com or by mail using the details provided below:\n
    
    33 Park View Ave Apt 2401, Jersey City, NJ, 07302, United States\n
    `,
  },
})

export default Legal
