import { defineMessages } from 'react-intl'

const org = defineMessages({
    advisors: {
        id: 'org.advisors',
        defaultMessage: 'Advisors'
    },
    users: {
        id: 'org.users',
        defaultMessage: 'Users'
    },
    orgKey: {
        id: 'org.orgKey',
        defaultMessage: 'Org Key'
    },
    invites: {
        id: 'org.invites',
        defaultMessage: 'Invites'
    },
    inviteUser: {
        id: 'org.inviteUser',
        defaultMessage: 'Invite User'
    },
    inviteAdmin: {
        id: 'org.inviteAdmin',
        defaultMessage: 'Invite Admin'
    },
    inviteAdvisor: {
        id: 'org.inviteAdvisor',
        defaultMessage: 'Invite Advisor'
    },
    enterEmailAddress: {
        id: 'org.enterEmailAddress',
        defaultMessage: 'Enter an email address'
    },
    acceptedOrgInvite: {
        id: 'org.acceptedOrgInvite',
        defaultMessage: 'Accepted organization invite'
    },
    orgSignup: {
        id: 'org.orgSignup',
        defaultMessage: 'Welcome!'
    },
    orgSignupDescription: {
        id: 'org.orgSignupDescription',
        defaultMessage: '{0} has provided you with a LaunchPlan account so that you can collaborate with them on launching your business. We need to collect a few details from you first to complete setting up your account.'
    },
    disclaimer: {
        id: 'org.disclaimer',
        defaultMessage: 'Disclaimer'
    },
    orgInviteDisclaimer: {
        id: 'org.orgInviteDisclaimer',
        defaultMessage: 'You accept that this invite will share all data associated with your account with the {0} organization.'
    },
    acceptAndJoin: {
        id: 'org.acceptAndJoin',
        defaultMessage: 'Accept Invite'
    },
    accountWithOrganization: {
        id: 'org.accountWithOrganization',
        defaultMessage: 'Organizational {0} with '
    },
    orgSubscriptionDescription: {
        id: 'org.orgSubscriptionDescription',
        defaultMessage: 'Your subscription is sponsored by the following organization:'
    },
    inviteUserToJoin: {
        id: 'org.inviteUserToJoin',
        defaultMessage: 'Add a new user'
    },
    inviteUserToJoinDescription: {
        id: 'org.inviteUserToJoinDescription',
        defaultMessage: 'When you create a user, we send them an invite email with a one-time use signup link. Existing LaunchPlan accounts will recieve an email and a notification the next time they sign in.'
    },
    inviteAdminToJoin: {
        id: 'org.inviteAdminToJoin',
        defaultMessage: 'Add a new administrator'
    },
    inviteAdvisorToJoin: {
        id: 'org.inviteAdvisorToJoin',
        defaultMessage: 'Add a new advisor'
    },
    inviteAdvisorToJoinDescription: {
        id: 'org.inviteAdvisorToJoinDescription',
        defaultMessage: 'When you create an advisor, we will send them an invite email.'
    },
    updateAdvisorUsersSuccess: {
        id: 'org.updateAdvisorUsersSuccess',
        defaultMessage: 'Advisor updated successfully'
    },
    updateAdvisorUsersError: {
        id: 'org.updateAdvisorUsersError',
        defaultMessage: 'We could not update this advisor right now'
    },
    orgAdmins: {
        id: 'org.orgAdmins',
        defaultMessage: 'Administrators'
    },
    administrator: {
        id: 'org.administrator',
        defaultMessage: 'administrator'
    },
    advisor: {
        id: 'org.advisor',
        defaultMessage: 'advisor'
    },
    orgAdvisor: {
        id: 'org.orgAdvisor',
        defaultMessage: 'Organizational Advisor'
    },
    orgAccount: {
        id: 'org.orgAccount',
        defaultMessage: 'Organizational User'
    },
    orgAdmin: {
        id: 'org.orgAdmin',
        defaultMessage: 'Organizational Administrator'
    },
    account: {
        id: 'org.account',
        defaultMessage: 'account'
    },
    organization: {
        id: 'org.organization',
        defaultMessage: 'Organization'
    },
    organizations: {
        id: 'org.organizations',
        defaultMessage: 'Organizations'
    },
    createOrg: {
        id: 'org.createOrg',
        defaultMessage: 'Create Org'
    },
    noOrgs: {
        id: 'org.noOrgs',
        defaultMessage: 'No organizations exist'
    },
    noOrgUsers: {
        id: 'org.noOrgUsers',
        defaultMessage: 'No organization users created yet'
    },
    orgCreated: {
        id: 'org.orgCreated',
        defaultMessage: 'Org created'
    },
    addOrgLogo: {
        id: 'org.addOrgLogo',
        defaultMessage: 'Add Organization Logo'
    },
    notAssignedUsersYet: {
        id: 'org.notAssignedUsersYet',
        defaultMessage: `You've not been assigned any clients to work with yet. Speak to one of your organizational administrators to get started.`
    },
    yourAdvisorCompanies: {
        id: 'org.yourAdvisorCompanies',
        defaultMessage: `Companies Assigned To You`
    },
    manageAccount: {
        id: 'org.manageAccount',
        defaultMessage: `Manage Account`
    },
    closeAccount: {
        id: 'org.closeAccount',
        defaultMessage: `Close Account`
    },
    orgAdminsDeleteOwnAccount: {
        id: 'org.orgAdminsDeleteOwnAccount',
        defaultMessage: `Organizational administrators should delete their own accounts in their profile. If that individual has left your organization, please reach out to support to have the account removed.`
    },
    accountType: {
        id: 'org.accountType',
        defaultMessage: `Account Type`
    },
    acceptOrgAccountTransitionSuccess: {
        id: 'org.acceptOrgAccountTransitionSuccess',
        defaultMessage: `You've successfully joined {0}`
    },
    acceptOrgAccountTransitionError: {
        id: 'org.acceptOrgAccountTransitionError',
        defaultMessage: `There was an error trying to join {0}`
    },
})

export default org
