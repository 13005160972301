import gql from 'graphql-tag'
import * as Fragments from '../fragments'

export const getOrgQuery = gql`
  query getOrg ($input: GetOrgInput!) {
    getOrg(input: $input) {
      ...OrgFragment
    }
  }
  ${Fragments.OrgFragment}
`

export const createOrg = gql`
  mutation createOrg ($input: CreateOrgInput!) {
    createOrg(input: $input) {
      ...OrgFragment
    }
  }
  ${Fragments.OrgFragment}
`

export const getFullCompanyData = gql`
  query getFullCompanyData ($input: IdOrgInput!) {
    getFullCompanyData(input: $input) {
      ...CompanyFragment
    }
  }
  ${Fragments.CompanyFragment}
`

export const getAdvisorCompaniesList = gql`
  query getAdvisorCompaniesList ($input: IdOrgInput!) {
    getAdvisorCompaniesList(input: $input) {
      ...CompanyFragment
    }
  }
  ${Fragments.CompanyFragment}
`

export const getOrgUserDetails = gql`
  query getOrgUserDetails ($input: IdOrgInput!) {
    getOrgUserDetails(input: $input) {
      ...UserFragment
    }
  }
  ${Fragments.UserFragment}
`

export const getUserDetailsFromOrgInvite = gql`
  query getUserDetailsFromOrgInvite ($input: IdOrgInput!) {
    getUserDetailsFromOrgInvite(input: $input) {
      _id
      accountType
      org {
        _id
        name
        settings {
          logoId
        }
      }
      sponsor {
        fullName
        email
      }
      email
      inviteSentTime
      accountType
      accepted
    }
  }
`

export const getOrgInviteForUser = gql`
  query getOrgInviteForUser {
    getOrgInviteForUser {
      _id
      accountType
      org {
        _id
        name
        settings {
          logoId
        }
      }
    }
  }
`

export const inviteOrgUser = gql`
  mutation inviteOrgUser ($input: InviteOrgUserInput!) {
    inviteOrgUser(input: $input) {
      ...OrgFragment
    }
  }
  ${Fragments.OrgFragment}
`

export const resendOrgInvite = gql`
  mutation resendOrgInvite ($input: InviteOrgUserInput!) {
    resendOrgInvite(input: $input) {
      ...OrgFragment
    }
  }
  ${Fragments.OrgFragment}
`

export const assignUsersToAdvisor = gql`
  mutation assignUsersToAdvisor ($input: AssignUsersToAdvisorInput!) {
    assignUsersToAdvisor(input: $input) {
      ...OrgFragment
    }
  }
  ${Fragments.OrgFragment}
`

export const checkOrgInvite = gql`
  mutation checkOrgInvite ($input: CheckOrgInviteInput!) {
    checkOrgInvite(input: $input) {
      invite {
        _id
        email
        accepted
        inviteSentTime
        org {
          name
        }
        company {
          _id
          id
        }
      }
      existingUser
    }
  }
`

export const acceptOrgAccountTransition = gql`
  mutation acceptOrgAccountTransition ($input: AcceptOrgAccountTransitionInput!) {
    acceptOrgAccountTransition(input: $input)
  }
`

export const deleteOrgUser = gql`
  mutation deleteOrgUser ($input: DeleteOrgUserInput!) {
    deleteOrgUser(input: $input) {
      ...OrgFragment
    }
  }
  ${Fragments.OrgFragment}
`
