import ReactGA from 'react-ga'
import {
  SET_CANVAS,
  UPDATE_BLOCK,
} from './types'
import Query from '../graphql'
import {
  handleErrors,
} from '../utils/helpers'
import { company } from '../selectors'

export const getCanvas = () => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const canvasId = getState().canvas._id
      const companyId = getState().company.currentCompany._id

      const {
        data: { getCanvas },
      } = await graphqlClient.query({
        query: Query.getCanvasQuery,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            id: canvasId,
            companyId: companyId,
          },
        },
      })

      ReactGA.event({
        category: 'Canvas',
        action: 'User Plan',
        label: 'View',
      })

      dispatch(setCanvasData(getCanvas))
      resolve(getCanvas)
    } catch (err) {
      reject(handleErrors(err))
    }
  })
}

export const updateCanvasBlock = (key, block, canvasId) => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    const { content, completed } = block

    try {
      const companyId = company(getState())._id
      const {
        data: { updateCanvasBlock },
      } = await graphqlClient.mutate({
        mutation: Query.updateCanvasBlock,
        variables: {
          input: {
            companyId,
            canvasId,
            key,
            content: content || '',
            completed,
          },
        },
      })

      dispatch(setCanvasBlockData(updateCanvasBlock))

      ReactGA.event({
        category: 'Canvas',
        action: 'Updaate Canvas Block',
        label: key,
      })

      resolve(updateCanvasBlock)
    } catch (err) {
      reject(handleErrors(err))
    }
  })
}

export const getCanvasBlock = (key, canvasId) => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const companyId = company(getState())._id

      const {
        data: { getCanvasBlock },
      } = await graphqlClient.query({
        query: Query.getCanvasBlock,
        variables: {
          input: {
            id: canvasId,
            key,
            companyId,
          },
        },
      })

      dispatch(setCanvasBlockData(getCanvasBlock))

      ReactGA.event({
        category: 'Canvas',
        action: 'Updaate Canvas Block',
        label: key,
      })

      resolve(getCanvasBlock)
    } catch (err) {
      reject(handleErrors(err))
    }
  })
}

export const setCanvasData = canvas => {
  return function (dispatch) {
    dispatch({
      type: SET_CANVAS,
      payload: canvas,
    })
  }
}

export const setCanvasBlockData = block => {
  return function (dispatch) {
    dispatch({
      type: UPDATE_BLOCK,
      payload: block,
    })
  }
}
