import { toArray } from 'lodash'
import { DateTime } from 'luxon'
import { createSelector } from 'reselect'
import { getResourceIdFromPath } from '../actions/lock.actions'

export const company = state => state.company.currentCompany
export const plan = state => state.plan
export const financials = state => state.financials
export const user = state => state.user.account
export const advisorCompanies = state => state.user.advisorCompanies || []
export const auth = state => state.auth
export const admin = state => state.admin
export const socket = state => state.socket
export const layout = state => state.layout
export const canvas = state => state.canvas
export const org = state => state.org
export const router = state => state.router

export const isAuthenticated = createSelector(auth, auth => auth.authenticated)
export const isTrialExpired = createSelector(auth, auth => auth.trialExpired)
export const isIdentified = createSelector(auth, auth => auth.identified)

export const promotions = createSelector(admin, admin => admin.promotions)
export const usersList = createSelector(admin, admin => admin.users)
export const orgsList = createSelector(admin, admin => admin.orgs)
export const adminUserStats = createSelector(admin, admin => admin.userStats)

export const companySettings = createSelector(
  company,
  company => company.settings,
)

export const forecastStart = createSelector(
  companySettings,
  settings => settings.forecastStart,
)

export const currency = createSelector(
  companySettings,
  settings => settings.currency,
)

export const forecastLength = createSelector(
  companySettings,
  settings => settings.forecastLength,
)

export const userCompanies = createSelector(user, user =>
  user ? user.companies : [],
)

export const userFullName = createSelector(user, user => user.fullName)

export const userSettings = createSelector(user, user => user.settings)

export const isPlatformAdmin = createSelector(
  userSettings,
  userSettings => userSettings.isPlatformAdmin,
)

export const toursSeen = createSelector(
  userSettings,
  userSettings => userSettings.toursSeen || [],
)

export const freeTrialLength = createSelector(
  userSettings,
  userSettings => userSettings.freeTrialLength,
)

export const planSelectedCardId = createSelector(
  plan,
  plan => plan.selectedCard,
)

export const planSelectedChapter = createSelector(
  plan,
  plan => plan.selectedChapter,
)

export const isSoundEnabled = createSelector(
  userSettings,
  userSettings => userSettings.soundEffectsOn
)

export const selectedEntityType = createSelector(
  layout,
  layout => layout.currentEntityType,
)

export const planChapters = createSelector(plan, plan => plan.chapters)

export const planCards = createSelector(plan, plan => plan.cards)

export const planSelectedChapterCards = createSelector(
  [planCards, planChapters, planSelectedChapter],
  (cards, chapters, selectedChapter) => {
    if (!chapters || !selectedChapter || !chapters[selectedChapter]) return []

    const selectedChapterCardIds = chapters[selectedChapter].cards
    const cardsMap = {}

    for (let id of selectedChapterCardIds) {
      cardsMap[id] = cards[id]
    }

    return cardsMap
  },
)

export const planSelectedCard = createSelector(
  [planCards, planSelectedCardId],
  (cards, planSelectedCardId) => {
    if (!planSelectedCardId || !cards[planSelectedCardId]) return {}

    return cards[planSelectedCardId]
  },
)

export const revenues = createSelector(
  financials,
  financials => financials.revenues,
)

export const expenses = createSelector(
  financials,
  financials => financials.expenses,
)

export const costs = createSelector(financials, financials => financials.costs)

export const employees = createSelector(
  financials,
  financials => financials.employees,
)

export const taxes = createSelector(financials, financials => financials.taxes)

export const startingBalance = createSelector(
  financials,
  financials => financials.startingBalance,
)

export const assumptions = createSelector(
  financials,
  financials => financials.assumptions,
)

export const loans = createSelector(financials, financials => financials.loans)

export const linesOfCredit = createSelector(
  financials,
  financials => financials.linesOfCredit,
)

export const investments = createSelector(
  financials,
  financials => financials.investments,
)

export const dividends = createSelector(
  financials,
  financials => financials.dividends,
)

export const assets = createSelector(
  financials,
  financials => financials.assets,
)

export const timePivot = createSelector(
  financials,
  financials => financials.timePivot,
)

export const financialsDocument = createSelector(
  financials,
  financials => financials.document,
)

export const forecasts = createSelector(
  financials,
  financials => financials.forecasts || {},
)

export const profitLossStatement = createSelector(
  forecasts,
  forecasts => forecasts.profitLossStatement,
)

export const breakEvenAnalysis = createSelector(
  forecasts,
  forecasts => forecasts.breakEvenAnalysis,
)

export const balanceSheet = createSelector(
  forecasts,
  forecasts => forecasts.balanceSheet,
)

export const cashFlowStatement = createSelector(
  forecasts,
  forecasts => forecasts.cashFlowStatement,
)

export const selectedWizardItem = createSelector(
  financials,
  financials => financials.selectedWizardItem,
)

export const locks = createSelector(
  socket,
  socket => socket.resourceLocks || [],
)

export const locksMap = createSelector([locks, user], (locks, user) => {
  const locksMap = {}

  locks.forEach(lock => {
    if (lock.userId !== user._id) {
      locksMap[lock.resourceId] = lock
    }
  })

  return locksMap
})

export const isResourceLocked = createSelector(
  [locks, planSelectedCardId, user],
  (locks, selectedCardId, user) => {
    return locks && locks.length && locks.some(
      lock => lock.resourceId === selectedCardId && lock.userId !== user._id,
    )
  },
)

export const isCanvasResourceLocked = createSelector(
  [locks, user, router],
  (locks, user, router) => {
    return locks && locks.length && locks.some(
      lock => lock.resourceId === getResourceIdFromPath(router.location) && lock.userId !== user._id,
    )
  },
)

export const planExports = state => state.dataExports

export const planExportsArray = createSelector(
  [user, planExports],
  (user, planExports) => {
    return toArray(planExports)
  }
)

export const hasRecentExports = createSelector(
  [user, planExportsArray],
  (user, planExportsArray) => {
    return planExportsArray.filter(item => item.url && DateTime.fromISO(item.created).diffNow('day').days > -1).length
  }
)