import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import { hideFlashMessage } from '../../actions/flash.actions'

const styles = theme => ({
  success: {
    backgroundColor: theme.palette.primary.success,
  },
  error: {
    backgroundColor: theme.palette.primary.error,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
})

class FlashMessage extends Component {
  render() {
    const {
      className,
      classes,
      open,
      message,
      onClose,
      hideFlashMessage,
      variant,
    } = this.props
    return (
      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={15000}
        open={open}
        ContentProps={{
          'aria-describedby': 'message-id',
          className: classnames(
            variant ? classes[variant] : classes.success,
            className,
          ),
        }}
        onClose={onClose || hideFlashMessage}
        message={<span id="message-id">{message}</span>}
      />
    )
  }
}

FlashMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
}

function mapStateToProps({ layout }) {
  return {
    open: layout.openFlash,
    message: layout.flashMessage,
    variant: layout.flashVariant,
  }
}

const mapDispatchToProps = {
  hideFlashMessage,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(FlashMessage))
