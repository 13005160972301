import { defineMessages } from 'react-intl'

const assumptions = defineMessages({
    percentSalesOnCreditLabel: {
        id: 'f.percentSalesOnCreditLabel',
        defaultMessage: `What percent of your sales will be made on credit?`,
    },
    percentSalesOnCreditDescription: {
        id: 'f.percentSalesOnCreditDescription',
        defaultMessage: `What part of your revenue comes from credit sales — that is, invoices that your customers will pay later, rather than paying you in cash at the time of purchase? Credit sales will ultimately affect your cash balance, since you have to wait for payment.`,
    },
    accountsReceivableDaysOutstandingLabel: {
        id: 'f.accountsReceivableDaysOutstandingLabel',
        defaultMessage: `How many days will it take to get paid?`,
    },
    accountsReceivableDaysOutstandingDescription: {
        id: 'f.accountsReceivableDaysOutstandingDescription',
        defaultMessage: `How long will it take, on average, to get paid for your credit sales? Obviously, faster payments are better since you can use the cash received to pay expenses or fund growth. Minimize the risk associated with credits sales by establishing timely reminders and collection tactics.`,
    },
    percentPurchasesOnCreditLabel: {
        id: 'f.percentPurchasesOnCreditLabel',
        defaultMessage: `What percent of your purchases will be made on credit?`,
    },
    percentPurchasesOnCreditDescription: {
        id: 'f.percentPurchasesOnCreditDescription',
        defaultMessage: `What portion of your spending (excluding personnel costs) will be on credit from your vendors, rather than paid immediately? From your end, it’s better to accept credit terms whenever possible, so you can keep your cash on hand longer.`,
    },
    accountsPayableDaysOutstandingLabel: {
        id: 'f.accountsPayableDaysOutstandingLabel',
        defaultMessage: `How many days will you take to make your payments?`,
    },
    accountsPayableDaysOutstandingDescription: {
        id: 'f.accountsPayableDaysOutstandingDescription',
        defaultMessage: `How long can you wait to pay for your purchases on credit? Just as slow payments from your customers will hurt your cash flow, so will unnecessarily fast payments to your suppliers. Slowing your payment roll will keep more cash in the bank, giving your company more options to work with.`,
    },
    inventoryDaysOutstandingLabel: {
        id: 'f.inventoryDaysOutstandingLabel',
        defaultMessage: `How many days of inventory will you keep on hand?`,
    },
    inventoryDaysOutstandingDescription: {
        id: 'f.inventoryDaysOutstandingDescription',
        defaultMessage: `How many months’ worth of inventory do you want to keep in stock? We use the direct costs associated with your projected sales to calculate the right amount for each period, ordering more when you need it.`,
    },
    burdenRateLabel: {
        id: 'f.burdenRateLabel',
        defaultMessage: `What is the percentage of extra costs per employee?`,
    },
    burdenRateDescription: {
        id: 'f.burdenRateDescription',
        defaultMessage: `The burden rate on employees is the costs that are related to your employees that are not included in their salaries such as payroll taxes and benefits.`,
    },
})

export default assumptions
