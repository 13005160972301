import {
  CLEAR_COMPANY,
  DELETE_COMPANY,
  SET_COMPANY,
  SET_COMPANY_LOADING,
  SET_ORG
} from '../actions/types'

const initialState = {
  loading: false,
  currentCompany: {
    teamMembers: [],
    settings: {
      owner: {
        id: null
      }
    }
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_COMPANY:
      return {
        ...state,
        currentCompany: action.payload,
      }
    case SET_ORG:
      return initialState
    case SET_COMPANY_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case CLEAR_COMPANY:
    case DELETE_COMPANY:
      return initialState
    default:
      return state
  }
}