import {
  SET_PUBLIC_SHARE_COMPANY,
  SET_PUBLIC_SHARE_PLAN,
  SET_PUBLIC_SHARE_FINANCIALS,
  SET_PUBLIC_SHARE_FORECASTS,
  CLEAR_PUBLIC_SHARE,
} from '../actions/types'

const intialState = {
  loading: false,
  company: {
    teamMembers: [],
    settings: {
      owner: {
        id: null
      }
    }
  },
}

export default function(state = intialState, action) {
  switch (action.type) {
    case SET_PUBLIC_SHARE_COMPANY:
      return {
        ...state,
        company: action.payload,
      }
    case SET_PUBLIC_SHARE_PLAN:
      return {
        ...state,
        plan: action.payload,
      }
    case SET_PUBLIC_SHARE_FINANCIALS:
      return {
        ...state,
        financials: action.payload,
      }
    case SET_PUBLIC_SHARE_FORECASTS:
      return {
        ...state,
        forecasts: action.payload,
      }
    case CLEAR_PUBLIC_SHARE:
      return intialState
    default:
      return state
  }
}