import { defineMessages } from 'react-intl'

const startingBalances = defineMessages({
  cashLabel: {
    id: 'sb.cashLabel',
    defaultMessage: 'Cash'
  },
  cashDescription: {
    id: 'sb.cashDescription',
    defaultMessage: 'How much money do you have in the bank? Keep in mind that these balances should correspond with the start of your forecast.'
  },
  accountsReceivableLabel: {
    id: 'sb.accountsReceivableLabel',
    defaultMessage: 'Accounts receivable'
  },
  accountsReceivableDescription: {
    id: 'sb.accountsReceivableDescription',
    defaultMessage: 'How much do your customers owe you for past sales on credit?'
  },
  inventoryLabel: {
    id: 'sb.inventoryLabel',
    defaultMessage: 'Inventory'
  },
  inventoryDescription: {
    id: 'sb.inventoryDescription',
    defaultMessage: 'If you have inventory, how much is your unsold inventory worth?'
  },
  longTermAssetsLabel: {
    id: 'sb.longTermAssetsLabel',
    defaultMessage: 'Long-term assets'
  },
  longTermAssetsDescription: {
    id: 'sb.longTermAssetsDescription',
    defaultMessage: `Tally your fixed assets here. This should be the full, original value, without including depreciation.`
  },
  accumulatedDepreciationLabel: {
    id: 'sb.accumulatedDepreciationLabel',
    defaultMessage: 'Accumulated depreciation'
  },
  accumulatedDepreciationDescription: {
    id: 'sb.accumulatedDepreciationDescription',
    defaultMessage: 'How much depreciation have you claimed on your fixed assets you entered above so far?'
  },
  depreciationPeriodLabel: {
    id: 'sb.depreciationPeriodLabel',
    defaultMessage: 'Depreciation period'
  },
  depreciationPeriodDescription: {
    id: 'sb.depreciationPeriodDescription',
    defaultMessage: 'How will you spread out the depreciation on the remaining value of the long-term assets you are starting out with?'
  },
  shortTermAssetsLabel: {
    id: 'sb.shortTermAssetsLabel',
    defaultMessage: 'Short-term assets'
  },
  shortTermAssetsDescription: {
    id: 'sb.shortTermAssetsDescription',
    defaultMessage: 'Tally the unamortized value of your short-term assets here.'
  },
  amortizationPeriodLabel: {
    id: 'sb.amortizationPeriodLabel',
    defaultMessage: 'Amortization Period'
  },
  amortizationPeriodDescription: {
    id: 'sb.amortizationPeriodDescription',
    defaultMessage: 'How many months do you want to spread out the value of your short-term assets?'
  },
  paidInCapitalLabel: {
    id: 'sb.paidInCapitalLabel',
    defaultMessage: 'Paid in capital'
  },
  paidInCapitalDescription: {
    id: 'sb.paidInCapitalDescription',
    defaultMessage: 'Enter how much money others have invested in your company as owners or in exchange for equity.'
  },
  equity: {
    id: 'sb.equity',
    defaultMessage: 'Equity'
  },
  liabilities: {
    id: 'sb.liabilities',
    defaultMessage: 'Liabilities'
  },
})

export default startingBalances
