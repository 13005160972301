import { APP_START, RESOURCE_LOCK_UPDATE } from '../actions/types'

const initialState = {
  resourceLocks: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case RESOURCE_LOCK_UPDATE:
      // console.log('RESOURCE_LOCK_UPDATE', action.payload)
      return {
        ...state,
        resourceLocks: action.payload.resourceLocks
      }
    case APP_START:
      return initialState
    default:
      return state
  }
}