import gql from 'graphql-tag'
import * as Fragments from '../fragments'

export const getCanvasQuery = gql`
  query getCanvas ($input: InputGetCanvas) {
    getCanvas(
      input: $input,
    ) {
      ...CanvasFragment
    }
  }
  ${Fragments.CanvasFragment}
`

export const updateCanvasBlock = gql`
  mutation updateCanvasBlock ($input: InputUpdateBlock) {
    updateCanvasBlock(
      input: $input,
    ) {
      ...CanvasBlockFragment
    }
  }
  ${Fragments.CanvasBlockFragment}
`

export const getCanvasBlock = gql`
  query getCanvasBlock ($input: InputGetCanvasBlock) {
    getCanvasBlock(
      input: $input,
    ) {
      ...CanvasBlockFragment
    }
  }
  ${Fragments.CanvasBlockFragment}
`
