import {
  AUTH_USER,
  UNAUTH_USER,
  AUTH_ERROR,
  LOADING,
  NOT_LOADING,
  VALIDATE_VERIFY_URL,
  INVALIDATE_VERIFY_URL,
  VALIDATE_RESET_URL,
  INVALIDATE_RESET_URL,
  USER_IDENTIFIED,
  FREE_TRIAL_EXPIRED,
  CLEAR_FREE_TRIAL_EXPIRED
} from '../actions/types'

const initialState = {
  isLoading: false,
  validVerificationLink: false,
  identified: false,
  trialExpired: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case AUTH_USER:
      return { ...state, error: '', authenticated: true }
    case UNAUTH_USER:
      return { ...state, error: '', authenticated: false }
    case AUTH_ERROR:
      return { ...state, error: action.payload, isLoading: false }
    case LOADING:
      return { ...state, isLoading: true }
    case NOT_LOADING:
      return { ...state, isLoading: false }
    case VALIDATE_VERIFY_URL:
      return { ...state, validVerificationLink: true, isLoading: false }
    case INVALIDATE_VERIFY_URL:
      return { ...state, validVerificationLink: false, isLoading: false }
    case VALIDATE_RESET_URL:
      return { ...state, validResetLink: true, isLoading: false }
    case INVALIDATE_RESET_URL:
      return { ...state, validResetLink: false, isLoading: false }
    case USER_IDENTIFIED:
      return { ...state, identified: true }
    case FREE_TRIAL_EXPIRED:
      return { ...state, trialExpired: true }
    case CLEAR_FREE_TRIAL_EXPIRED:
      return { ...state, trialExpired: false }
    default:
      return state
  }
}