const FREE_ACCOUNT = 'FREE_ACCOUNT'
const MONTHLY_ACCOUNT = 'MONTHLY_ACCOUNT'
const SIX_MONTH_ACCOUNT = 'SIX_MONTH_ACCOUNT'
const YEARLY_ACCOUNT = 'YEARLY_ACCOUNT'
const ORG_ADMIN_ACCOUNT = 'ORG_ADMIN_ACCOUNT'
const ORG_ADVISOR_ACCOUNT = 'ORG_ADVISOR_ACCOUNT'
const ORG_ACCOUNT = 'ORG_ACCOUNT'
const FREE_ACCOUNT_FRIENDLY = 'Free Account'
const MONTHLY_ACCOUNT_FRIENDLY = 'Monthly account'
const SIX_MONTH_ACCOUNT_FRIENDLY = 'Six month account'
const YEARLY_ACCOUNT_FRIENDLY = 'Yearly account'
const ORG_ACCOUNT_FRIENDLY = 'Sponsored account'

export const ACCOUNT_TYPES = {
  FREE_ACCOUNT,
  MONTHLY_ACCOUNT,
  SIX_MONTH_ACCOUNT,
  YEARLY_ACCOUNT,
  ORG_ACCOUNT,
  ORG_ADMIN_ACCOUNT,
  ORG_ADVISOR_ACCOUNT,
  FREE_ACCOUNT_FRIENDLY,
  MONTHLY_ACCOUNT_FRIENDLY,
  SIX_MONTH_ACCOUNT_FRIENDLY,
  YEARLY_ACCOUNT_FRIENDLY,
  ORG_ACCOUNT_FRIENDLY,
}

export const ORG_ACCOUNT_TYPES = [
  ORG_ACCOUNT,
  ORG_ADMIN_ACCOUNT,
  ORG_ADVISOR_ACCOUNT,
]

export const ACCOUNT_PRICES = {
  FREE_ACCOUNT_PRICE: 0,
  MONTHLY_ACCOUNT_PRICE: 18,
  SIX_MONTH_ACCOUNT_PRICE: 90,
  YEARLY_ACCOUNT_PRICE: 144
}

const PROFIT_LOSS = 'PROFIT_LOSS'
const BALANCE_SHEET = 'BALANCE_SHEET'
const CASH_FLOW = 'CASH_FLOW'

export const STATEMENT_TYPES = {
  PROFIT_LOSS,
  BALANCE_SHEET,
  CASH_FLOW
}

const NO_FEE = 'NO_FEE'
const CONSTANT = 'CONSTANT'
const CONSTANT_VALUE_ONLY = 'CONSTANT_VALUE_ONLY'
const VARYING = 'VARYING'
const ONE_TIME = 'ONE_TIME'
const PERCENTAGE_OVERALL_REVENUE = 'PERCENTAGE_OVERALL_REVENUE'
const PERCENTAGE_SPECIFIC_REVENUE = 'PERCENTAGE_SPECIFIC_REVENUE'

const UNIT_SALES = 'UNIT_SALES'
const BILLABLE_HOURS = 'BILLABLE_HOURS'
const RECURRING_CHARGES = 'RECURRING_CHARGES'
const REVENUE_ONLY = 'REVENUE_ONLY'
const UNIT_PRICE = 'UNIT_PRICE'
const HOURS = 'HOURS'
const RATE = 'RATE'
const SIGNUPS = 'SIGNUPS'
const CHARGES = 'CHARGES'
const CHURN_RATE = 'CHURN_RATE'
const UPFRONT_FEE = 'UPFRONT_FEE'

const INVESTMENTS = 'INVESTMENTS'

const EMPLOYEES = 'EMPLOYEES'
const SALARY = 'SALARY'

const EXPENSES = 'EXPENSES'
const COSTS = 'COSTS'

const PRICE = 'PRICE'

const DIVIDENDS = 'DIVIDENDS'

const WITHDRAWLS = 'WITHDRAWLS'
const PAYMENTS = 'PAYMENTS'

const MONTH = 'MONTH'
const YEAR = 'YEAR'
const QUARTERLY = 'QUARTERLY'
const MONTHLY = 'MONTHLY'
const ANNUALLY = 'ANNUALLY'

const LONG_TERM = 'LONG_TERM'
const SHORT_TERM = 'SHORT_TERM'

const COST_GENERAL = 'COST_GENERAL'
const COST_SPECIFIC_REVENUE = 'COST_SPECIFIC_REVENUE'

export const DEPRECIATION_YEARS = [
  2, 3, 5, 7, 10, 15, 20, 25, 27.5, 39, 0
]

export const ACCOUNTS_RECEIVABLE_DAYS = [
  15, 30, 45, 60, 75, 90, 120, 150
]

export const AMOUNT_TYPES = {
  NO_FEE,
  ONE_TIME,
  PERCENTAGE_OVERALL_REVENUE,
  PERCENTAGE_SPECIFIC_REVENUE,
  CONSTANT,
  CONSTANT_VALUE_ONLY,
  VARYING
}

export const BILLING_PERIOD = {
  MONTH,
  YEAR
}

export const COSTS_TYPES = {
  COST_GENERAL,
  COST_SPECIFIC_REVENUE
}

export const REVENUE_TYPES = {
  UNIT_SALES,
  BILLABLE_HOURS,
  RECURRING_CHARGES,
  REVENUE_ONLY
}

export const FREQUENCY_TYPES = {
  MONTHLY,
  QUARTERLY,
  ANNUALLY
}

const PHYSICAL_SPACE = 'PHYSICAL_SPACE'
const EQUITMENT = 'EQUITMENT'
const MARKETING = 'MARKETING'
const OTHER = 'OTHER'

export const EXPENSE_TYPES = {
  PHYSICAL_SPACE,
  EQUITMENT,
  MARKETING,
  OTHER
}

const INDIVIDUAL = 'INDIVIDUAL'
const GROUP = 'GROUP'
const REGULAR = 'REGULAR'
const DIRECT = 'DIRECT'
const FULLTIME = 'FULLTIME'
const CONTRACT = 'CONTRACT'

export const EMPLOYEE_TYPES = {
  INDIVIDUAL,
  GROUP
}

export const EMPLOYEE_STATUS_TYPES = {
  FULLTIME,
  CONTRACT
}

export const LABOR_TYPES = {
  REGULAR,
  DIRECT
}

export const ASSET_TYPES = {
  LONG_TERM,
  SHORT_TERM
}
export const FORM_TYPES = {
  SINGLE: 'SINGLE',
  STEPS: 'STEPS'
}

const revenuePartTypes = {
  UNIT_PRICE,
  UNIT_SALES,
  HOURS,
  RATE,
  SIGNUPS,
  CHARGES,
  CHURN_RATE,
  REVENUE_ONLY,
  UPFRONT_FEE
}

const employeePartTypes = {
  EMPLOYEES,
  SALARY
}

const expensesPartTypes = {
  EXPENSES
}

const costsPartTypes = {
  COSTS
}

const investmentsPartTypes = {
  INVESTMENTS
}

const assetPartTypes = {
  PRICE
}

const lineOfCreditPartTypes = {
  WITHDRAWLS,
  PAYMENTS
}

const dividendsPartTypes = {
  DIVIDENDS
}

export const FINANCIAL_WIZARDS = {
  revenues: {
    type: FORM_TYPES.STEPS,
    form: 'revenues',
    title: 'Revenue',
    labels: {
      name: 'Name',
      type: 'Type',
      unitSales: 'Unit Sales',
      unitPrice: 'Unit Price',
      hours: 'Hours',
      rate: 'Rate',
      signups: 'Signups',
      charges: 'Charges',
      churnRate: 'Churn Rate',
      revenue: 'Revenue'
    },
    partTypes: {
      ...revenuePartTypes
    }
  },
  expenses: {
    type: FORM_TYPES.SINGLE,
    form: 'expenses',
    title: 'Expenses',
    partTypes: {
      ...expensesPartTypes
    }
  },
  costs: {
    type: FORM_TYPES.SINGLE,
    form: 'costs',
    title: 'Cost of Goods Sold',
    partTypes: {
      ...costsPartTypes
    }
  },
  employees: {
    type: FORM_TYPES.STEPS,
    form: 'employees',
    title: 'Employees',
    labels: {
      name: 'Name',
      type: 'Type',
      amount: 'Amount',
      status: 'Status'
    },
    partTypes: {
      ...employeePartTypes
    }
  },
  taxes: {
    type: FORM_TYPES.STEPS,
    form: 'taxes',
    title: 'Taxes',
    labels: {
      corporate: 'Corporate',
      sales: 'Sales'
    }
  },
  assumptions: {
    type: FORM_TYPES.SINGLE,
    form: 'assumptions',
    title: 'Assumptions'
  },
  investments: {
    type: FORM_TYPES.SINGLE,
    form: 'investments',
    title: 'Investments',
    partTypes: {
      ...investmentsPartTypes
    }
  },
  assets: {
    type: FORM_TYPES.STEPS,
    form: 'assets',
    title: 'Assets',
    labels: {
      name: 'Name',
      type: 'Type',
      price: 'Price',
      life: 'Life'
    },
    partTypes: {
      ...assetPartTypes
    }
  },
  startingBalance: {
    type: FORM_TYPES.STEPS,
    form: 'startingBalance',
    title: 'Starting Balances',
    labels: {
      assets: 'Assets',
      liabilities: 'Liabilities',
      equity: 'Equity'
    }
  },
  loans: {
    type: FORM_TYPES.SINGLE,
    form: 'loans',
    title: 'Loans'
  },
  dividends: {
    type: FORM_TYPES.SINGLE,
    form: 'dividends',
    title: 'Dividends',
    partTypes: {
      ...dividendsPartTypes
    }
  },
  linesOfCredit: {
    type: FORM_TYPES.STEPS,
    form: 'linesOfCredit',
    title: 'Line of Credit',
    labels: {
      name: 'Name',
      terms: 'Terms',
      withdrawls: 'Withdrawls',
      payments: 'Payments'
    },
    partTypes: {
      ...lineOfCreditPartTypes
    }
  }
}

export const ENTITY_TYPES = {
  COMPANY: 'COMPANY',
  ORG: 'ORG',
}

export const CANVAS_BLOCKS = [
  'keyPartners',
  'keyActivities',
  'keyResources',
  'valuePropositions',
  'customerRelationships',
  'channels',
  'customerSegments',
  'costStructure',
  'revenueStreams',
]