import gql from 'graphql-tag'
import * as Fragments from '../fragments'

export const getPlanQuery = gql`
  query getPlan ($id: String!, $company: String!) {
    getPlan(
      id: $id
      company: $company
    ) {
      ...PlanFragment
    }
  }
  ${Fragments.PlanFragment}
`

export const getCardQuery = gql`
  query getCard ($id: String!, $company: String!) {
    getCard(
      id: $id
      company: $company
    ) {
      ...CardFragment
    }
  }
  ${Fragments.CardFragment}
`

export const updateCardMutation = gql`
  mutation updateCard ($id: String!, $title: String!, $content: String!, $sequence: Int!, $company: String!, $completed: Boolean) {
    updateCard(
      id: $id
      title: $title
      content: $content
      sequence: $sequence
      company: $company
      completed: $completed
    ) {
      ...CardFragment
    }
  }
  ${Fragments.CardFragment}
`

export const updateCardSequenceMutation = gql`
  mutation updateCardSequence ($chapter: String!, $cards: [String!], $company: String!) {
    updateCardSequence(
      chapter: $chapter
      cards: $cards
      company: $company
    ) {
      id
      sequence
    }
  }
`

export const updateChapterSequenceMutation = gql`
  mutation updateChapterSequence ($plan: String!, $chapters: [String!], $company: String!) {
    updateChapterSequence(
      plan: $plan
      chapters: $chapters
      company: $company
    ) {
      id
      sequence
    }
  }
`

export const createCardMutation = gql`
  mutation createCard ($chapterId: String!, $userId: String!, $sequence: Int!, $company: String!) {
    createCard(
      chapter: $chapterId
      creator: $userId
      sequence: $sequence
      company: $company
    ) {
      ...CardFragment
    }
  }
  ${Fragments.CardFragment}
`

export const deleteCardMutation = gql`
  mutation deleteCard ($id: String!, $company: String!) {
    deleteCard(
      id: $id
      company: $company
    )
  }
`

export const createChapterMutation = gql`
mutation createChapter ($companyId: String!, $userId: String!, $sequence: Int!) {
  createChapter(
    company: $companyId
    creator: $userId
    sequence: $sequence
  ) {
    ...ChapterFragment
  }
}
${Fragments.ChapterFragment}
`

export const updateChapterMutation = gql`
mutation updateChapter ($id: String!, $title: String!, $sequence: Int!, $company: String!) {
  updateChapter(
    id: $id
    title: $title,
    sequence: $sequence
    company: $company
  ) {
    ...ChapterFragment
  }
}
${Fragments.ChapterFragment}
`

export const deleteChapterMutation = gql`
mutation deleteChapter ($id: String!, $company: String!) {
  deleteChapter(
    id: $id
    company: $company
  )
}
`