import { defineMessages } from 'react-intl'

const canvas = defineMessages({
  canvas: {
    id: 'bmc.canvas',
    defaultMessage: 'Canvas',
  },
  businessMdoelCanvas: {
    id: 'bmc.businessMdoelCanvas',
    defaultMessage: 'Business Model Canvas',
  },
  canvasDescription: {
    id: 'bmc.canvasDescription',
    defaultMessage: 'Describe the rationale of how your company creates, delivers, and captures value.',
  },
  keyPartners: {
    id: 'bmc.keyPartners',
    defaultMessage: 'Key Partners',
  },
  keyActivities: {
    id: 'bmc.keyActivities',
    defaultMessage: 'Key Activities',
  },
  keyResources: {
    id: 'bmc.keyResources',
    defaultMessage: 'Key Resources',
  },
  valuePropositions: {
    id: 'bmc.valuePropositions',
    defaultMessage: 'Value Propositions',
  },
  customerRelationships: {
    id: 'bmc.customerRelationships',
    defaultMessage: 'Customer Relationships',
  },
  channels: {
    id: 'bmc.channels',
    defaultMessage: 'Channels',
  },
  customerSegments: {
    id: 'bmc.customerSegments',
    defaultMessage: 'Customer Segments',
  },
  costStructure: {
    id: 'bmc.costStructure',
    defaultMessage: 'Cost Structure',
  },
  revenueStreams: {
    id: 'bmc.revenueStreams',
    defaultMessage: 'Revenue Streams',
  },
  exportCanvas: {
    id: 'bmc.exportCanvas',
    defaultMessage: 'Export canvas',
  },
})

export default canvas
