import React from 'react'
import { withStyles } from '@material-ui/core'
import Rocket from './logo_rocket'
import LogoText from './logo_text'

const styles = theme => ({
  logoRocket: {
    maxWidth: 50,
    margin: '0 auto',
    marginBottom: '1rem'
  }, 
  logoWrapper: {
    width: 200,
    margin: '0 auto',
    marginBottom: '3rem'
  }
})

const LogoVertical = (props) => {
  const { classes } = props

  return (
    <a href='/'>
      <div className={classes.logoWrapper}>
        <div className={classes.logoRocket}>
          <Rocket />
        </div>
        <div className={classes.logoText}>
          <LogoText />
        </div>
      </div>
    </a>
  )
}

export default withStyles(styles)(LogoVertical)