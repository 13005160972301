import axios from 'axios'
import { APP_START, WEB_SOCKET_CONNECTED } from './types'
import { updateUser } from './user.actions'
import { user } from '../selectors'

export const applicationStart = () => {
  return function (dispatch, getState) {
    dispatch({
      type: APP_START,
    })

    if (getState().user.account && getState().router && getState().router.location && getState().router.location.pathname.includes('dashboard')) {
      dispatch(updateUser())
    }
  }
}

export function webSocketConnected(url) {
  return {
    type: WEB_SOCKET_CONNECTED,
    payload: {
      url,
    },
  }
}

export const logErrorToBackend = (error, info, level) => (
  dispatch,
  getState,
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const values = {
        level: level || 'ERROR',
        error,
        info,
        location: window.location,
        userId: user(getState())._id,
        userAgent: navigator.userAgent,
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/logClientError`,
        values,
      )

      resolve(response)
    } catch (errors) {
      // eat it
      reject()
    }
  })
}
