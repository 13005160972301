import { defineMessages } from 'react-intl'

const assets = defineMessages({
  shortTermUsefulLifeLabel: {
    id: 'f.shortTermUsefulLifeLabel',
    defaultMessage: `Over how many months do you want to spread out your asset’s
    value?`,
  },
  shortTermUsefulLifeDescription: {
    id: 'f.shortTermUsefulLifeDescription',
    defaultMessage: `Determine the lifespan over which this asset will provide value,
    so that we can expense an appropriate portion each month. For
    instance, choose 12 months for something like an annual
    contract. If you don’t feel like expensing at all (as with a
    security deposit, for example), choose “Do not expense” instead.`,
  },
  longTermUsefulLifeLabel: {
    id: 'f.longTermUsefulLifeLabel',
    defaultMessage: `How many years will this asset be useful?`,
  },
  longTermUsefulLifeDescription: {
    id: 'f.longTermUsefulLifeDescription',
    defaultMessage: `The trick to long term assets is to spread out the asset’s
    purchase expense over the span of its useful life. How long do
    you expect this asset to continue providing value to your
    business?`,
  },
  salvageValueLabel: {
    id: 'f.salvageValueLabel',
    defaultMessage: `Does this asset have a salvage value?`,
  },
  salvageValueDescription: {
    id: 'f.salvageValueDescription',
    defaultMessage: `What value does the item hold after it has reached the end
    of its useful life? Salvage value, or residual value, can
    often provide a small boost in capital.`,
  },
  resellLabel: {
    id: 'f.resellLabel',
    defaultMessage: `Do you think you will resell this asset before the end of
    your forecast period?`,
  },
  resellPriceLabel: {
    id: 'f.resellPriceLabel',
    defaultMessage: `How much will you sell it for?`,
  },
  resellDateLabel: {
    id: 'f.resellDateLabel',
    defaultMessage: `When will you sell it?`,
  },
  assetNameLabel: {
    id: 'f.assetNameLabel',
    defaultMessage: `What do you want to call this asset?`,
  },
  assetPriceLabel: {
    id: 'f.assetPriceLabel',
    defaultMessage: `How do you want to enter the cost?`,
  },
  assetTypeLabel: {
    id: 'f.assetTypeLabel',
    defaultMessage: `What type of asset is this?`,
  },
  assetTypeDescription: {
    id: 'f.assetTypeDescription',
    defaultMessage: `Draw a clear line between your “short term” and “long term” assets. Short term assets, like an annual service contract, are used up in a matter of 12 months or less. Long term assets, like a delivery van or a pricey piece of equipment, keep providing value year after year.`,
  },
})

export default assets
