import { omit } from 'lodash'
import {
  ADD_CARD,
  ADD_CHAPTER,
  CLEAR_COMPANY,
  DELETE_CARD,
  DELETE_CHAPTER,
  DONE_EDITING,
  EDIT_CARD,
  SELECTED_CHAPTER,
  SET_PLAN,
  UPDATE_CARD,
  UPDATE_CHAPTER,
  UPDATE_CARD_SEQUENCES,
  UPDATE_CHAPTER_SEQUENCES,
} from '../actions/types'

const intialState = {
  selectedCard: null,
}

export default function(state = intialState, action) {
  switch (action.type) {
    case SET_PLAN:
      return {
        ...state,
        ...action.payload,
      }
    case ADD_CARD:
      return {
        ...state,
        chapters: addCardToChapter(state.chapters, action),
        cards: addCard(state.cards, action),
      }
    case UPDATE_CARD:
      return {
        ...state,
        cards: updateCard(state.cards, action),
      }
    case UPDATE_CARD_SEQUENCES:
      return {
        ...state,
        cards: updateCardSequences(state.cards, action),
      }
    case UPDATE_CHAPTER_SEQUENCES:
      return {
        ...state,
        chapters: updateChapterSequences(state.chapters, action),
      }
    case EDIT_CARD:
      return {
        ...state,
        selectedCard: action.payload.id,
      }
    case DELETE_CARD:
      return {
        ...state,
        chapters: deleteCardFromChapter(state.chapters, action),
        cards: deleteCard(state.cards, action),
      }
    case ADD_CHAPTER:
      return {
        ...state,
        chapters: addChapter(state.chapters, action),
        selectedChapter: action.payload.id,
      }
    case UPDATE_CHAPTER:
      return {
        ...state,
        chapters: updateChapter(state.chapters, action),
      }
    case DELETE_CHAPTER:
      return {
        ...state,
        chapters: deleteChapter(state.chapters, action),
        selectedChapter: null,
      }
    case SELECTED_CHAPTER:
      return { ...state, selectedChapter: action.payload }
    case DONE_EDITING:
      return { ...state, selectedCard: {} }
    case CLEAR_COMPANY:
      return intialState
    default:
      return state
  }
}

function addCard(state, action) {
  const {
    payload: { card },
  } = action

  return {
    ...state,
    [card.id]: card,
  }
}

function addCardToChapter(state, action) {
  const {
    payload: { card, chapterId },
  } = action

  const chapter = state[chapterId]

  return {
    ...state,
    [chapterId]: {
      ...chapter,
      cards: chapter.cards.concat(card.id),
    },
  }
}

function deleteCard(state, action) {
  const {
    payload: { card },
  } = action

  const cards = omit(state, card.id)

  return {
    ...cards,
  }
}

function deleteCardFromChapter(state, action) {
  const {
    payload: { card, chapterId },
  } = action

  const chapter = state[chapterId]

  const cards = chapter.cards.filter(item => {
    if (card.id === item) {
      return null
    }

    return item
  })

  return {
    ...state,
    [chapterId]: {
      ...chapter,
      cards,
    },
  }
}

function updateCard(state, action) {
  const { payload } = action

  return {
    ...state,
    [payload.id]: payload,
  }
}

function updateCardSequences(state, action) {
  const { payload } = action

  const updates = {}
  
  payload.forEach(card => {
    updates[card.id] = {
      ...state[card.id],
      sequence: card.sequence
    }
  })

  return {
    ...state,
    ...updates
  }
}

function updateChapterSequences(state, action) {
  const { payload } = action

  const updates = {}
  
  payload.forEach(chapter => {
    updates[chapter.id] = {
      ...state[chapter.id],
      sequence: chapter.sequence
    }
  })

  return {
    ...state,
    ...updates
  }
}

function addChapter(state, action) {
  const {
    payload: { chapter },
  } = action

  return {
    ...state,
    [chapter.id]: chapter,
  }
}

function updateChapter(state, action) {
  const { payload } = action

  return {
    ...state,
    [payload.id]: payload,
  }
}

function deleteChapter(state, action) {
  const {
    payload: { chapterId },
  } = action

  const chapters = omit(state, chapterId)

  return {
    ...chapters,
  }
}
