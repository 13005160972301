import { defineMessages } from 'react-intl'

const Marketing = defineMessages({
  tryForFree: {
    id: 'marketing.tryForFree',
    defaultMessage: 'Try for free',
  },
  goToDashboard: {
    id: 'marketing.goToDashboard',
    defaultMessage: 'Go to dashboard',
  },
  homepageHeadline: {
    id: 'marketing.homepageHeadline',
    defaultMessage:
      'The most intuitive and affordable way to create a successful business plan',
  },
  homepageSubheading: {
    id: 'marketing.homepageSubheading',
    defaultMessage: 'Business Plans. Simplified.',
  },
  automaticFinancials: {
    id: 'marketing.automaticFinancials',
    defaultMessage: 'Automated projections',
  },
  automaticFinancialsHeadline: {
    id: 'marketing.automaticFinancialsHeadline',
    defaultMessage:
      'Simple business planning and automated financial forecasting',
  },
  automaticFinancialsDescription: {
    id: 'marketing.automaticFinancialsDescription',
    defaultMessage:
      'From writing your plan to forecasting financial results, it’s all in LaunchPlan’s smart and simple business plan software. Step-by-step instructions organize your ideas and automated projections are displayed in modern charts and graphs for investors.',
  },
  forEveryone: {
    id: 'marketing.forEveryone',
    defaultMessage: 'For everyone',
  },
  tellTheWorld: {
    id: 'marketing.tellTheWorld',
    defaultMessage: 'Where your ideas meet action',
  },
  tellTheWorldDescription: {
    id: 'marketing.tellTheWorldDescription',
    defaultMessage:
      'Share your ideas and invite others to collaborate. With LaunchPlan, unlimited team members can view, edit, and contribute without additional subscription fees. Our web-based business plan creator is accessible across devices and operating systems, whether you’re on a Mac or PC, or running macOS, Windows, Linux, iOS, or Android.',
  },
  startFreeTrial: {
    id: 'marketing.startFreeTrial',
    defaultMessage: 'Start free trial',
  },
  pricing: {
    id: 'marketing.pricing',
    defaultMessage: 'Pricing',
  },
  seePricingOptions: {
    id: 'marketing.seePricingOptions',
    defaultMessage: 'See pricing options',
  },
  featuresHeadline: {
    id: 'marketing.featuresHeadline',
    defaultMessage: `Launch Your Ideas`,
  },
  featuresSubheading: {
    id: 'marketing.featuresSubheading',
    defaultMessage:
      'Quickly and easily create your business plan and financial projections.',
  },
  programs: {
    id: 'marketing.programs',
    defaultMessage: 'Solutions',
  },
  programsHeadline: {
    id: 'marketing.programsHeadline',
    defaultMessage: 'LaunchPlan was built for...',
  },
  programsSubheading: {
    id: 'marketing.programsSubheading',
    defaultMessage: 'LaunchPlan is proud to help all, and for our work with:',
  },
  entrepreneurs: {
    id: 'marketing.entrepreneurs',
    defaultMessage: 'Entrepreneurs',
  },
  entrepreneursHeadline: {
    id: 'marketing.entrepreneursHeadline',
    defaultMessage: 'Start your company with LaunchPlan',
  },
  entrepreneursDescription: {
    id: 'marketing.entrepreneursDescription',
    defaultMessage:
      'We understand how difficult it can be to get your startup going. LaunchPlan is the most intuitive and user-friendly business planning website out there. Even if this is your first time writing a business plan, we help you each step of the way.',
  },
  coworkingSpaces: {
    id: 'marketing.coworkingSpaces',
    defaultMessage: 'Coworking Spaces',
  },
  coworkingSpacesHeadline: {
    id: 'marketing.coworkingSpacesHeadline',
    defaultMessage: 'Helping the community',
  },
  coworkingSpacesDescription: {
    id: 'marketing.coworkingSpacesDescription',
    defaultMessage:
      'We believe in helping entrepreneurs and any growing business. If you are a member of a coworking space, or run a coworking space, we can help you get your business plan and financial projections done. Contact us to get signed up.',
  },
  veterans: {
    id: 'marketing.veterans',
    defaultMessage: 'Veterans',
  },
  veteransHeadline: {
    id: 'marketing.veteransHeadline',
    defaultMessage: 'Thank you for your service',
  },
  veteransDescription: {
    id: 'marketing.veteransDescription',
    defaultMessage:
      'LaunchPlan proudly supports our veterans. As a way of showing our appreciation for your service, we offer veterans with free access for a period of time and ongoing discounts through any VA programs. Tell your local VA office to contact us now to learn more.',
  },
  universities: {
    id: 'marketing.universities',
    defaultMessage: 'Universities',
  },
  universitiesHeadline: {
    id: 'marketing.universitiesHeadline',
    defaultMessage: 'Start your company from class',
  },
  universitiesDescription: {
    id: 'marketing.universitiesDescription',
    defaultMessage:
      'We offer great promotions to professors teaching entrepreneurship courses or those that push entrepreneurial initiatives within a university. If your students are writing business plans, let us help them make that process easy. LaunchPlan can be used in a classroom, a business plan competition, an incubator and in many other ways. If you would like to learn more about the promotions we offer to professors and students contact us today.',
  },
  stepByStepPlanning: {
    id: 'marketing.stepByStepPlanning',
    defaultMessage: 'Step-By-Step Planning',
  },
  stepByStepPlanningHeadline: {
    id: 'marketing.stepByStepPlanningHeadline',
    defaultMessage: 'Plan stress-free',
  },
  stepByStepPlanningDescription: {
    id: 'marketing.stepByStepPlanningDescription',
    defaultMessage:
      'Create a detailed and complete business plan with step-by-step instructions. LaunchPlan offers full definitions, examples, and tutorials in every section.',
  },
  automaticFinancialsFeatureHeadline: {
    id: 'marketing.automaticFinancialsFeatureHeadline',
    defaultMessage: 'No spreadsheets needed',
  },
  automaticFinancialsFeatureDescription: {
    id: 'marketing.automaticFinancialsFeatureDescription',
    defaultMessage:
      'LaunchPlan helps you create a profit & loss (income statement), balance sheet, and cash flow statements. Think TurboTax for financial modeling.',
  },
  unlimitedTeam: {
    id: 'marketing.unlimitedTeam',
    defaultMessage: 'Unlimited collaboration',
  },
  unlimitedTeamHeadline: {
    id: 'marketing.unlimitedTeamHeadline',
    defaultMessage: 'Team members there to help',
  },
  unlimitedTeamDescription: {
    id: 'marketing.unlimitedTeamDescription',
    defaultMessage: `Others charge for additional users. We don't. LaunchPlan allows unlimited team members to work on a business plan together for free.`,
  },
  investorReady: {
    id: 'marketing.investorReady',
    defaultMessage: 'Investor ready plan',
  },
  investorReadyHeadline: {
    id: 'marketing.investorReadyHeadline',
    defaultMessage: 'Show them what they care about',
  },
  investorReadyDescription: {
    id: 'marketing.investorReadyDescription',
    defaultMessage: `LaunchPlan creates a professional looking business plan to export or share online. You can feel confident that your plan will be organized and look great.`,
  },
  contactUs: {
    id: 'marketing.contactUs',
    defaultMessage: `Contact Us`,
  },
  getInTouchWithUs: {
    id: 'marketing.getInTouchWithUs',
    defaultMessage: `Get in touch with us`,
  },
  message: {
    id: 'marketing.message',
    defaultMessage: `Message`,
  },
  thankYouForYourMessage: {
    id: 'marketing.thankYouForYourMessage',
    defaultMessage: `Thank you for your message.`,
  },
  wellRespondBackToYouSoon: {
    id: 'marketing.wellRespondBackToYouSoon',
    defaultMessage: `We'll respond back to you soon.`,
  },
  subscriptionHeadline: {
    id: 'o.subscriptionHeadline',
    defaultMessage: `Choose a plan that's right for you`,
  },
  subscriptionSubHeading: {
    id: 'o.subscriptionSubHeading',
    defaultMessage: `Select your subscription option and get started.`,
  },
  paymentHeadline: {
    id: 'o.paymentHeadline',
    defaultMessage: `Set up your payment`,
  },
  paymentSubHeading: {
    id: 'o.paymentSubHeading',
    defaultMessage: `Good choice. No risk, cancel anytime.`,
  },
  companyHeadline: {
    id: 'o.companyHeadline',
    defaultMessage: `Company information`,
  },
  companySubHeading: {
    id: 'o.companySubHeading',
    defaultMessage: `Provide quick details for your company. This can be changed later. `,
  },
  forecastHeadline: {
    id: 'o.forecastHeadline',
    defaultMessage: `Forecast`,
  },
  forecastSubHeading: {
    id: 'o.forecastSubHeading',
    defaultMessage: `We're almost finished! Let's set the context for your financial forecasts.`,
  },
  features: {
    id: 'o.features',
    defaultMessage: `Features`,
  },
  oneCompany: {
    id: 'o.oneCompany',
    defaultMessage: `1 company`,
  },
  stepByStepInstructions: {
    id: 'o.stepByStepInstructions',
    defaultMessage: `Step-by-step instructions`,
  },
  exportBusinessPlan: {
    id: 'o.exportBusinessPlan',
    defaultMessage: `Export business plan`,
  },
  easyToFollowFormat: {
    id: 'o.easyToFollowFormat',
    defaultMessage: `Easy to follow format`,
  },
  unlimtedTeamMembers: {
    id: 'o.unlimtedTeamMembers',
    defaultMessage: `Unlimted team members*`,
  },
  trialLength: {
    id: 'o.trialLength',
    defaultMessage: `14 day trial`,
  },
  unlimitedCompanies: {
    id: 'o.unlimitedCompanies',
    defaultMessage: `Unlimited companies`,
  },
  cancelAnytime: {
    id: 'o.cancelAnytime',
    defaultMessage: `Cancel anytime`,
  },
  yearlyDiscount: {
    id: 'o.yearlyDiscount',
    defaultMessage: `Save 20% on yearly subscriptions`,
  },
  costBreakdown: {
    id: 'o.costBreakdown',
    defaultMessage: 'Per active user, per month',
  },
  choosePlan: {
    id: 'o.choosePlan',
    defaultMessage: 'Choose plan',
  },
  makePayment: {
    id: 'o.makePayment',
    defaultMessage: 'Make payment',
  },
  welcomeToLaunchPlan: {
    id: 'o.welcomeToLaunchPlan',
    defaultMessage: 'Welcome to LaunchPlan',
  },
  launchACompanyToStart: {
    id: 'o.launchACompanyToStart',
    defaultMessage: 'To get started, launch a company!',
  },
  pricingHeadline: {
    id: 'pricing.pricingHeadline',
    defaultMessage: 'Pricing',
  },
  pricingSubheading: {
    id: 'pricing.pricingSubheading',
    defaultMessage:
      'Collaborate with unlimited team members. No additional fees.',
  },
  freeTrial: {
    id: 'pricing.freeTrial',
    defaultMessage: 'Free trial',
  },
  freeCaption: {
    id: 'pricing.freeCaption',
    defaultMessage: 'Free business model canvas trial. No credit card required.',
  },
  freeTrialCaption: {
    id: 'pricing.freeTrialCaption',
    defaultMessage: 'Free trial. No credit card required.',
  },
  signupCaption: {
    id: 'pricing.signupCaption',
    defaultMessage: '60 day risk-free trial. 100% money back guarantee.',
  },
  monthlyCaption: {
    id: 'pricing.monthlyCaption',
    defaultMessage: 'No risk. Cancel anytime.',
  },
  biAnnuallyCaption: {
    id: 'pricing.biAnnuallyCaption',
    defaultMessage: '$15 per month. 17% instant savings.',
  },
  annualCaption: {
    id: 'pricing.annualCaption',
    defaultMessage: '$12 per month. 33% instant savings.',
  },
  sixMonths: {
    id: 'pricing.sixMonths',
    defaultMessage: '6 months',
  },
  chooseSixMonthsPlan: {
    id: 'pricing.chooseSixMonthsPlan',
    defaultMessage: 'Choose 6 months plan',
  },
  chooseMonthlyPlan: {
    id: 'pricing.chooseMonthlyPlan',
    defaultMessage: 'Choose monthly plan',
  },
  chooseYearlyPlan: {
    id: 'pricing.chooseYearlyPlan',
    defaultMessage: 'Choose yearly plan',
  },
  changeToSixMonthsPlan: {
    id: 'pricing.changeSixMonthsPlan',
    defaultMessage: 'Change to 6 months plan',
  },
  changeToMonthlyPlan: {
    id: 'pricing.changeMonthlyPlan',
    defaultMessage: 'Change to monthly plan',
  },
  changeToYearlyPlan: {
    id: 'pricing.changeYearlyPlan',
    defaultMessage: 'Change to yearly plan',
  },
  currentPlan: {
    id: 'pricing.currentPlan',
    defaultMessage: 'Current plan',
  },
  finePrint: {
    id: 'pricing.finePrint',
    defaultMessage:
      '* Users may invite unlimited team members to collaborate in writing a business plan. Creating new business plans require an individual LaunchPlan subscription.',
  },
  subscribeNow: {
    id: 'user.subscribeNow',
    defaultMessage: `Subscribe now`,
  },
})

export default Marketing
