import { defineMessages } from 'react-intl'

const errors = defineMessages({
  required: {
    id: 'errors.required',
    defaultMessage: 'Required'
  },
  invalidEmail: {
    id: 'errors.invalidEmail',
    defaultMessage: 'Invalid email address'
  },
  passwordsMustMatch: {
    id: 'errors.passwordsMustMatch',
    defaultMessage: 'Passwords must match'
  },
  passwordRequirementsNotMet: {
    id: 'errors.passwordRequirementsNotMet',
    defaultMessage: 'Password requirements not met'
  },
  signinError: {
    id: 'errors.signin',
    defaultMessage: 'Whoops, email and/or password is invalid'
  },
  notVerified: {
    id: 'errors.notVerified',
    defaultMessage: `It looks like you haven't verified your account email yet, please enter the verification code we sent over when you signed up. Can't find it? No worries, we can send you a new one.`
  },
  notAuthorized: {
    id: 'errors.notAuthorized',
    defaultMessage: `You're not authorized for that.`
  }
})

export default errors
