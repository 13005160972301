import gql from 'graphql-tag'
import * as Fragments from '../fragments'

export const createCompanyMutation = gql`
  mutation createCompany ($input: CreateCompanyInput) {
    createCompany(
      input: $input
    ) {
      ...MeFragment
    }
  }
  ${Fragments.MeFragment}
`

export const deleteCompanyMutation = gql`
  mutation deleteCompany ($input: DeleteCompanyInput) {
    deleteCompany(
      input: $input
    )
  }
`

export const updateCompanySettingsMutation = gql`
  mutation updateCompanySettings ($input: CompanySettingsInput!) {
    updateCompanySettings(
      input: $input,
    ) {
      ...CompanyFragment
    }
  }
  ${Fragments.CompanyFragment}
`

export const changeCompanyOwner = gql`
  mutation changeCompanyOwner ($input: ChangeCompanyOwnerInput!) {
    changeCompanyOwner(
      input: $input,
    ) {
      ...CompanyFragment
    }
  }
  ${Fragments.CompanyFragment}
`

export const getCompanyQuery = gql`
  query getCompany ($id: String!) {
    getCompany(id: $id) {
      ...CompanyFragment
    }
  }
  ${Fragments.CompanyFragment}
`

export const getShareContent = gql`
  query getShareContent ($id: String!) {
    getShareContent(id: $id) {
      ...CompanyFragment
    }
  }
  ${Fragments.CompanyFragment}
`

export const getCanvasShareContent = gql`
  query getCanvasShareContent ($id: String!) {
    getCanvasShareContent(id: $id) {
      ...CompanyFragment
    }
  }
  ${Fragments.CompanyFragment}
`

export const toggleSharingEnabled = gql`
  mutation toggleSharingEnabled ($input: ToggleSharingEnabledInput) {
    toggleSharingEnabled(input: $input) {
      ...CompanyFragment
    }
  }
  ${Fragments.CompanyFragment}
`

export const toggleCanvasSharing = gql`
  mutation toggleCanvasSharing ($input: ToggleSharingEnabledInput) {
    toggleCanvasSharing(input: $input) {
      ...CompanyFragment
    }
  }
  ${Fragments.CompanyFragment}
`

export const uploadCompanyLogo = gql`
  mutation uploadCompanyLogo ($input: UploadCompanyLogoInput) {
    uploadCompanyLogo(input: $input) {
     ...CompanyFragment
    }
  }
  ${Fragments.CompanyFragment}
`

export const uploadOrgLogo = gql`
  mutation uploadOrgLogo ($input: UploadOrgLogoInput) {
    uploadOrgLogo(input: $input) {
     ...OrgFragment
    }
  }
  ${Fragments.OrgFragment}
`

export const inviteTeamMember = gql`
  mutation inviteTeamMember ($input: InviteTeamMemberInput!) {
    inviteTeamMember(input: $input) {
      ...CompanyFragment
    }
  }
  ${Fragments.CompanyFragment}
`

export const acceptInvite = gql`
  mutation acceptInvite ($input: AcceptInviteInput!) {
    acceptInvite(input: $input)
  }
`

export const removeTeamMember = gql`
  mutation removeTeamMember ($input: RemoveTeamMemberInput!) {
    removeTeamMember(input: $input) {
      ...CompanyFragment
    }
  }
  ${Fragments.CompanyFragment}
`

export const removeSelfAsTeamMember = gql`
  mutation removeSelfAsTeamMember ($input: RemoveSelfAsTeamMemberInput!) {
    removeSelfAsTeamMember(input: $input) {
      ...MeFragment
    }
  }
  ${Fragments.MeFragment}
`