import React from 'react'

const LogoRocket = (props) => {
  const { fill } = props;

  return (
    <svg viewBox="0 0 71.4 84.85" fill={ fill }>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            className="cls-1"
            d="M44.8 14.5a34.73 34.73 0 0 0-10.7-1.7A34.08 34.08 0 0 0 9.7 70.7c.6-1.3 1.2-2.6 1.7-4a30.05 30.05 0 0 1 22.7-49.8 31.82 31.82 0 0 1 7.7 1M18.5 77.2a34.06 34.06 0 0 0 49.7-30.3 34.76 34.76 0 0 0-4.5-17L61.3 34a29.4 29.4 0 0 1 2.9 12.9A30.13 30.13 0 0 1 34.1 77a29.81 29.81 0 0 1-12.3-2.6"
          />
          <g id="rocket">
            <path
              className="cls-1"
              d="M32.1 33.2c-2-.4-5.5-1.1-7.6-.7C18 32.4 9.1 43.6 9.1 43.6l13.1 4.3c1.9-3 5.7-8.8 9.9-14.7zm5.2 26L44.9 70s8.6-7.1 8.6-14.5c.1-2.1-.8-5.7-3-9.9-5 5.1-10.7 11.2-13.2 13.6z"
            />
            <path
              className="cls-1"
              d="M24.7 55.9l-2.8-2S44.3 14.3 65 5.4c0 0-23.9 23.1-40.3 50.5zm-7.7-.6l13.7 10.6C70.9 32.6 71.4 0 71.4 0 41.4 8.7 17 55.3 17 55.3z"
            />
          </g>
          <polygon
            className="cls-1"
            points="27.5 68.5 15.4 59.4 16.7 56.2 30.1 66.5 27.5 68.5"
          />
          <path
            className="cls-1"
            d="M26.9 69.1c-5 1.6-19 16.7-20.5 15.7s8.2-19.5 8.7-24.5"
          />
        </g>
      </g>
    </svg>
  )
}

LogoRocket.defaultProps = {
  fill: '#2D6CFF'
}

export default LogoRocket;