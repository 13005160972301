import {
    SET_COMPANY,
    CLEAR_ORG,
    SET_ORG
  } from '../actions/types'
  
  const intialState = {}
  
  export default function (state = intialState, action) {
    switch (action.type) {
      case SET_ORG:
        return {
          ...state,
          ...action.payload
        }
      case SET_COMPANY:
      case CLEAR_ORG:
        return intialState
      default:
        return state
    }
  }