import {
  SET_CANVAS,
  UPDATE_BLOCK,
} from '../actions/types'

const intialState = {}

export default function (state = intialState, action) {
  switch (action.type) {
    case SET_CANVAS:
      return {
        ...action.payload
      }
    case UPDATE_BLOCK:
      return {
        ...state,
        blocks: updateBlock(state.blocks, action),
      }
    default:
      return state
  }
}

function updateBlock(state, action) {
  const {
    payload,
  } = action

  if (!payload) return state

  return {
    ...state,
    [payload.key]: {
      ...state[payload.key],
      ...payload.block,
    }
  }
}
