import {
  SET_ADMIN_DATA,
  ADD_PROMOTION,
  DELETE_PROMOTION,
  ADD_ORG_ADMIN_ORGS_LIST
} from '../actions/types'

const addOrg = (state, action) => {
  return state.concat(action.payload)
}

const addPromotion = (state, action) => {
  return state.concat(action.payload)
}

const deletePromotion = (state, { payload }) => {
  return state.filter(item => item._id !== payload)
}

const initialState = {
  promotions: {},
  userStats: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ADMIN_DATA: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case ADD_ORG_ADMIN_ORGS_LIST: {
      return {
        ...state,
        orgs: addOrg(state.orgs, action),
      }
    }
    case ADD_PROMOTION: {
      return {
        ...state,
        promotions: addPromotion(state.promotions, action),
      }
    }
    case DELETE_PROMOTION: {
      return {
        ...state,
        promotions: deletePromotion(state.promotions, action),
      }
    }
    default:
      return state
  }
}
