import { push } from 'react-router-redux'
import axios from 'axios'
import moment from 'moment'
import ReactGA from 'react-ga'
import {
  SET_USER,
  DELETE_USER,
  AUTH_USER,
  UNAUTH_USER,
  VALIDATE_VERIFY_URL,
  VERIFY_USER,
  INVALIDATE_VERIFY_URL,
  VALIDATE_RESET_URL,
  INVALIDATE_RESET_URL,
  RESET_PASSWORD_SENT,
  CLEAR_RESET_PASSWORD_SENT,
  UPDATE_USER_IMAGE,
  FREE_TRIAL_EXPIRED,
  CLEAR_FREE_TRIAL_EXPIRED,
  MARK_NOTIFICATION_READ,
  TURN_OFF_SOUND,
  TURN_ON_SOUND
} from './types'
import Query from '../graphql'
import { cleanCompanyData, setCompanyData } from './company.actions'
import { showFlashMessage } from './flash.actions'
import {
  handleErrors,
  handleStandardError,
  getCreatedDateFromMongoId,
} from '../utils/helpers'
import strings from '../assets/strings'
import { signoutUser } from './auth.actions'
import { ACCOUNT_TYPES, ENTITY_TYPES } from '../types'
import { setOrg, setOrgData } from './org.actions'

export function getUserAndSignin() {
  return async function (dispatch, getState, { graphqlClient }) {
    try {
      const {
        data: { me },
      } = await graphqlClient.query({
        query: Query.meQuery,
        fetchPolicy: 'network-only',
      })

      dispatch({
        type: SET_USER,
        payload: me,
      })

      if (me.orgs.length) {
        const {
          data: { getOrg },
        } = await graphqlClient.query({
          query: Query.getOrgQuery,
          fetchPolicy: 'network-only',
          variables: {
            input: {
              orgId: me.orgs[Object.keys(me.orgs)[0]]._id,
            }
          },
        })

        dispatch(setOrgData(getOrg))

        // if (me.accountType === ORG_ACCOUNT_TYPES.ORG_ADVISOR_ACCOUNT) {
        //   dispatch(getAdvisorCompaniesList(me._id, me.orgs[Object.keys(me.orgs)[0]]._id))
        // }
      } else if (Array.isArray(me.companies) && me.companies.length) {
        const currentCompanyId = getState().company.currentCompany.id
        if (currentCompanyId) {
          dispatch(
            setCompanyData(getState().user.account.companies[currentCompanyId]),
          )
        } else {
          dispatch(setCompanyData(me.companies[me.companies.length - 1]))
        }
      } else {
        dispatch(cleanCompanyData())
      }

      dispatch({
        type: AUTH_USER,
      })
    } catch (err) {
      console.log(err)
    }
  }
}

export function updateUser() {
  return async (dispatch, getState, { graphqlClient }) => {
    try {
      const selectedEntityType = getState().layout.currentEntityType
      const org = getState().org

      const {
        data: { me },
      } = await graphqlClient.query({
        query: Query.meQuery,
        fetchPolicy: 'network-only',
      })

      dispatch({
        type: SET_USER,
        payload: me,
      })

      if (selectedEntityType === ENTITY_TYPES.ORG && org._id) {
        dispatch(setOrg(org._id))
      }
      else if (Array.isArray(me.companies) && me.companies.length) {
        const currentCompanyId = getState().company.currentCompany.id
        if (currentCompanyId) {
          dispatch(
            setCompanyData(getState().user.account.companies[currentCompanyId])
          )
        } else {
          dispatch(setCompanyData(me.companies[me.companies.length - 1]))
        }
      } else if (Array.isArray(me.orgs) && me.orgs.length) {
        dispatch(setOrg(me.orgs[0]._id))
      }
      else {
        dispatch(cleanCompanyData())
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export const markNotificationRead = id => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { markNotificationRead },
      } = await graphqlClient.mutate({
        mutation: Query.markNotificationRead,
        variables: {
          input: {
            id
          },
        },
      })

      const { ok } = markNotificationRead

      ReactGA.event({
        category: 'Notification',
        action: 'Read'
      })

      if (ok) {
        // set user
        dispatch({
          type: MARK_NOTIFICATION_READ,
          payload: id,
        })

        resolve()
      } else {
        reject()
      }
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export function cancelSubscription(id) {
  return async function (dispatch, getState, { graphqlClient, intl }) {
    try {
      const {
        data: { cancelSubscription },
      } = await graphqlClient.mutate({
        mutation: Query.cancelSubscription,
        variables: {
          input: {
            id,
          },
        },
      })

      dispatch({
        type: SET_USER,
        payload: cancelSubscription,
      })

      const state = getState()

      dispatch(
        showFlashMessage(
          intl(state).formatMessage(strings.subscriptionCanceledSuccessfully),
          'success',
        ),
      )

      ReactGA.event({
        category: 'Account',
        action: 'Cancel',
      })
    } catch (err) {
      console.log(err)
    }
  }
}

export function deleteAccount(id) {
  return async function (dispatch, getState, { graphqlClient }) {
    try {
      const {
        data: { deleteAccount },
      } = await graphqlClient.mutate({
        mutation: Query.deleteAccount,
        variables: {
          input: {
            id,
          },
        },
      })

      dispatch({
        type: DELETE_USER,
        payload: deleteAccount,
      })

      dispatch(signoutUser())

      ReactGA.event({
        category: 'Account',
        action: 'Delete',
      })

      dispatch(push('/'))
    } catch (err) {
      console.log(err)
    }
  }
}

export const checkUserSubscriptionStatus = () => (
  dispatch,
  getState,
  { graphqlClient, intl },
) => {
  const user = getState().user.account
  const trialExpired = getState().auth.trialExpired
  const routerState = getState().router
  const companyOwner =
    (getState().company &&
      getState().company.currentCompany &&
      getState().company.currentCompany.settings &&
      getState().company.currentCompany.settings.owner) || {}

  if (!user) return true

  // Dismiss this if user is not on a free account
  if (user.accountType !== ACCOUNT_TYPES.FREE_ACCOUNT) {
    if (trialExpired) dispatch(clearFreeTrialExpired())
    return true
  }

  const created = getCreatedDateFromMongoId(user._id)

  // Let users see other companies they don't own even if on free trial
  if (user.id !== companyOwner.id) dispatch(clearFreeTrialExpired())

  // Check created date against free trial length if there, if not, use configured default value
  const trialLength = user.settings && user.settings.hasOwnProperty('freeTrialLength')
    ? user.settings.freeTrialLength
    : process.env.REACT_APP_FREE_TRIAL_LENGTH
  const routeIsNotBlockedForFreeTrial = routerState && routerState.location && ['/dashboard/me'].includes(routerState.location.pathname)

  if (
    (((Math.abs(created.diff(moment(), 'days')) > trialLength &&
      user.id === companyOwner.id) || (created.isAfter(moment('2021-05-02')) && trialLength === 0 &&
        user.id === companyOwner.id)) && !routeIsNotBlockedForFreeTrial)
  ) {
    dispatch(freeTrailExpired())
    return false
  } else {
    dispatch(clearFreeTrialExpired())
    return true
  }
}

export function clearFreeTrialExpired() {
  return function (dispatch) {
    dispatch({
      type: CLEAR_FREE_TRIAL_EXPIRED,
    })
  }
}

export function freeTrailExpired() {
  return function (dispatch) {
    ReactGA.event({
      category: 'Account',
      action: 'Free Trial Expired',
    })
    dispatch({
      type: FREE_TRIAL_EXPIRED,
    })
  }
}

export const submitSignupWizard = (values, routeParams) => (
  dispatch,
  getState,
  { graphqlClient, intl },
) => {
  return new Promise(async function (resolve, reject) {
    const state = getState()

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/signup`,
        values,
      )

      const { headers } = response

      const token = headers['x-token']
      const refreshToken = headers['x-refresh-token']

      if (token) {
        localStorage.setItem('token', token)
      }

      if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken)
      }

      const {
        data: { me },
      } = await graphqlClient.query({
        query: Query.meQuery,
      })

      // set user
      dispatch({
        type: SET_USER,
        payload: me,
      })

      dispatch({ type: AUTH_USER })

      if (values.inviteId && me.companies.length > 0) {
        dispatch(setCompanyData(me.companies[0]))

        dispatch(push('/dashboard'))

        dispatch(
          showFlashMessage(
            intl(state).formatMessage(strings.youAreAddedToCompany),
            'success',
          ),
        )

        ReactGA.event({
          category: 'Sign Up',
          action: 'Company Invite',
          label: 'Accepted',
        })

        resolve()
      } else {
        let subscriptionRoute = '/subscription'

        if (routeParams && routeParams.selection) {
          subscriptionRoute = `/subscription?selection=${routeParams.selection}`
        }
        dispatch(push(subscriptionRoute))

        dispatch(
          showFlashMessage(
            intl(state).formatMessage(strings.accountCreatedSuccessfully),
            'success',
          ),
        )

        ReactGA.event({
          category: 'Sign Up',
          action: 'User Created',
        })

        resolve()
      }
    } catch (error) {
      dispatch(
        showFlashMessage(
          intl(state).formatMessage(strings.accountCreatedFailure),
          'error',
        ),
      )
      reject(handleStandardError(error))
    }
  })
}

export const submitAccountSettingsForm = values => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { updateAccountSettings },
      } = await graphqlClient.mutate({
        mutation: Query.accountSettingsMutation,
        variables: {
          input: values,
        },
      })

      const { ok, me, error } = updateAccountSettings

      ReactGA.event({
        category: 'Account',
        action: 'Update',
        label: 'Settings',
      })

      if (ok) {
        // set user
        dispatch({
          type: SET_USER,
          payload: me,
        })

        resolve()
      } else {
        reject(handleErrors(error))
      }
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const updateToursSeen = key => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { updateToursSeen },
      } = await graphqlClient.mutate({
        mutation: Query.updateToursSeenMutation,
        variables: {
          input: {
            key
          },
        },
      })

      const { ok, me, error } = updateToursSeen

      ReactGA.event({
        category: 'Account',
        action: 'Update',
        label: 'Tours seen',
      })

      if (ok) {
        // set user
        dispatch({
          type: SET_USER,
          payload: me,
        })

        resolve()
      } else {
        reject(handleErrors(error))
      }
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const updateSoundEffects = soundEffectsOn => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      if (soundEffectsOn) {
        dispatch({
          type: TURN_ON_SOUND,
        })
      } else {
        dispatch({
          type: TURN_OFF_SOUND,
        })
      }

      const {
        data: { updateSoundEffectsSetting },
      } = await graphqlClient.mutate({
        mutation: Query.updateSoundEffectsMutation,
        variables: {
          input: {
            soundEffectsOn
          },
        },
      })

      const { ok, me, error } = updateSoundEffectsSetting

      ReactGA.event({
        category: 'Account',
        action: 'Update',
        label: 'Sound Effects Setting',
      })

      if (ok) {
        // set user
        dispatch({
          type: SET_USER,
          payload: me,
        })

        resolve()
      } else {
        reject(handleErrors(error))
      }
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const submitContactUsForm = values => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { submitContactUsForm },
      } = await graphqlClient.mutate({
        mutation: Query.submitContactUsForm,
        variables: {
          input: values,
        },
      })

      const { ok, error } = submitContactUsForm

      ReactGA.event({
        category: 'Contact Us',
        action: 'Submit',
      })

      if (ok) {
        resolve(submitContactUsForm)
      } else {
        reject(handleErrors(error))
      }
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const submitChangePasswordForm = values => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { changePassword },
      } = await graphqlClient.mutate({
        mutation: Query.changePasswordMutation,
        variables: {
          input: values,
        },
      })

      ReactGA.event({
        category: 'Account',
        action: 'Update',
        label: 'Password',
      })

      resolve(changePassword)
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const submitSubscriptionWizard = values => (
  dispatch,
  getState,
  { graphqlClient, intl },
) => {
  return new Promise(async function (resolve, reject) {
    const state = getState()

    try {
      const {
        data: { setSubscription },
      } = await graphqlClient.mutate({
        mutation: Query.setSubscriptionMutation,
        variables: {
          input: {
            accountType: values.accountType,
            stripeToken: values.stripeToken,
            promoCode: values.promoCode,
          },
        },
      })

      // set user
      dispatch({
        type: SET_USER,
        payload: setSubscription,
      })

      if (
        Array.isArray(setSubscription.companies) &&
        setSubscription.companies.length
      ) {
        dispatch(push('/dashboard'))
      } else {
        dispatch(push('/create-company'))
      }

      dispatch(
        showFlashMessage(
          intl(state).formatMessage(strings.subscriptionSetupSuccess),
          'success',
        ),
      )

      ReactGA.event({
        category: 'Sign Up',
        action: 'Subscription Set',
      })

      resolve(setSubscription)
    } catch (errors) {
      let errorMessage = intl(state).formatMessage(
        strings.subscriptionSetupFailure,
      )

      if (
        errors['graphQLErrors'] &&
        errors['graphQLErrors'][0] &&
        errors['graphQLErrors'][0].extensions &&
        errors['graphQLErrors'][0].extensions.exception &&
        errors['graphQLErrors'][0].extensions.exception.data &&
        errors['graphQLErrors'][0].extensions.exception.data.stripeErrorMessage
      ) {
        errorMessage =
          errors['graphQLErrors'][0].extensions.exception.data
            .stripeErrorMessage
      }

      dispatch(showFlashMessage(errorMessage, 'error'))

      reject(handleErrors(errorMessage))
    }
  })
}

export const updateUserSubscription = accountType => (
  dispatch,
  getState,
  { graphqlClient, intl },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { updateSubscription },
      } = await graphqlClient.mutate({
        mutation: Query.updateSubscriptionMutation,
        variables: {
          input: {
            accountType,
          },
        },
      })

      // set user
      dispatch({
        type: SET_USER,
        payload: updateSubscription,
      })

      const state = getState()
      dispatch(
        showFlashMessage(
          intl(state).formatMessage(strings.subscriptionUpdateSuccess),
        ),
      )

      ReactGA.event({
        category: 'Account',
        action: 'Update',
        label: 'Subscription',
      })

      resolve(updateSubscription)
    } catch (errors) {
      const state = getState()
      dispatch(
        showFlashMessage(
          intl(state).formatMessage(strings.subscriptionUpdateFailure),
          'error',
        ),
      )
      reject(handleErrors(errors))
    }
  })
}

export const resendVerificationCode = () => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { resendVerificationCode },
      } = await graphqlClient.query({
        query: Query.resendVerificationCode,
      })

      ReactGA.event({
        category: 'Account',
        action: 'Resend Verification Code',
      })

      resolve(resendVerificationCode)
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const checkPromoCode = (code) => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { checkPromoCode },
      } = await graphqlClient.query({
        query: Query.checkPromoCode,
        variables: {
          input: {
            code,
          },
        },
      })

      ReactGA.event({
        category: 'Signup',
        action: 'Check Promo Code',
      })

      resolve(checkPromoCode)
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const checkVerifyLink = id => dispatch => {
  return new Promise(async function (resolve, reject) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/checkVerifyLink`,
        { id },
      )

      if (response) {
        dispatch({ type: VALIDATE_VERIFY_URL })
      } else {
        dispatch({ type: INVALIDATE_VERIFY_URL })
      }

      resolve(response)
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const checkVerificationCode = values => dispatch => {
  return new Promise(async function (resolve, reject) {
    const { verificationCode, userid } = values

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/checkVerificationCode`,
        {
          id: userid,
          verificationCode,
        },
      )

      if (response) {
        dispatch({
          type: VERIFY_USER,
        })
        dispatch(push('/dashboard'))

        ReactGA.event({
          category: 'Account',
          action: 'Verified',
        })
      } else {
        reject({ _error: 'Whoops, that verification code is incorrect.' })
      }

      resolve(response)
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const checkResetPasswordToken = token => dispatch => {
  return new Promise(async function (resolve, reject) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/checkResetPasswordToken`,
        { token },
      )

      if (response) {
        dispatch({ type: VALIDATE_RESET_URL })
      } else {
        dispatch({ type: INVALIDATE_RESET_URL })
      }

      resolve(response)
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const resetPassword = values => (
  dispatch,
  getState,
  { graphqlClient, intl },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/resetPassword`,
        {
          password: values.newPassword,
          token: values.token,
        },
      )

      if (response) {
        dispatch({
          type: UNAUTH_USER,
        })
        dispatch(push('/'))
        const state = getState()
        dispatch(
          showFlashMessage(
            intl(state).formatMessage(strings.resetPasswordSuccess),
          ),
        )

        ReactGA.event({
          category: 'Account',
          action: 'Reset Password',
        })
      } else {
        reject({
          _error: 'Sorry, something went wrong and the password was not reset',
        })
      }

      resolve(response)
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const forgotPassword = ({ email }) => dispatch => {
  return new Promise(async function (resolve, reject) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/forgotPassword`,
        { email },
      )

      dispatch({
        type: RESET_PASSWORD_SENT,
      })

      ReactGA.event({
        category: 'Account',
        action: 'Forgot Password',
      })

      resolve(response)
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export function clearResetPasswordSent() {
  return function (dispatch) {
    dispatch({
      type: CLEAR_RESET_PASSWORD_SENT,
    })
  }
}

export const uploadUserImage = (userId, file) => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const formData = new FormData()
      formData.append('file', file)
      formData.append(
        'upload_preset',
        process.env.REACT_APP_CLOUDINARY_USER_UPLOAD_PRESET,
      )

      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_NAME}/image/upload/`,
        formData,
      )

      const {
        data: { uploadUserImage },
      } = await graphqlClient.mutate({
        mutation: Query.uploadUserImage,
        variables: {
          input: {
            userId,
            accountImageId: response.data.public_id,
          },
        },
      })

      ReactGA.event({
        category: 'Account',
        action: 'Update',
        label: 'Profile Image',
      })

      dispatch({
        type: UPDATE_USER_IMAGE,
        payload: response.data.public_id,
      })

      resolve(uploadUserImage)
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}
