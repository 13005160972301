import { push } from 'react-router-redux'
import axios from 'axios'
import ReactGA from 'react-ga'
import {
  SET_ORG,
  SET_USER,
  AUTH_USER,
  ADD_ORG_ADMIN_ORGS_LIST
} from './types'
import Query from '../graphql'
import {
  handleErrors,
  handleStandardError,
} from '../utils/helpers'
import { showFlashMessage } from './flash.actions'
import strings from '../assets/strings'
import { setCompany, setCompanyLoading } from './company.actions'
import { ACCOUNT_TYPES } from '../types'
import { company, user } from '../selectors'

export const setOrg = (id, goToDashboard) => {
  return async function (dispatch, getState, { graphqlClient }) {
    try {
      dispatch(setCompanyLoading(true))
      const {
        data: { getOrg },
      } = await graphqlClient.query({
        query: Query.getOrgQuery,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            orgId: id,
          },
        }
      })

      if (goToDashboard) dispatch(push('/dashboard'))
      dispatch(setOrgData(getOrg))
      dispatch(setCompanyLoading(false))

      ReactGA.event({
        category: 'Org',
        action: 'Load',
      })
    } catch (error) {
      throw error
    }
  }
}

export const createOrg = (values) => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    const input = {
      name: values.name,
      orgKey: values.orgKey,
      adminEmails: values.admins && values.admins.map(a => a.email)
    }

    try {
      const {
        data: { createOrg },
      } = await graphqlClient.mutate({
        mutation: Query.createOrg,
        variables: {
          input,
        },
      })

      dispatch({
        type: ADD_ORG_ADMIN_ORGS_LIST,
        payload: createOrg,
      })

      resolve()
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const updateOrg = (id) => {
  return async function (dispatch, getState, { graphqlClient }) {
    try {
      const {
        data: { getOrg },
      } = await graphqlClient.query({
        query: Query.getOrgQuery,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            orgId: id,
          },
        }
      })

      dispatch(setOrgData(getOrg))

      ReactGA.event({
        category: 'Org',
        action: 'Load',
      })
    } catch (error) {
      throw error
    }
  }
}

export const getFullCompanyData = id => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const orgId = getState().org._id

      const {
        data: { getFullCompanyData },
      } = await graphqlClient.query({
        query: Query.getFullCompanyData,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            id,
            orgId,
          },
        },
      })

      ReactGA.event({
        category: 'Org',
        action: 'User Plan',
        label: 'View',
      })

      resolve(getFullCompanyData)
    } catch (err) {
      reject(handleErrors(err))
    }
  })
}

export const inviteOrgUser = values => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    const { email, orgId, companyId } = values
    let detectedOrgId = orgId ? orgId : Object.keys(user(getState()).orgs)[0]
    try {
      const {
        data: { inviteOrgUser },
      } = await graphqlClient.mutate({
        mutation: Query.inviteOrgUser,
        variables: {
          input: {
            email,
            orgId: detectedOrgId,
            companyId
          },
        },
      })

      // if companyId, this form is from company settings team member invite so update company instead
      if (companyId) {
        dispatch(setCompany(company(getState())._id, false))
      } else {
        dispatch(setOrgData(inviteOrgUser))
      }

      ReactGA.event({
        category: 'Org',
        action: 'Invite New User',
        label: 'Invite',
      })

      resolve(inviteOrgUser)
    } catch (err) {
      reject(handleErrors(err))
    }
  })
}

export const resendOrgInvite = ({ email, orgId }) => (
  dispatch,
  getState,
  { graphqlClient, intl },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const state = getState()
      const {
        data: { resendOrgInvite },
      } = await graphqlClient.mutate({
        mutation: Query.resendOrgInvite,
        variables: {
          input: {
            email,
            orgId,
          },
        },
      })

      dispatch(setOrgData(resendOrgInvite))

      ReactGA.event({
        category: 'Org',
        action: 'Resend Org Invite',
        label: 'Invite',
      })

      dispatch(
        showFlashMessage(
          intl(state).formatMessage(strings.inviteSuccess),
          'success',
        ),
      )

      resolve(resendOrgInvite)
    } catch (err) {
      reject(handleErrors(err))
    }
  })
}

export const getAdvisorCompaniesList = (id, orgId) => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { getAdvisorCompaniesList },
      } = await graphqlClient.query({
        query: Query.getAdvisorCompaniesList,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            id,
            orgId,
          },
        },
      })

      ReactGA.event({
        category: 'Org',
        action: 'Get Advisor Companies List'
      })

      // dispatch({
      //   type: SET_ADVISOR_COMPANIES,
      //   payload: getAdvisorCompaniesList,
      // })

      resolve(getAdvisorCompaniesList)
    } catch (err) {
      reject(handleErrors(err))
    }
  })
}

export const inviteOrgAdvisor = values => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    const { email, orgId } = values

    try {
      const {
        data: { inviteOrgAdvisor },
      } = await graphqlClient.mutate({
        mutation: Query.inviteOrgUser,
        variables: {
          input: {
            email,
            orgId,
            accountType: ACCOUNT_TYPES.ORG_ADVISOR_ACCOUNT
          },
        },
      })

      dispatch(setOrgData(inviteOrgAdvisor))

      ReactGA.event({
        category: 'Org',
        action: 'Invite New Advisor',
        label: 'Invite',
      })

      resolve(inviteOrgAdvisor)
    } catch (err) {
      reject(handleErrors(err))
    }
  })
}

export const inviteOrgAdmin = values => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    const { email, orgId } = values

    try {
      const {
        data: { inviteOrgAdmin },
      } = await graphqlClient.mutate({
        mutation: Query.inviteOrgUser,
        variables: {
          input: {
            email,
            orgId,
            accountType: ACCOUNT_TYPES.ORG_ADMIN_ACCOUNT
          },
        },
      })

      dispatch(setOrgData(inviteOrgAdmin))

      ReactGA.event({
        category: 'Org',
        action: 'Invite New Admin',
        label: 'Invite',
      })

      resolve(inviteOrgAdvisor)
    } catch (err) {
      reject(handleErrors(err))
    }
  })
}

export const assignUsersToAdvisor = values => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    const { id, orgUsers } = values
    const orgId = getState().org._id

    try {
      const {
        data: { assignUsersToAdvisor },
      } = await graphqlClient.mutate({
        mutation: Query.assignUsersToAdvisor,
        variables: {
          input: {
            id,
            orgUsers,
            orgId,
          },
        },
      })

      dispatch(setOrgData(assignUsersToAdvisor))

      ReactGA.event({
        category: 'Org',
        action: 'Update Advisor',
        label: 'Assign Users',
      })

      resolve(assignUsersToAdvisor)
    } catch (err) {
      reject(handleErrors(err))
    }
  })
}

export const checkOrgInvite = inviteId => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { checkOrgInvite },
      } = await graphqlClient.mutate({
        mutation: Query.checkOrgInvite,
        variables: {
          input: {
            inviteId,
          },
        },
      })

      ReactGA.event({
        category: 'Org',
        action: 'Check Org Invite',
      })

      resolve(checkOrgInvite)
    } catch (err) {
      reject(handleErrors(err))
    }
  })
}

export const getOrgUserDetails = (id) => (
  dispatch,
  getState,
  { graphqlClient },
) => {

  return new Promise(async function (resolve, reject) {
    try {
      const orgId = getState().org._id
      const {
        data: { getOrgUserDetails },
      } = await graphqlClient.query({
        query: Query.getOrgUserDetails,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            id,
            orgId,
          },
        },
      })

      ReactGA.event({
        category: 'Org',
        action: 'Get User Details',
      })

      resolve(getOrgUserDetails)
    } catch (err) {
      reject(handleErrors(err))
    }
  })
}

export const getUserDetailsFromOrgInvite = (id, invite = false) => (
  dispatch,
  getState,
  { graphqlClient },
) => {

  return new Promise(async function (resolve, reject) {
    try {
      const orgId = getState().org._id
      const {
        data: { getUserDetailsFromOrgInvite },
      } = await graphqlClient.query({
        query: Query.getUserDetailsFromOrgInvite,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            id,
            orgId,
          },
        },
      })

      ReactGA.event({
        category: 'Org',
        action: 'Get User Details for Org Invite',
      })

      resolve(getUserDetailsFromOrgInvite)
    } catch (err) {
      reject(handleErrors(err))
    }
  })
}

export const getOrgInviteForUser = () => (
  dispatch,
  getState,
  { graphqlClient },
) => {

  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { getOrgInviteForUser },
      } = await graphqlClient.query({
        query: Query.getOrgInviteForUser,
        fetchPolicy: 'network-only',
      })

      ReactGA.event({
        category: 'Org',
        action: 'Get Org Invite for user',
      })

      resolve(getOrgInviteForUser)
    } catch (err) {
      reject(handleErrors(err))
    }
  })
}

export const submitAcceptOrgInviteSignup = (values) => (
  dispatch,
  getState,
  { graphqlClient, intl },
) => {
  return new Promise(async function (resolve, reject) {
    const state = getState()

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/orgSignup`,
        values,
      )

      const { headers } = response

      const token = headers['x-token']
      const refreshToken = headers['x-refresh-token']

      if (token) {
        localStorage.setItem('token', token)
      }

      if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken)
      }

      const {
        data: { me },
      } = await graphqlClient.query({
        query: Query.meQuery,
        fetchPolicy: 'network-only'
      })

      // set user
      dispatch({
        type: SET_USER,
        payload: me,
      })

      dispatch({ type: AUTH_USER })

      if (me.orgs && me.orgs.length) {
        dispatch(setOrg(me.orgs[0]._id))
      }

      if (me.accountType === ACCOUNT_TYPES.ORG_ACCOUNT && !values.inviteCompanyId) {
        dispatch(push('/create-company'))
      } else {
        dispatch(push('/dashboard'))
      }

      dispatch(
        showFlashMessage(
          intl(state).formatMessage(strings.accountCreatedSuccessfully),
          'success',
        ),
      )

      ReactGA.event({
        category: 'Org Sign Up',
        action: 'User Accepted Invite',
      })

      resolve()
    } catch (error) {
      dispatch(
        showFlashMessage(
          intl(state).formatMessage(strings.accountCreatedFailure),
          'error',
        ),
      )
      reject(handleStandardError(error))
    }
  })
}

export const acceptOrgAccountTransition = values => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { acceptOrgAccountTransition },
      } = await graphqlClient.mutate({
        mutation: Query.acceptOrgAccountTransition,
        variables: {
          input: {
            userId: values.userId,
            inviteId: values.inviteId,
            acceptDataSharing: values.acceptDataSharing
          },
        },
      })

      if (!acceptOrgAccountTransition) {
        reject()
      }

      const {
        data: { me },
      } = await graphqlClient.query({
        query: Query.meQuery,
        fetchPolicy: 'network-only',
      })

      dispatch({
        type: SET_USER,
        payload: me,
      })

      const {
        data: { getOrg },
      } = await graphqlClient.query({
        query: Query.getOrgQuery,
        fetchPolicy: 'network-only',
        variables: {
          input: {
            orgId: values.orgId,
          }
        },
      })

      dispatch(setOrgData(getOrg))

      ReactGA.event({
        category: 'Org',
        action: 'Join Org Invite Account Transition',
        label: 'Accepted',
      })

      resolve(acceptOrgAccountTransition)
    } catch (err) {
      reject(handleErrors(err))
    }
  })
}

export const deleteOrgUser = ({ orgId, userId }) => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { deleteOrgUser },
      } = await graphqlClient.mutate({
        mutation: Query.deleteOrgUser,
        variables: {
          input: {
            orgId: orgId,
            userId: userId,
          },
        },
      })

      ReactGA.event({
        category: 'Org',
        action: 'Delete Org User',
      })

      dispatch(push('/dashboard'))
      resolve(deleteOrgUser)
    } catch (err) {
      reject(handleErrors(err))
    }
  })
}

export const uploadOrgLogo = (orgId, file) => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const formData = new FormData()
      formData.append('file', file)
      formData.append(
        'upload_preset',
        process.env.REACT_APP_CLOUDINARY_COMPANY_UPLOAD_PRESET,
      )

      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_NAME}/image/upload/`,
        formData,
      )

      const {
        data: { uploadOrgLogo },
      } = await graphqlClient.mutate({
        mutation: Query.uploadOrgLogo,
        variables: {
          input: {
            orgId,
            logoId: response.data.public_id,
          },
        },
      })

      dispatch(setOrgData(uploadOrgLogo))

      ReactGA.event({
        category: 'Company',
        action: 'Update',
        label: 'Logo',
      })

      resolve(uploadOrgLogo)
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const setOrgData = org => {
  return function (dispatch) {
    dispatch({
      type: SET_ORG,
      payload: org,
    })
  }
}
