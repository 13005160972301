import { defineMessages } from 'react-intl'

const financials = defineMessages({
  financials: {
    id: 'f.financials',
    defaultMessage: 'Financials',
  },
  statements: {
    id: 'f.statements',
    defaultMessage: 'Statements',
  },
  planYourFinancialForecast: {
    id: 'f.planYourFinancialForecast',
    defaultMessage: 'Plan your financial forecast',
  },
  profitLossGetStarted: {
    id: 'f.profitLossGetStarted',
    defaultMessage: 'Start with entering your revenues and costs',
  },
  balanceSheetGetStarted: {
    id: 'f.balanceSheetGetStarted',
    defaultMessage: 'Get started building out your balance sheet',
  },
  financialStatements: {
    id: 'f.financialStatements',
    defaultMessage: 'Financial Statements',
  },
  profitAndLost: {
    id: 'f.profitAndLost',
    defaultMessage: 'Profit & Loss',
  },
  projectedProfitAndLost: {
    id: 'f.projectedProfitAndLost',
    defaultMessage: 'Projected Profit & Loss',
  },
  balanceSheet: {
    id: 'f.balanceSheet',
    defaultMessage: 'Balance Sheet',
  },
  projectedBalanceSheet: {
    id: 'f.projectedBalanceSheet',
    defaultMessage: 'Projected Balance Sheet',
  },
  cashFlow: {
    id: 'f.cashFlow',
    defaultMessage: 'Cash Flow',
  },
  projectedCashFlow: {
    id: 'f.projectedCashFlow',
    defaultMessage: 'Projected Cash Flow',
  },
  breakEvenAnalysis: {
    id: 'f.breakEvenAnalysis',
    defaultMessage: 'Break-Even Analysis',
  },
  breakEvenAnalysisDescription: {
    id: 'f.breakEvenAnalysisDescription',
    defaultMessage: `Break-even analysis entails calculating and examining the margin of safety for an entity based on the revenues collected and associated costs. In other words, the analysis shows how many sales it takes to pay for the cost of doing business. Analyzing different price levels relating to various levels of demand, the break-even analysis determines what level of sales are necessary to cover the company's total fixed costs. A demand-side analysis would give a seller significant insight into selling capabilities.`,
  },
  projectedBreakEvenAnalysis: {
    id: 'f.projectedbreakEvenAnalysis',
    defaultMessage: 'Projected Break-Even Analysis',
  },
  profitAndLossHeadline: {
    id: 'f.profitAndLossHeadline',
    defaultMessage: 'Plan your financial forecast',
  },
  profitAndLossSubHeading: {
    id: 'f.profitAndLossSubHeading',
    defaultMessage:
      'Start planning and exploring your financial forecast with our easy step-by-step guide.',
  },
  revenues: {
    id: 'f.revenues',
    defaultMessage: 'Revenues',
  },
  costOfGoodsSold: {
    id: 'f.costOfGoodsSold',
    defaultMessage: 'Cost of Goods Sold',
  },
  cost: {
    id: 'f.cost',
    defaultMessage: 'Cost',
  },
  expenses: {
    id: 'f.expenses',
    defaultMessage: 'Expenses',
  },
  employees: {
    id: 'f.employees',
    defaultMessage: 'Employees',
  },
  insurance: {
    id: 'f.insurance',
    defaultMessage: 'Insurance',
  },
  dividends: {
    id: 'f.dividends',
    defaultMessage: 'Dividends',
  },
  taxes: {
    id: 'f.taxes',
    defaultMessage: 'Taxes',
  },
  depreciation: {
    id: 'f.depreciation',
    defaultMessage: 'Depreciation',
  },
  startingBalances: {
    id: 'f.startingBalances',
    defaultMessage: 'Starting balances',
  },
  assumptions: {
    id: 'f.assumptions',
    defaultMessage: 'Assumptions',
  },
  assets: {
    id: 'f.assets',
    defaultMessage: 'Assets',
  },
  loans: {
    id: 'f.loans',
    defaultMessage: 'Loans',
  },
  lineOfCredit: {
    id: 'f.lineOfCredit',
    defaultMessage: 'Line of Credit',
  },
  investments: {
    id: 'f.investments',
    defaultMessage: 'Investments',
  },
  investment: {
    id: 'f.investment',
    defaultMessage: 'Investment',
  },
  set: {
    id: 'f.set',
    defaultMessage: 'Set',
  },
  balanceSheetHeadline: {
    id: 'f.balanceSheetHeadline',
    defaultMessage: 'Formulate your balance sheet',
  },
  balanceSheetSubheading: {
    id: 'f.balanceSheetSubheading',
    defaultMessage: `Compile your company's assets, liabilities and shareholders' equity at a specific point in time.`,
  },
  cashFlowHeadline: {
    id: 'f.cashFlowHeadline',
    defaultMessage: 'Tune your cash flow statement',
  },
  cashFlowSubheading: {
    id: 'f.cashFlowSubheading',
    defaultMessage: `View your company's cash position as cash enters and leaves your company.`,
  },
  // ----------------------------------------
  // ----------- REVENUES -------------------
  // ----------------------------------------
  revenueType: {
    id: 'f.revenueType',
    defaultMessage: 'What will your revenue stream look like?',
  },
  revenueTypeDescription: {
    id: 'f.revenueTypeDescription',
    defaultMessage:
      'Select the type that most closely resembles what this revenue stream will look like.',
  },
  unitSales: {
    id: 'f.unitSales',
    defaultMessage: 'Unit Sales',
  },
  billableHours: {
    id: 'f.billableHours',
    defaultMessage: 'Billable Hours',
  },
  recurringCharges: {
    id: 'f.recurringCharges',
    defaultMessage: 'Recurring Charges',
  },
  revenueOnly: {
    id: 'f.revenueOnly',
    defaultMessage: 'Revenue only',
  },
  unitPrice: {
    id: 'f.unitPrice',
    defaultMessage: 'Unit Price',
  },
  price: {
    id: 'f.price',
    defaultMessage: 'Price',
  },
  rate: {
    id: 'f.rate',
    defaultMessage: 'Rate',
  },
  amount: {
    id: 'f.amount',
    defaultMessage: 'Amount',
  },
  total: {
    id: 'f.total',
    defaultMessage: 'Total',
  },
  units: {
    id: 'f.units',
    defaultMessage: 'Units',
  },
  unitCount: {
    id: 'f.unitCount',
    defaultMessage: 'Unit Count',
  },
  shortTerm: {
    id: 'f.shortTerm',
    defaultMessage: 'Short Term',
  },
  longTerm: {
    id: 'f.longTerm',
    defaultMessage: 'Long Term',
  },
  hours: {
    id: 'f.hours',
    defaultMessage: 'Hours',
  },
  nameLabel: {
    id: 'f.nameLabel',
    defaultMessage: 'What would you like to call it?',
  },
  namePlaceholder: {
    id: 'f.namePlaceholder',
    defaultMessage: 'Enter a name or description',
  },
  unitSalesDescription: {
    id: 'f.unitSalesDescription',
    defaultMessage:
      'Ideal for products that are not sold in set quantities or individual units',
  },
  billableHoursDescription: {
    id: 'f.billableHoursDescription',
    defaultMessage: 'Best for services priced on a per-hour basis',
  },
  recurringChargesDescription: {
    id: 'f.recurringChargesDescription',
    defaultMessage:
      'Designated for memberships, rentals, subscriptions, web apps, and other services or products with periodic (think monthly, annually, etc.) charges',
  },
  revenueOnlyDescription: {
    id: 'f.revenueOnlyDescription',
    defaultMessage: `Choose this option if none of the options above fit the bill, or you already have a detailed forecast in Excel or elsewhere. You'll enter the overall revenue values without any other details.`,
  },
  unitSalesLabel: {
    id: 'f.unitSalesLabel',
    defaultMessage: `How many units will you sell?`,
  },
  unitSalesContext: {
    id: 'f.unitSalesContext',
    defaultMessage: `In terms of your business, a ‘unit’ depends on what you sell. If you are offering material goods (chocolate bars, cameras—the list goes on), enter the quantity of the goods you think you will sell. Units can also cover less concrete offerings, like meetings with clients or fix-priced contracts. Take a look at your business, and see what makes sense for you.`,
  },
  unitPriceLabel: {
    id: 'f.unitPriceLabel',
    defaultMessage: `What price will you charge for each unit?`,
  },
  unitPriceContext: {
    id: 'f.unitPriceContext',
    defaultMessage: `Type in the average selling price (excluding sales tax) for each unit of your product or service. Don’t worry, these prices aren’t set in stone, and can vary over time, especially to reflect seasonal changes in demand, scheduled promotions or discounts, and planned increases.`,
  },
  hoursLabel: {
    id: 'f.hoursLabel',
    defaultMessage: `How many hours are you going to bill for?`,
  },
  hoursContext: {
    id: 'f.hoursContext',
    defaultMessage: `Take an educated stab at how many billing hours you will have in each period. This can be a flat, fixed amount or can vary over time. Varying over time helps account for growth, seasonality, added resources, etc.`,
  },
  hourlyRateLabel: {
    id: 'f.hourlyRateLabel',
    defaultMessage: `How much is your hourly rate?`,
  },
  hourlyRateContext: {
    id: 'f.hourlyRateContext',
    defaultMessage: `Enter your hourly rate below, keeping sales tax separate. You can lock in a constant rate or adjust it over time to account for planned rate changes, different combinations of billable work, etc.`,
  },
  customerSignupsLabel: {
    id: 'f.customersLabel',
    defaultMessage: `How many new customers do you expect to sign up?`,
  },
  customerSignupsContext: {
    id: 'f.customersContext',
    defaultMessage: `Estimate the number of new clientele that you will add in each period of your forecast. The existing customer base and cancellations are not important here, just the new signups.`,
  },
  upfrontFeeLabel: {
    id: 'f.upfrontFeeLabel',
    defaultMessage: `Are you charging an up-front fee?`,
  },
  upfrontFeeContext: {
    id: 'f.upfrontFeeContext',
    defaultMessage: `If you have a start-up fee or a one-time charge at signup, include it here. Make a point to distinguish whether you will always charge the same fee, or if that fee amount will change over different signup periods.`,
  },
  frequencyLabel: {
    id: 'f.frequencyLabel',
    defaultMessage: `How frequently is this charge assessed?`,
  },
  recurringLabel: {
    id: 'f.recurringLabel',
    defaultMessage: `How much is the recurring charge?`,
  },
  recurringContext: {
    id: 'f.recurringContext',
    defaultMessage: `Type in your company’s repeating charge (minus sales tax) for each period. If you are planning to switch up your pricing, or offer discounts at certain points of the year, use the varying option to enter individual values.`,
  },
  churnRateLabel: {
    id: 'f.churnRateLabel',
    defaultMessage: `What is your churn rate?`,
  },
  churnRateContext: {
    id: 'f.churnRateContext',
    defaultMessage: `What percentage of your current customer base will cancel before their payment is due? This rate will be applied to the client base who are up for renewal at the start of each forecast period—before any new signups are included. Most companies look to reduce their churn rate over time, and you can plan for this change by using the varying option to enter a separate rate for each period.`,
  },
  revenueOnlyLabel: {
    id: 'f.revenueOnlyLabel',
    defaultMessage: `How much will you gain from this revenue stream?`,
  },
  revenueOnlyContext: {
    id: 'f.revenueOnlyContext',
    defaultMessage: `If you’re looking to enter revenue totals for this revenue stream alone rather than tallying those totals from signups, unit sales, or other details, simply add in the projected revenue amount below.`,
  },
  // ----------------------------------------
  // -------------- EMPLOYEES ---------------
  // ----------------------------------------
  individualEmployeeOrGroup: {
    id: 'f.individualEmployeeOrGroup',
    defaultMessage: `Is this an individual employee or a group?`,
  },
  individualEmployeeOrGroupDescription: {
    id: 'f.individualEmployeeOrGroupDescription',
    defaultMessage: `Use the group option only for similar employees with the same start date. If you are filling the same role at multiple points in time, enter those hires individually with their own start dates.`,
  },
  employeeTypeLabel: {
    id: 'f.employeeTypeLabel',
    defaultMessage: `Do you want to treat them as direct labor?`,
  },
  employeeTypeDescription: {
    id: 'f.employeeTypeDescription',
    defaultMessage: `Select the direct labor option only if this labor is directly related to revenue. For example, a factory worker hired to assemble products for sale. A freelance designer hired to take on contract work from a creative agency would also fit this category. The key qualifier here is whether or not you want to consider an employee’s expenses as direct costs.`,
  },

  // ----------------------------------------
  // -------------- COSTS -------------------
  // ----------------------------------------
  costsLabel: {
    id: 'f.costsLabel',
    defaultMessage: `If you break down your costs by revenue stream, check out how profitable each of those streams is rather than just plugging them in as overall costs.`,
  },
  howDoYouWantToEnter: {
    id: 'f.howDoYouWantToEnter',
    defaultMessage: `How do you want to enter it?`,
  },
  howMuchIsIt: {
    id: 'f.howMuchIsIt',
    defaultMessage: `How much is it?`,
  },
  whatTypeOfExpenseIsThis: {
    id: 'f.whatTypeOfExpenseIsThis',
    defaultMessage: `What type of expense is this?`,
  },
  physicalSpace: {
    id: 'f.physicalSpace',
    defaultMessage: `Physical Space`,
  },
  marketing: {
    id: 'f.marketing',
    defaultMessage: `Marketing`,
  },
  equipment: {
    id: 'f.equipment',
    defaultMessage: `Equipment`,
  },
  otherType: {
    id: 'f.otherType',
    defaultMessage: `Other`,
  },
  accountsPayable: {
    id: 'f.accountsPayable',
    defaultMessage: `Accounts payable`,
  },
  accountsPayableDescription: {
    id: 'f.accountsPayableDescription',
    defaultMessage: `How much money do you owe other people?`,
  },
  corporateTaxesPayable: {
    id: 'f.corporateTaxesPayable',
    defaultMessage: `Corporate tax payable`,
  },
  corporateTaxesPayableDescription: {
    id: 'f.corporateTaxesPayableDescription',
    defaultMessage: `Have you accrued any money toward your next corporate tax payment? How much?`,
  },
  salesTaxesPayable: {
    id: 'f.salesTaxesPayable',
    defaultMessage: `Sales tax payable`,
  },
  salesTaxesPayableDescription: {
    id: 'f.salesTaxesPayableDescription',
    defaultMessage: `You have cash on hand, but how much of that is sales tax that will have to be passed to the government?`,
  },
  youHaveNotStartedFinancials: {
    id: 'f.youHaveNotStartedFinancials',
    defaultMessage: `You have not started creating your financial statements yet.`,
  },
  themeColor: {
    id: 'f.themeColor',
    defaultMessage: `Theme Color`,
  },
  themeColorDescription: {
    id: 'f.themeColorDescription',
    defaultMessage: `Select a theme color for the accent color of your PDF export.`,
  },
  includeFinancialGraphs: {
    id: 'f.includeFinancialGraphs',
    defaultMessage: `Include financial graphs?`,
  },
  includeConfidentialityStatement: {
    id: 'f.includeConfidentialityStatement',
    defaultMessage: `Include confidentiality statement?`,
  },
  confidentialityStatement: {
    id: 'f.confidentialityStatement',
    defaultMessage: `Confidentiality Statement`,
  },
  confidentialityStatementDescription: {
    id: 'f.confidentialityStatementDescription',
    defaultMessage: `Decide if you would like to include a confidentiality statement at the beginning of your business plan.`,
  },
  dividendNameLabel: {
    id: 'f.dividendNameLabel',
    defaultMessage: `What do you want to call this distribution?`,
  },
  dividendDistributeAmountQuestion: {
    id: 'f.dividendDistributeAmountQuestion',
    defaultMessage: `How much do you want to distribute and when?`,
  },
  isExpenseCOGS: {
    id: 'f.isExpenseCOGS',
    defaultMessage: `Is this expense part of your cost of goods sold?`,
  },
  whatDoYouWantToCallIt: {
    id: 'f.whatDoYouWantToCallIt',
    defaultMessage: `What do you want to call it?`,
  },
  howMuchWillYouRecieve: {
    id: 'f.howMuchWillYouRecieve',
    defaultMessage: `How much will you receive?`,
  },
  whenWillInvestmentBegin: {
    id: 'f.whenWillInvestmentBegin',
    defaultMessage: `When will this investment begin?`,
  },
  lineOfCreditNameLabel: {
    id: 'f.lineOfCreditNameLabel',
    defaultMessage: `What do you want to call this line of credit?`,
  },
  payments: {
    id: 'f.payments',
    defaultMessage: `Payments`,
  },
  lineOfCreditPaymentsLabel: {
    id: 'f.lineOfCreditPaymentsLabel',
    defaultMessage: `How much will you pay back against this line of credit and when?`,
  },
  lineOfCreditPaymentsDescription: {
    id: 'f.lineOfCreditPaymentsDescription',
    defaultMessage: `Enter the actual amount that you want to pay each period against your outstanding balance. Don’t worry, we’ll sort out the interest and principal pieces of the payments for you.`,
  },
  creditLimitLabel: {
    id: 'f.creditLimitLabel',
    defaultMessage: `What is the credit limit?`,
  },
  creditLimitDescription: {
    id: 'f.creditLimitDescription',
    defaultMessage: `Enter the maximum amount you are allowed to have as an outstanding balance on this line of credit.`,
  },
  existingBalanceLabel: {
    id: 'f.existingBalanceLabel',
    defaultMessage: `What is the exisiting balance as of your forecast start date ({ startDate })?`,
  },
  existingBalanceDescription: {
    id: 'f.existingBalanceDescription',
    defaultMessage: `If this line of credit was already in place before your forecast start date, enter the outstanding balance as of the start of that month.`,
  },
  creditAnnualInterestRateLabel: {
    id: 'f.creditAnnualInterestRateLabel',
    defaultMessage: `What is the annual interest rate of this line of credit?`,
  },
  creditAnnualInterestRateDescription: {
    id: 'f.creditAnnualInterestRateDescription',
    defaultMessage: `If you’re unclear on what interest rate you are paying on this credit line, check your bank documents or ask your lender`,
  },
  withdrawlsLabel: {
    id: 'f.withdrawlsLabel',
    defaultMessage: `How much will you pull out against this line of credit and when?`,
  },
  withdrawlsDescription: {
    id: 'f.withdrawlsDescription',
    defaultMessage: `Enter the amount that you plan to withdraw each period. Use the projected cash balance above as a guide for how much funding you need in order to always keep enough cash in the bank.`,
  },
  withdrawls: {
    id: 'f.withdrawls',
    defaultMessage: `Withdrawls`,
  },
  terms: {
    id: 'f.terms',
    defaultMessage: `Terms`,
  },
  name: {
    id: 'f.name',
    defaultMessage: `Name`,
  },
  type: {
    id: 'f.type',
    defaultMessage: `Type`,
  },
  status: {
    id: 'f.status',
    defaultMessage: `Status`,
  },
  loanStartLabel: {
    id: 'f.loanStartLabel',
    defaultMessage: `When will you receive the loan?`,
  },
  loanAmountLabelWithStartDate: {
    id: 'f.loanAmountLabelWithStartDate',
    defaultMessage: `How much do you owe as of the beginning of your forecast ({ startDate })?`,
  },
  loanLengthLabelWithStartDate: {
    id: 'f.loanLengthLabelWithStartDate',
    defaultMessage: `How many payments remain as of the beginning of your forecast ({ startDate })?`,
  },
  loanAnnualInterestRateLabel: {
    id: 'f.loanAnnualInterestRateLabel',
    defaultMessage: `What is the annual interest rate of the loan?`,
  },
  loanAmountLabel: {
    id: 'f.loanAmountLabel',
    defaultMessage: `How much will you receive?`,
  },
  loanInterestRateLabel: {
    id: 'f.loanInterestRateLabel',
    defaultMessage: `Interest rate of loan?`,
  },
  loanLengthLabel: {
    id: 'f.loanLengthLabel',
    defaultMessage: `Length of loan?`,
  },
  charges: {
    id: 'f.charges',
    defaultMessage: `Charges`,
  },
  churnRate: {
    id: 'f.churnRate',
    defaultMessage: `Churn Rate`,
  },
  revenue: {
    id: 'f.revenue',
    defaultMessage: `Revenue`,
  },
  customers: {
    id: 'f.customers',
    defaultMessage: `Customers`,
  },
  signups: {
    id: 'f.signups',
    defaultMessage: `Signups`,
  },
  keyPerformanceIndicators: {
    id: 'f.keyPerformanceIndicators',
    defaultMessage: `Key Performance Indicators`,
  },
  keyPerformanceIndicatorsTitle: {
    id: 'f.keyPerformanceIndicatorsTitle',
    defaultMessage: `Key Performance Indicators | LaunchPlan`,
  },
  keyPerformanceIndicatorsHeadline: {
    id: 'f.keyPerformanceIndicatorsHeadline',
    defaultMessage: `See your outlook`,
  },
  keyPerformanceIndicatorsSubheading: {
    id: 'f.keyPerformanceIndicatorsSubheading',
    defaultMessage: `View projections on your Key Performance Indicators based on your financials data.`,
  },
  ebitda: {
    id: 'f.ebitda',
    defaultMessage: 'EBITDA'
  },
  ebitdaDescription: {
    id: 'f.ebitdaDescription',
    defaultMessage: `EBITDA, or earnings before interest, taxes, depreciation, and amortization, is a measure of a company's overall financial performance and is used as an alternative to net income in some circumstances. EBITDA, however, can be misleading because it strips out the cost of capital investments like property, plant, and equipment.

    This metric also excludes expenses associated with debt by adding back interest expense and taxes to earnings. Nonetheless, it is a more precise measure of corporate performance since it is able to show earnings before the influence of accounting and financial deductions.
    
    Simply put, EBITDA is a measure of profitability. While there is no legal requirement for companies to disclose their EBITDA, according to the U.S. generally accepted accounting principles (GAAP), it can be worked out and reported using the information found in a company's financial statements.
    
    The earnings, tax, and interest figures are found on the income statement, while the depreciation and amortization figures are normally found in the notes to operating profit or on the cash flow statement. The usual shortcut to calculate EBITDA is to start with operating profit, also called earnings before interest and tax (EBIT) and then add back depreciation and amortization.`
  },
  ebitdaFull: {
    id: 'f.ebitdaFull',
    defaultMessage: 'Earnings before Interest, Taxes, Depreciation & Amortization'
  },
  grossProfitMargin: {
    id: 'f.grossProfitMargin',
    defaultMessage: 'Gross Profit Margin'
  },
  netProfitMargin: {
    id: 'f.netProfitMargin',
    defaultMessage: 'Net Profit Margin'
  },
  profitMargin: {
    id: 'f.profitMargin',
    defaultMessage: 'Profit Margin'
  },
  profitMargins: {
    id: 'f.profitMargins',
    defaultMessage: 'Profit Margins'
  },
  profitMarginsDescription: {
    id: 'f.profitMarginsDescription',
    defaultMessage: `Net Profit Margin
    
    The net profit margin, or simply net margin, measures how much net income or profit is generated as a percentage of revenue. It is the ratio of net profits to revenues for a company or business segment. Net profit margin is typically expressed as a percentage but can also be represented in decimal form. The net profit margin illustrates how much of each dollar in revenue collected by a company translates into profit.
    
    Gross Profit Margin
    
    Gross profit margin is a metric analysts use to assess a company's financial health by calculating the amount of money left over from product sales after subtracting the cost of goods sold (COGS). Sometimes referred to as the gross margin ratio, gross profit margin is frequently expressed as a percentage of sales.`
  },
  net: {
    id: 'f.net',
    defaultMessage: 'Net'
  },
  gross: {
    id: 'f.gross',
    defaultMessage: 'Gross'
  },
  currentRatio: {
    id: 'f.currentRatio',
    defaultMessage: 'Current Ratio'
  },
  currentRatioDescription: {
    id: 'f.currentRatioDescription',
    defaultMessage: `The current ratio is a liquidity ratio that measures a company's ability to pay short-term obligations or those due within one year. It tells investors and analysts how a company can maximize the current assets on its balance sheet to satisfy its current debt and other payables.

    A current ratio that is in line with the industry average or slightly higher is generally considered acceptable. A current ratio that is lower than the industry average may indicate a higher risk of distress or default. Similarly, if a company has a very high current ratio compared to its peer group, it indicates that management may not be using its assets efficiently.
    
    The current ratio is called “current” because, unlike some other liquidity ratios, it incorporates all current assets and current liabilities. The current ratio is sometimes called the working capital ratio.`
  },
  quickRatio: {
    id: 'f.quickRatio',
    defaultMessage: 'Quick Ratio'
  },
  quickRatioDescription: {
    id: 'f.quickRatioDescription',
    defaultMessage: `The quick ratio is an indicator of a company’s short-term liquidity position and measures a company’s ability to meet its short-term obligations with its most liquid assets.

    Since it indicates the company’s ability to instantly use its near-cash assets (assets that can be converted quickly to cash) to pay down its current liabilities, it is also called the acid test ratio. An "acid test" is a slang term for a quick test designed to produce instant results.`
  },
})

export default financials
