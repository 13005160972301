import * as AdminQueries from './admin'
import * as CanvasQueries from './canvas'
import * as CompanyQueries from './company'
import * as FinancialQueries from './financials'
import * as OrgQueries from './org'
import * as PlanQueries from './plan'
import * as UserQueries from './user'


export default {
  ...AdminQueries,
  ...CanvasQueries,
  ...CompanyQueries,
  ...OrgQueries,
  ...FinancialQueries,
  ...PlanQueries,
  ...UserQueries,
}
