import gql from 'graphql-tag'
import * as Fragments from '../fragments'


export const financialsQuery = gql`
  query financials ($company: String) {
    financials(company: $company) {
      financials {
        ...FinancialsFragment
      }
      forecasts {
        ...FinancialForecastsFragment
      }
    }
  }
  ${Fragments.FinancialsFragment}
  ${Fragments.FinancialForecastsFragment}
`



// ---------------------------------------
// Create
// ---------------------------------------

export const createRevenueMutation = gql`
  mutation createRevenue ($input: BaseFinancialItemInput) {
    createRevenue(
      input: $input
    ) {
      ...FinancialItemPayloadFragment
    },
  }
  ${Fragments.FinancialItemPayloadFragment}
`

export const createExpenseMutation = gql`
  mutation createExpense ($input: BaseFinancialItemInput) {
    createExpense(input: $input) {
      ...FinancialItemPayloadFragment
    },
  }
  ${Fragments.FinancialItemPayloadFragment}
`

export const createCostMutation = gql`
  mutation createCost ($input: BaseFinancialItemInput) {
    createCost(input: $input) {
      ...FinancialItemPayloadFragment
    },
  }
  ${Fragments.FinancialItemPayloadFragment}
`

export const createEmployeeMutation = gql`
  mutation createEmployee ($input: EmployeeInput) {
    createEmployee(input: $input) {
      ...EmployeePayloadFragment
    },
  }
  ${Fragments.EmployeePayloadFragment}
`

export const createInvestmentMutation = gql`
  mutation createInvestment ($input: BaseFinancialItemInput) {
    createInvestment(input: $input) {
      ...FinancialItemPayloadFragment
    },
  }
  ${Fragments.FinancialItemPayloadFragment}
`

export const createDividendMutation = gql`
  mutation createDividend ($input: BaseFinancialItemInput) {
    createDividend(input: $input) {
      ...FinancialItemPayloadFragment
    },
  }
  ${Fragments.FinancialItemPayloadFragment}
`

export const createLoanMutation = gql`
  mutation createLoan ($input: LoanInput) {
    createLoan(input: $input) {
      ...LoanPayloadFragment
    },
  }
  ${Fragments.LoanPayloadFragment}
`

export const createAssetMutation = gql`
  mutation createAsset ($input: AssetInput) {
    createAsset(input: $input) {
      ...AssetPayloadFragment
    },
  }
  ${Fragments.AssetPayloadFragment}
`

export const createLineOfCreditMutation = gql`
  mutation createLineOfCredit ($input: LineOfCreditInput) {
    createLineOfCredit(input: $input) {
      ...LineOfCreditPayloadFragment
    },
  }
  ${Fragments.LineOfCreditPayloadFragment}
`




// ---------------------------------------
// Update
// ---------------------------------------

export const updateRevenueMutation = gql`
  mutation updateRevenue ($input: BaseFinancialItemInput) {
    updateRevenue(input: $input) {
      ...FinancialItemPayloadFragment
    },
  }
  ${Fragments.FinancialItemPayloadFragment}
`

export const updateExpenseMutation = gql`
  mutation updateExpense ($input: BaseFinancialItemInput) {
    updateExpense(input: $input) {
      ...FinancialItemPayloadFragment
    },
  }
  ${Fragments.FinancialItemPayloadFragment}
`

export const updateCostMutation = gql`
  mutation updateCost ($input: BaseFinancialItemInput) {
    updateCost(input: $input) {
      ...FinancialItemPayloadFragment
    },
  }
  ${Fragments.FinancialItemPayloadFragment}
`

export const updateEmployeeMutation = gql`
  mutation updateEmployee ($input: EmployeeInput) {
    updateEmployee(input: $input) {
      ...EmployeePayloadFragment
    },
  }
  ${Fragments.EmployeePayloadFragment}
`

export const updateInvestmentMutation = gql`
  mutation updateInvestment ($input: BaseFinancialItemInput) {
    updateInvestment(input: $input) {
      ...FinancialItemPayloadFragment
    },
  }
  ${Fragments.FinancialItemPayloadFragment}
`

export const updateDividendMutation = gql`
  mutation updateDividend ($input: BaseFinancialItemInput) {
    updateDividend(input: $input) {
      ...FinancialItemPayloadFragment
    },
  }
  ${Fragments.FinancialItemPayloadFragment}
`

export const updateTaxMutation = gql`
  mutation updateTax ($input: TaxInput) {
    updateTax(input: $input) {
      ...TaxPayloadFragment
    },
  }
  ${Fragments.TaxPayloadFragment}
`

export const updateAssumptionsMutation = gql`
  mutation updateAssumptions ($input: AssumptionsInput) {
    updateAssumptions(input: $input) {
      ...AssumptionsPayloadFragment
    },
  }
  ${Fragments.AssumptionsPayloadFragment}
`

export const updateStartingBalanceMutation = gql`
  mutation updateStartingBalance ($input: StartingBalanceInput) {
    updateStartingBalance(input: $input) {
      ...StartingBalancePayloadFragment
    },
  }
  ${Fragments.StartingBalancePayloadFragment}
`

export const updateLoanMutation = gql`
  mutation updateLoan ($input: LoanInput) {
    updateLoan(input: $input) {
      ...LoanPayloadFragment
    },
  }
  ${Fragments.LoanPayloadFragment}
`

export const updateAssetMutation = gql`
  mutation updateAsset ($input: AssetInput) {
    updateAsset(input: $input) {
      ...AssetPayloadFragment
    },
  }
  ${Fragments.AssetPayloadFragment}
`

export const updateLineOfCreditMutation = gql`
  mutation updateLineOfCredit ($input: LineOfCreditInput) {
    updateLineOfCredit(input: $input) {
      ...LineOfCreditPayloadFragment
    },
  }
  ${Fragments.LineOfCreditPayloadFragment}
`




// ---------------------------------------
// Delete
// ---------------------------------------

export const deleteRevenueMutation = gql`
  mutation deleteRevenue ($input: DeleteFinancialItemInput) {
    deleteRevenue(input: $input) {
      ...FinancialItemPayloadFragment
    },
  }
  ${Fragments.FinancialItemPayloadFragment}
`

export const deleteExpenseMutation = gql`
  mutation deleteExpense ($input: DeleteFinancialItemInput) {
    deleteExpense(input: $input) {
      ...FinancialItemPayloadFragment
    },
  }
  ${Fragments.FinancialItemPayloadFragment}
`

export const deleteCostMutation = gql`
  mutation deleteCost ($input: DeleteFinancialItemInput) {
    deleteCost(input: $input) {
      ...FinancialItemPayloadFragment
    },
  }
  ${Fragments.FinancialItemPayloadFragment}
`

export const deleteInvestmentMutation = gql`
  mutation deleteInvestment ($input: DeleteFinancialItemInput) {
    deleteInvestment(input: $input) {
      ...FinancialItemPayloadFragment
    },
  }
  ${Fragments.FinancialItemPayloadFragment}
`

export const deleteDividendMutation = gql`
  mutation deleteDividend ($input: DeleteFinancialItemInput) {
    deleteDividend(input: $input) {
      ...FinancialItemPayloadFragment
    },
  }
  ${Fragments.FinancialItemPayloadFragment}
`

export const deleteEmployeeMutation = gql`
  mutation deleteEmployee ($input: DeleteFinancialItemInput) {
    deleteEmployee(input: $input) {
      ...EmployeePayloadFragment
    },
  }
  ${Fragments.EmployeePayloadFragment}
`

export const deleteAssetMutation = gql`
  mutation deleteAsset ($input: DeleteFinancialItemInput) {
    deleteAsset(input: $input) {
      ...AssetPayloadFragment
    },
  }
  ${Fragments.AssetPayloadFragment}
`

export const deleteLoanMutation = gql`
  mutation deleteLoan ($input: DeleteFinancialItemInput) {
    deleteLoan(input: $input) {
      ...LoanPayloadFragment
    },
  }
  ${Fragments.LoanPayloadFragment}
`

export const deleteLineOfCreditMutation = gql`
  mutation deleteLineOfCredit ($input: DeleteFinancialItemInput) {
    deleteLineOfCredit(input: $input) {
      ...LineOfCreditPayloadFragment
    },
  }
  ${Fragments.LineOfCreditPayloadFragment}
`