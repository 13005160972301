import { defineMessages } from 'react-intl'

const pageTitles = defineMessages({
  balanceSheetTitle: {
    id: 'pageTitles.balanceSheetTitle',
    defaultMessage: 'Balance Sheet | Financials | LaunchPlan'
  },
  cashFlowTitle: {
    id: 'pageTitles.cashFlowTitle',
    defaultMessage: 'Cash Flow | Financials | LaunchPlan'
  },
  profitLossTitle: {
    id: 'pageTitles.profitLossTitle',
    defaultMessage: 'Profit & Loss | Financials | LaunchPlan'
  },
})

export default pageTitles
