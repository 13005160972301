import { defineMessages } from 'react-intl'

const common = defineMessages({
  hello: {
    id: 'common.hello',
    defaultMessage: 'Hello',
  },
  yes: {
    id: 'common.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'common.no',
    defaultMessage: 'No',
  },
  email: {
    id: 'common.email',
    defaultMessage: 'Email',
  },
  password: {
    id: 'common.password',
    defaultMessage: 'Password',
  },
  currentPassword: {
    id: 'common.currentPassword',
    defaultMessage: 'Current password',
  },
  newPassword: {
    id: 'common.newPassword',
    defaultMessage: 'New password',
  },
  newPasswordConfirmation: {
    id: 'common.newPasswordConfirmation',
    defaultMessage: 'New password confirmation',
  },
  saved: {
    id: 'common.saved',
    defaultMessage: 'Saved',
  },
  send: {
    id: 'common.send',
    defaultMessage: 'Send',
  },
  delete: {
    id: 'common.delete',
    defaultMessage: 'Delete',
  },
  deleteItem: {
    id: 'common.deleteItem',
    defaultMessage: 'Delete { title }',
  },
  deleteConfirmation: {
    id: 'common.deleteConfirmation',
    defaultMessage: 'Are you sure you want to delete this?',
  },
  deleteRevenueConfirmation: {
    id: 'common.deleteRevenueConfirmation',
    defaultMessage:
      'Are you sure you want to delete this? If there are any costs or expenses that are set as a percentage of this revenue stream, they will also be deleted.',
  },
  edit: {
    id: 'common.edit',
    defaultMessage: 'Edit',
  },
  download: {
    id: 'common.download',
    defaultMessage: 'Download',
  },
  page: {
    id: 'common.page',
    defaultMessage: 'Page',
  },
  remove: {
    id: 'common.remove',
    defaultMessage: 'Remove',
  },
  view: {
    id: 'common.view',
    defaultMessage: 'View',
  },
  signup: {
    id: 'common.signup',
    defaultMessage: 'Join',
  },
  payment: {
    id: 'common.payment',
    defaultMessage: 'Payment',
  },
  example: {
    id: 'common.example',
    defaultMessage: 'Example',
  },
  free: {
    id: 'common.free',
    defaultMessage: 'Free',
  },
  entrepreneur: {
    id: 'common.entrepreneur',
    defaultMessage: 'Entrepreneur',
  },
  getStarted: {
    id: 'common.getStarted',
    defaultMessage: 'Get started',
  },
  forecast: {
    id: 'common.forecast',
    defaultMessage: 'Forecast',
  },
  monthly: {
    id: 'common.monthly',
    defaultMessage: 'Monthly',
  },
  quarterly: {
    id: 'common.quarterly',
    defaultMessage: 'Quarterly',
  },
  yearly: {
    id: 'common.yearly',
    defaultMessage: 'Yearly',
  },
  month: {
    id: 'common.month',
    defaultMessage: 'Month',
  },
  year: {
    id: 'common.year',
    defaultMessage: 'Year',
  },
  amount: {
    id: 'common.amount',
    defaultMessage: 'Amount',
  },
  you: {
    id: 'common.you',
    defaultMessage: 'You',
  },
  signin: {
    id: 'common.signin',
    defaultMessage: 'Sign in',
  },
  resendCode: {
    id: 'common.resendCode',
    defaultMessage: `Resend code`,
  },
  code: {
    id: 'common.code',
    defaultMessage: `Code`,
  },
  forgotPassword: {
    id: 'common.forgotPassword',
    defaultMessage: `Forgot password?`,
  },
  alreadyHaveAccount: {
    id: 'common.alreadyHaveAccount',
    defaultMessage: `Already have a LaunchPlan account?`,
  },
  dontHaveAccount: {
    id: 'common.dontHaveAccount',
    defaultMessage: `Don't have an account?`,
  },
  signout: {
    id: 'common.signout',
    defaultMessage: 'Sign out',
  },
  start: {
    id: 'common.start',
    defaultMessage: 'Start',
  },
  revisit: {
    id: 'common.revisit',
    defaultMessage: 'Revisit',
  },
  addNew: {
    id: 'common.addNew',
    defaultMessage: 'Add new',
  },
  addNewItem: {
    id: 'common.addNewItem',
    defaultMessage: 'Add new { item }',
  },
  next: {
    id: 'common.next',
    defaultMessage: 'Next',
  },
  cancel: {
    id: 'common.cancel',
    defaultMessage: 'Cancel',
  },
  close: {
    id: 'common.close',
    defaultMessage: 'Close',
  },
  goBack: {
    id: 'common.goBack',
    defaultMessage: 'Go back',
  },
  submit: {
    id: 'common.submit',
    defaultMessage: 'Submit',
  },
  comingSoon: {
    id: 'common.comingSoon',
    defaultMessage: 'Coming soon',
  },
  fullName: {
    id: 'common.fullName',
    defaultMessage: 'Full name',
  },
  displayName: {
    id: 'common.displayName',
    defaultMessage: 'Display name',
  },
  whatDisplayName: {
    id: 'common.whatDisplayName',
    defaultMessage: 'What name would you like us to call you by?',
  },
  save: {
    id: 'common.save',
    defaultMessage: 'Save',
  },
  saveExit: {
    id: 'common.saveExit',
    defaultMessage: 'Save & Exit',
  },
  saveChanges: {
    id: 'common.saveChanges',
    defaultMessage: 'Save changes',
  },
  finish: {
    id: 'common.finish',
    defaultMessage: 'Finish',
  },
  finishAndAddAnother: {
    id: 'common.finishAndAddAnother',
    defaultMessage: 'Finish and Add Another',
  },
  update: {
    id: 'common.update',
    defaultMessage: 'Update',
  },
  loading: {
    id: 'common.loading',
    defaultMessage: 'Loading',
  },
  lastUpdated: {
    id: 'common.lastUpdated',
    defaultMessage: 'Last updated',
  },
  enterNameOrDescription: {
    id: 'common.enterNameOrDescription',
    defaultMessage: 'Enter a name or description',
  },
  moreInformation: {
    id: 'common.moreInformation',
    defaultMessage: 'More information',
  },
  seeExample: {
    id: 'common.seeExample',
    defaultMessage: 'See example',
  },
  completed: {
    id: 'common.completed',
    defaultMessage: 'Completed',
  },
  overview: {
    id: 'common.overview',
    defaultMessage: 'Overview',
  },
  plan: {
    id: 'common.plan',
    defaultMessage: 'Plan',
  },
  admin: {
    id: 'common.admin',
    defaultMessage: 'Admin',
  },
  settings: {
    id: 'common.settings',
    defaultMessage: 'Settings',
  },
  companySettings: {
    id: 'company.companySettings',
    defaultMessage: 'Company Settings',
  },
  chooseOne: {
    id: 'common.chooseOne',
    defaultMessage: 'Choose one',
  },
  pleaseSelectAnOption: {
    id: 'common.pleaseSelectAnOption',
    defaultMessage: 'Please select an option',
  },
  progress: {
    id: 'common.progress',
    defaultMessage: 'Progress',
  },
  sharePlanOnline: {
    id: 'common.sharePlanOnline',
    defaultMessage: 'Share Plan Online',
  },
  sharePlanOnlineDescription: {
    id: 'common.sharePlanOnlineDescription',
    defaultMessage: `You can share your business model canvas and business plan online at a public web address to share with anyone you'd like. Save the trees.`,
  },
  shareOnline: {
    id: 'common.shareOnline',
    defaultMessage: 'Share Online',
  },
  shareCanvasOnline: {
    id: 'common.shareCanvasOnline',
    defaultMessage: 'Share Canvas Online',
  },
  shared: {
    id: 'common.shared',
    defaultMessage: 'Shared',
  },
  notShared: {
    id: 'common.notShared',
    defaultMessage: 'Not Shared',
  },
  yourCanvasIsLive: {
    id: 'common.yourCanvasIsLive',
    defaultMessage: 'Your canvas is live',
  },
  yourPlanIsLive: {
    id: 'common.yourPlanIsLive',
    defaultMessage: 'Your plan is live',
  },
  chapterOrder: {
    id: 'common.chapterOrder',
    defaultMessage: 'Chapter Order',
  },
  pleaseChooseOne: {
    id: 'common.pleaseChooseOne',
    defaultMessage: 'Please choose one...',
  },
  noFee: {
    id: 'common.noFee',
    defaultMessage: 'No fee',
  },
  fee: {
    id: 'common.fee',
    defaultMessage: 'Fee',
  },
  oneTime: {
    id: 'common.oneTime',
    defaultMessage: 'One-time',
  },
  constantAmount: {
    id: 'common.constantAmount',
    defaultMessage: 'Constant amount',
  },
  varyingAmount: {
    id: 'common.varyingAmount',
    defaultMessage: 'Varying amount',
  },
  percentageOverallRevenue: {
    id: 'common.percentageOverallRevenue',
    defaultMessage: 'Percentage of overall revenue',
  },
  percentageSpecificRevenueStream: {
    id: 'common.percentageSpecificRevenueStream',
    defaultMessage: 'Percentage of specific revenue stream',
  },
  oneTimeValueLabel: {
    id: 'common.oneTimeValueLabel',
    defaultMessage: 'How much is this one-time amount?',
  },
  oneTimeValuePlaceholder: {
    id: 'common.oneTimeValuePlaceholder',
    defaultMessage: 'Enter amount',
  },
  startLabel: {
    id: 'common.startLabel',
    defaultMessage: 'When will this start?',
  },
  percentageOverallRevenueLabel: {
    id: 'common.percentageOverallRevenueLabel',
    defaultMessage: 'What percentage of revenue?',
  },
  percentageRevenueStreamLabel: {
    id: 'common.percentageRevenueStreamLabel',
    defaultMessage: 'What percentage of this revenue?',
  },
  revenueStreamLabel: {
    id: 'common.revenueStreamLabel',
    defaultMessage: 'Which revenue stream?',
  },
  notFoundErrorMessage: {
    id: 'common.notFoundErrorMessage',
    defaultMessage: `Whoops, it appears this part of the rocket hasn't been built yet.`,
  },
  backToHomepage: {
    id: 'common.backToHomepage',
    defaultMessage: `Back to the homepage`,
  },
  backToOverview: {
    id: 'common.backToOverview',
    defaultMessage: `Back to overview`,
  },
  errorNotification: {
    id: 'common.errorNotification',
    defaultMessage: `Sorry about this, we had something go wrong. We've been notified of the error and are looking into it.`,
  },
  promotionCode: {
    id: 'common.promotionCode',
    defaultMessage: `Promotion Code`,
  },
})

export default common
