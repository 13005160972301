import { isEmpty } from 'lodash'
import moment from 'moment'
import { ACCOUNT_TYPES } from '../types'

// Helper to get the initials from a name that is either 1, or 2 words long, ignores others
export function getInitials(name) {
  if (!name) return ''

  const names = name.split(' ')
  let initials

  if (names[0]) {
    initials = names[0].substring(0, 1)
  }

  if (names[1]) {
    initials += names[1].substring(0, 1)
  }

  return initials.toUpperCase()
}

export function capitalize(string) {
  if (process.env.NODE_ENV !== 'production' && typeof string !== 'string') {
    throw new Error('capitalize(string) expects a string argument.')
  }

  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getCreatedDateFromMongoId = id => {
  const ts = id.substring(0, 8)

  return moment(new Date(parseInt(ts, 16) * 1000))
}

export function getNullSeriesArray(amount, length) {
  let series = []

  for (let i = 0; i < amount; i++) {
    series[i] = getNullArray(length)
  }

  return series
}

export function getNullArray(length) {
  return new Array(length).fill(null)
}

export const handleStandardError = error => {
  if (!error) {
    throw new Error('No error provided')
  }

  const message =
    error.response && error.response.data && error.response.data.error

  return message ? { _error: message } : { _error: error }
}

// Format errors for app, friendly for user and redux-form or show actual
// errors if not in development mode
export const handleErrors = errors => {
  if (!errors) {
    throw new Error('No errors provided')
  }

  if (typeof errors === 'string') return { _error: errors }

  let uiErrors = {}

  if (process.env.NODE_ENV === 'development') {
    console.error(`ALL ERRORS - ${JSON.stringify(errors)}`)
  }

  if (!isEmpty(errors.networkError)) {
    errors.networkError.result && errors.networkError.result.errors && errors.networkError.result.errors.forEach(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error.message)
      }
      uiErrors = Object.assign(uiErrors, { _error: error.message })
    })
  }

  if (!isEmpty(errors.graphQLErrors)) {
    errors.graphQLErrors.forEach(error => {
      if (error.name === 'AuthenticationRequiredError') {
        return { _error: error.message }
      }
      if (error.message) {
        uiErrors = Object.assign(uiErrors, { _error: error.message })
      }

      try {
        uiErrors = Object.assign(uiErrors, ...error.data.errors)
        return uiErrors
      } catch (error) {
        if (!isEmpty(uiErrors)) {
          return uiErrors
        }

        return (uiErrors = { _error: 'An unknown error occured' })
      }
    })
  }

  if (isEmpty(uiErrors)) {
    uiErrors = { _error: errors.message }
  }

  return uiErrors
}

export const cleanGraphQLError = error => {
  const cleanString = error.replace('GraphQL error: ', '')

  return cleanString
}

export const sequenceSort = (a, b) => {
  return a.sequence - b.sequence
}

export const isBehindPaywall = (company) => {
  return company.settings && company.settings.owner && company.settings.owner.accountType === ACCOUNT_TYPES.FREE_ACCOUNT
}