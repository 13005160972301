// ***************************
//  Lifecycle types
// ***************************
export const APP_START = 'APP_START'

// ***************************
//  Auth types
// ***************************
export const AUTH_USER = 'AUTH_USER'
export const UNAUTH_USER = 'UNAUTH_USER'
export const SIGNIN_USER = 'SIGNIN_USER'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOADING = 'LOADING'
export const NOT_LOADING = 'NOT_LOADING'
export const VALIDATE_VERIFY_URL = 'VALIDATE_VERIFY_URL'
export const INVALIDATE_VERIFY_URL = 'INVALIDATE_VERIFY_URL'
export const VALIDATE_RESET_URL = 'VALIDATE_RESET_URL'
export const INVALIDATE_RESET_URL = 'INVALIDATE_RESET_URL'
export const RESET_PASSWORD_SENT = 'RESET_PASSWORD_SENT'
export const CLEAR_RESET_PASSWORD_SENT = 'CLEAR_RESET_PASSWORD_SENT'
export const USER_IDENTIFIED = 'USER_IDENTIFIED'
export const CLEAR_FREE_TRIAL_EXPIRED = 'CLEAR_FREE_TRIAL_EXPIRED'
export const FREE_TRIAL_EXPIRED = 'FREE_TRIAL_EXPIRED'

// ***************************
//  Plan types
// ***************************
export const SET_PLAN = 'SET_PLAN'
export const EDIT_CARD = 'EDIT_CARD'
export const UPDATE_CARD = 'UPDATE_CARD'
export const UPDATE_CHAPTER = 'UPDATE_CHAPTER'
export const DONE_EDITING = 'DONE_EDITING'
export const ADD_CARD = 'ADD_CARD'
export const ADD_CHAPTER = 'ADD_CHAPTER'
export const DELETE_CARD = 'DELETE_CARD'
export const DELETE_CHAPTER = 'DELETE_CHAPTER'
export const SELECTED_CHAPTER = 'SELECTED_CHAPTER'
export const SET_PDF_URL = 'SET_PDF_URL'
export const MOVE_CARD = 'MOVE_CARD'
export const MOVE_CHAPTER = 'MOVE_CHAPTER'
export const UPDATE_CARD_SEQUENCES = 'UPDATE_CARD_SEQUENCES'
export const UPDATE_CHAPTER_SEQUENCES = 'UPDATE_CHAPTER_SEQUENCES'

// ***************************
//  User types
// ***************************
export const SET_USER = 'SET_USER'
export const DELETE_USER = 'DELETE_USER'
export const VERIFY_USER = 'VERIFY_USER'
export const UPDATE_USER_IMAGE = 'UPDATE_USER_IMAGE'
export const UPDATE_USER_ACCOUNT_COMPANIES = 'UPDATE_USER_ACCOUNT_COMPANIES'
export const GET_EXPORT_HISTORY = 'GET_EXPORT_HISTORY'
export const GET_EXPORT_STATUS = 'GET_EXPORT_STATUS'
export const ADD_NEW_EXPORT_TICKET = 'ADD_NEW_EXPORT_TICKET'
export const MARK_NOTIFICATION_READ = 'MARK_NOTIFICATION_READ'

// ***************************
//  Financials types
// ***************************
export const SET_DOCUMENT = 'SET_DOCUMENT'
export const SET_FINANCIALS = 'SET_FINANCIALS'
export const SET_FINANCIALS_ITEM = 'SET_FINANCIALS_ITEM'
export const SET_CURRENT_WIZARD_ITEM = 'SET_CURRENT_WIZARD_ITEM'
export const CLEAR_CURRENT_WIZARD_ITEM = 'CLEAR_CURRENT_WIZARD_ITEM'
export const DELETE_FINANCIALS_ITEM = 'DELETE_FINANCIALS_ITEM'
export const SET_FORECASTS = 'SET_FORECASTS'
export const SET_FINANCIALS_OBJECT = 'SET_FINANCIALS_OBJECT'
export const SET_TIME_PIVOT = 'SET_TIME_PIVOT'

// ***************************
//  Company types
// ***************************
export const SET_COMPANY = 'SET_COMPANY'
export const DELETE_COMPANY = 'DELETE_COMPANY'
export const CLEAR_COMPANY = 'CLEAR_COMPANY'
export const SET_ORG = 'SET_ORG'
export const CLEAR_ORG = 'CLEAR_ORG'
export const SET_COMPANY_LOADING = 'SET_COMPANY_LOADING'

// ***************************
//  Public Share types
// ***************************
export const SET_PUBLIC_SHARE_COMPANY = 'SET_PUBLIC_SHARE_COMPANY'
export const SET_PUBLIC_SHARE_PLAN = 'SET_PUBLIC_SHARE_PLAN'
export const SET_PUBLIC_SHARE_FINANCIALS = 'SET_PUBLIC_SHARE_FINANCIALS'
export const SET_PUBLIC_SHARE_FORECASTS = 'SET_PUBLIC_SHARE_FORECASTS'
export const CLEAR_PUBLIC_SHARE = 'CLEAR_PUBLIC_SHARE'

// ***************************
//  Layout types
// ***************************
export const SET_INFORMATION_DRAWER = 'SET_INFORMATION_DRAWER'
export const CLOSE_INFORMATION_DRAWER = 'CLOSE_INFORMATION_DRAWER'
export const OPEN_FLASH_MESSAGE = 'OPEN_FLASH_MESSAGE'
export const CLOSE_FLASH_MESSAGE = 'CLOSE_FLASH_MESSAGE'
export const TURN_ON_SOUND = 'TURN_ON_SOUND'
export const TURN_OFF_SOUND = 'TURN_OFF_SOUND'

// ***************************
//  Canvas types
// ***************************
export const SET_CANVAS = 'SET_CANVAS'
export const UPDATE_BLOCK = 'UPDATE_BLOCK'

// ***************************
//  Admin types
// ***************************
export const ADD_PROMOTION = 'ADD_PROMOTION'
export const DELETE_PROMOTION = 'DELETE_PROMOTION'
export const SET_ADMIN_DATA = 'SET_ADMIN_DATA'
export const ADD_ORG_ADMIN_ORGS_LIST = 'ADD_ORG_ADMIN_ORGS_LIST'

// ***************************
//  Org types
// ***************************
export const SET_ADVISOR_COMPANIES = 'SET_ADVISOR_COMPANIES'

// ***************************
//  Intl types
// ***************************
export const SET_LOCALE = 'SET_LOCALE'

// ***************************
//  Web socket update types
// ***************************
export const WEB_SOCKET_CONNECTED = 'WEB_SOCKET_CONNECTED'
export const RESOURCE_LOCK_UPDATE = 'RESOURCE_LOCK_UPDATE'
export const DISCONNECT = 'disconnect'
export const RESOURCE_LOCK_ENTER = 'resource:lock:enter'
export const RESOURCE_LOCK_LEAVE = 'resource:lock:leave'
export const RESOURCE_LOCK_LIST = 'resource:lock:list'
export const GET_RESOURCE_LOCK_LIST = 'resource:lock:list:get'
