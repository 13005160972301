import { defineMessages } from 'react-intl'

const Company = defineMessages({
  company: {
    id: 'c.company',
    defaultMessage: 'Company'
  },
  companies: {
    id: 'c.companies',
    defaultMessage: 'Companies'
  },
  yourCompanies: {
    id: 'c.yourCompanies',
    defaultMessage: 'Your Companies'
  },
  viewCompany: {
    id: 'c.viewCompany',
    defaultMessage: 'View Company'
  },
  noCompaniesCreated: {
    id: 'c.noCompaniesCreated',
    defaultMessage: 'No companies created yet'
  },
  companyDetails: {
    id: 'c.companyDetails',
    defaultMessage: 'Company details'
  },
  createCompany: {
    id: 'c.launchCompany',
    defaultMessage: 'Launch company'
  },
  launchNewCompany: {
    id: 'c.launchNewCompany',
    defaultMessage: 'Launch a new company'
  },
  companyName: {
    id: 'c.companyName',
    defaultMessage: 'What do you want to call this company?'
  },
  companyNamePlaceholder: {
    id: 'c.companyNamePlaceholder',
    defaultMessage: 'Enter a name for you company'
  },
  noCompanySelected: {
    id: 'c.noCompanySelected',
    defaultMessage: 'No company selected'
  },
  industry: {
    id: 'c.industry',
    defaultMessage: 'What is the industry classification for your company?'
  },
  industryPlaceholder: {
    id: 'c.industryPlaceholder',
    defaultMessage: `Select your company's industry`
  },
  forecastStart: {
    id: 'c.forecastStart',
    defaultMessage: 'When do you plan to start your business?'
  },
  forecastLength: {
    id: 'c.forecastLength',
    defaultMessage: 'How many years do you want your forecast to cover?'
  },
  currency: {
    id: 'c.currency',
    defaultMessage: 'What currency do you want to use in your forecast?'
  },
  teamMembers: {
    id: 'c.teamMembers',
    defaultMessage: 'Team Members'
  },
  addMember: {
    id: 'c.addMember',
    defaultMessage: 'Add member'
  },
  invite: {
    id: 'c.invite',
    defaultMessage: 'Invite'
  },
  enterColleagueEmail: {
    id: 'c.enterColleagueEmail',
    defaultMessage: `Enter your colleague's email address`
  },
  noCurrentTeamMembers: {
    id: 'c.noCurrentTeamMembers',
    defaultMessage: `There are no team members added to this company. Collaborate now and invite a colleague!`
  },
  noCurrentTeamMembersOrg: {
    id: 'c.noCurrentTeamMembersOrg',
    defaultMessage: `There are no organizational team members added to this company. Invite a new or existing user by email here.`
  },
  removeMember: {
    id: 'c.removeMember',
    defaultMessage: 'Remove member'
  },
  removeTeamMemberConfirmationMessage: {
    id: 'c.removeTeamMemberConfirmationMessage',
    defaultMessage: `{name} won't be able to collaborate and work on this company's LaunchPlan anymore. Are you sure you want to remove them?`
  },
  leaveTeam: {
    id: 'c.leaveTeam',
    defaultMessage: `Leave team`
  },
  leave: {
    id: 'c.leave',
    defaultMessage: `Leave`
  },
  removeSelfAsTeamMemberConfirmationMessage: {
    id: 'c.removeSelfAsTeamMemberConfirmationMessage',
    defaultMessage: `Are you sure you want to leave this team?`
  },
  teamMember: {
    id: 'c.teamMember',
    defaultMessage: 'Team member'
  },
  accepted: {
    id: 'c.accepted',
    defaultMessage: 'Accepted'
  },
  notAccepted: {
    id: 'c.notAccepted',
    defaultMessage: 'Not accepted'
  },
  inviteSuccess: {
    id: 'c.inviteSuccess',
    defaultMessage: 'Invite sent successfully!'
  },
  companyCreatedSuccess: {
    id: 'c.companyCreatedSuccess',
    defaultMessage: 'Company launched successfully!'
  },
  deleteCompany: {
    id: 'c.deleteCompany',
    defaultMessage: 'Delete company'
  },
  deleteCompanyConfirmation: {
    id: 'c.deleteCompanyConfirmation',
    defaultMessage: 'Are you sure you want to delete {company}? This is irreversible and will remove all team members.'
  },
  team: {
    id: 'c.team',
    defaultMessage: 'Team'
  },
  owner: {
    id: 'c.owner',
    defaultMessage: 'Owner'
  },
  companySettingsUpdated: {
    id: 'c.companySettingsUpdated',
    defaultMessage: 'Company settings updated successfully!'
  },
  selectCompanyYouDontOwn: {
    id: 'c.selectCompanyYouDontOwn',
    defaultMessage: `Select other company`
  },
  confirm: {
    id: 'c.confirm',
    defaultMessage: `Confirm`
  },
  makeOwner: {
    id: 'c.makeOwner',
    defaultMessage: `Make Owner`
  },
  changeCompanyOwner: {
    id: 'c.changeCompanyOwner',
    defaultMessage: `Change company owner`
  },
  changeCompanyOwnerConfirmationMessage: {
    id: 'c.changeCompanyOwnerConfirmationMessage',
    defaultMessage: `Are you sure you want to change the owner of the company? Assigning ownership to another person can not be undone by you once completed.`
  },
  companyOwnerUpdatedSuccessfully: {
    id: 'c.companyOwnerUpdatedSuccessfully',
    defaultMessage: `Company owner changed successfully`
  },
})

export default Company
