import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import { createBrowserHistory } from 'history'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import { IntlProvider, addLocaleData } from 'react-intl'
import en from 'react-intl/locale-data/en'
import es from 'react-intl/locale-data/es'
import fr from 'react-intl/locale-data/fr'
import de from 'react-intl/locale-data/de'
import it from 'react-intl/locale-data/it'
import ja from 'react-intl/locale-data/ja'
import nl from 'react-intl/locale-data/nl'
import pl from 'react-intl/locale-data/pl'
import ru from 'react-intl/locale-data/ru'
import zh from 'react-intl/locale-data/zh'
import reduxThunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger'
import LogRocket from 'logrocket'
import TagManager from 'react-gtm-module'
import * as serviceWorker from './serviceWorker'
import reducers from './reducers'
import graphqlClient from './utils/graphqlClient'
import App from './components/App'
import { applicationStart } from './actions/lifecycle.actions'
import { SET_LOCALE } from './actions/types'
import { loadState, saveState } from './utils/localStorage'
import strings from './assets/strings/build/lang/strings.json'
import { getIntl } from './utils/intl'
import theme from './assets/styles/theme'
import './assets/styles/normalize.css'
import './assets/styles/index.css'

const middlewares = []

if (process.env.NODE_ENV === `production`) {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)
  LogRocket.init(process.env.REACT_APP_LOGROCKET_CODE)
}

// Set up logger
if (process.env.NODE_ENV === `development`) {
  const logger = createLogger({
    duration: true,
  })

  middlewares.push(logger)
}

// Get state from local storage if it's there
const persistedState = loadState()

// Set up strings and localization
addLocaleData([
  ...en,
  ...es,
  ...fr,
  ...de,
  ...it,
  ...nl,
  ...ja,
  ...ru,
  ...pl,
  ...zh,
])

// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them
const language =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage

// Split locales with a region code
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0]

// Try full locale, try locale without region code, fallback to 'en'
const messages =
  strings[languageWithoutRegionCode] || strings[language] || strings.en

const history = createBrowserHistory()

const routingMiddleware = routerMiddleware(history)

// Set up store
const createStoreWithMiddleware = composeWithDevTools(
  applyMiddleware(
    reduxThunk.withExtraArgument({ intl: getIntl, graphqlClient }),
    ...middlewares,
    routingMiddleware,
  ),
)(createStore)

export const store = createStoreWithMiddleware(reducers, persistedState)

store.subscribe(() => {
  saveState(store.getState())
})

store.dispatch(applicationStart())

// Put locale info into Redux store
store.dispatch({
  type: SET_LOCALE,
  payload: {
    locale: language,
    messages: messages,
  },
})

const rootEl = document.getElementById('root')


const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTAG_TRACKING_ID
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale={language} messages={messages}>
      <MuiThemeProvider theme={theme}>
        <App history={history} />
      </MuiThemeProvider>
    </IntlProvider>
  </Provider>,
  rootEl,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
