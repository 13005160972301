import DOMPurify from 'dompurify'
import ReactGA from 'react-ga'
import { push } from 'react-router-redux'
import {
  ADD_CARD,
  ADD_CHAPTER,
  DONE_EDITING,
  DELETE_CARD,
  DELETE_CHAPTER,
  EDIT_CARD,
  UPDATE_CARD_SEQUENCES,
  UPDATE_CHAPTER_SEQUENCES,
  UPDATE_CARD,
  UPDATE_CHAPTER,
  SELECTED_CHAPTER,
  SET_PLAN,
} from './types'
import Query from '../graphql'
import { handleErrors } from '../utils/helpers'
import { normalizeChapter, normalizePlan } from '../utils/normalizers'
import { showFlashMessage } from './flash.actions'

export function chapterOverviewClick(id) {
  return function (dispatch) {
    dispatch(selectChapter(id))

    dispatch(push('/dashboard/plan'))
  }
}

// ***************************
//  Query action creators
// ***************************

export const getPlan = (planId, companyId) => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { getPlan },
      } = await graphqlClient.query({
        query: Query.getPlanQuery,
        fetchPolicy: 'network-only',
        variables: {
          id: planId,
          company: companyId,
        },
      })

      if (getPlan) {
        const normalizedPlan = normalizePlan(getPlan)

        dispatch({
          type: SET_PLAN,
          payload: normalizedPlan,
        })
      }

      ReactGA.event({
        category: 'Plan',
        action: 'Load',
      })
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}
// ***************************
//  Card action creators
// ***************************

export const createCard = (chapterId, sequence, company) => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { createCard },
      } = await graphqlClient.mutate({
        mutation: Query.createCardMutation,
        variables: {
          chapterId,
          userId: getState().user.account._id,
          sequence,
          company: company.id,
        },
      })

      if (createCard) {
        dispatch({
          type: ADD_CARD,
          payload: {
            card: createCard,
            chapterId,
          },
        })
      }

      ReactGA.event({
        category: 'Plan',
        action: 'Create',
        label: 'Section',
      })
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const updateCard = (card, company) => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    const { id, title, content, sequence, completed } = card

    try {
      const {
        data: { updateCard },
      } = await graphqlClient.mutate({
        mutation: Query.updateCardMutation,
        variables: {
          id,
          title,
          content: DOMPurify.sanitize(content),
          sequence,
          company: company.id,
          completed,
        },
      })

      if (updateCard) {
        dispatch({
          type: UPDATE_CARD,
          payload: updateCard,
        })
      }

      ReactGA.event({
        category: 'Plan',
        action: 'Update',
        label: 'Section',
      })
      resolve(updateCard)
    } catch (errors) {
      // console.log('errors')
      reject(handleErrors(errors))
    }
  })
}

export const deleteCard = (card, chapterId, company) => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { deleteCard },
      } = await graphqlClient.mutate({
        mutation: Query.deleteCardMutation,
        variables: {
          id: card.id,
          company: company.id,
        },
      })

      if (deleteCard) {
        dispatch({
          type: DELETE_CARD,
          payload: {
            card,
            chapterId,
          },
        })

        ReactGA.event({
          category: 'Plan',
          action: 'Delete',
          label: 'Section',
        })

        dispatch(push('/dashboard/plan'))
      }
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const getCard = (cardId, companyId) => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { getCard },
      } = await graphqlClient.query({
        query: Query.getCardQuery,
        fetchPolicy: 'network-only',
        variables: {
          id: cardId,
          company: companyId,
        },
      })

      if (getCard) {
        dispatch({
          type: UPDATE_CARD,
          payload: getCard,
        })
      }

      resolve(getCard)
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export function editCard(id) {
  return function (dispatch) {
    ReactGA.event({
      category: 'Plan',
      action: 'Open',
      label: 'Editor',
    })

    dispatch({
      type: EDIT_CARD,
      payload: {
        id,
      },
    })

    dispatch(push(`/dashboard/plan/edit/${id}`))
  }
}

// ***************************
//  Chapter action creators
// ***************************

export const createChapter = (companyId, sequence) => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { createChapter },
      } = await graphqlClient.mutate({
        mutation: Query.createChapterMutation,
        variables: {
          companyId,
          userId: getState().user.account._id,
          sequence,
        },
      })

      if (createChapter) {
        dispatch({
          type: ADD_CHAPTER,
          payload: {
            chapter: createChapter,
          },
        })

        dispatch(selectChapter(createChapter._id))
      }

      ReactGA.event({
        category: 'Plan',
        action: 'Create',
        label: 'Chapter',
      })
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const updateCardSequence = (chapter, cards, company) => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {

    try {
      const {
        data: { updateCardSequence },
      } = await graphqlClient.mutate({
        mutation: Query.updateCardSequenceMutation,
        variables: {
          chapter,
          cards,
          company,
        },
      })

      if (updateCardSequence) {
        dispatch({
          type: UPDATE_CARD_SEQUENCES,
          payload: updateCardSequence,
        })
      }

      ReactGA.event({
        category: 'Plan',
        action: 'Update',
        label: 'Chapter section order',
      })
      resolve(updateCardSequence)
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const updateChapterSequence = (plan, chapters, company) => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {

    try {
      const {
        data: { updateChapterSequence },
      } = await graphqlClient.mutate({
        mutation: Query.updateChapterSequenceMutation,
        variables: {
          plan,
          chapters,
          company,
        },
      })

      if (updateChapterSequence) {
        dispatch({
          type: UPDATE_CHAPTER_SEQUENCES,
          payload: updateChapterSequence,
        })
      }

      ReactGA.event({
        category: 'Plan',
        action: 'Update',
        label: 'Chapter order',
      })
      resolve(updateChapterSequence)
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const updateChapter = (chapter, company) => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    const { id, title, sequence } = chapter

    try {
      const {
        data: { updateChapter },
      } = await graphqlClient.mutate({
        mutation: Query.updateChapterMutation,
        variables: {
          id,
          title,
          sequence,
          company: company.id
        },
      })

      const update = normalizeChapter(updateChapter)

      if (updateChapter) {
        dispatch({
          type: UPDATE_CHAPTER,
          payload: update,
        })
      }

      ReactGA.event({
        category: 'Plan',
        action: 'Update',
        label: 'Chapter',
      })
      resolve(update)
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export const deleteChapter = (chapterId, companyId) => (
  dispatch,
  getState,
  { graphqlClient },
) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { deleteChapter },
      } = await graphqlClient.mutate({
        mutation: Query.deleteChapterMutation,
        variables: {
          id: chapterId,
          company: companyId
        },
      })

      if (deleteChapter) {
        dispatch({
          type: DELETE_CHAPTER,
          payload: {
            chapterId,
          },
        })

        dispatch(push('/dashboard/plan'))
        dispatch(showFlashMessage('Chapter deleted successfully', 'success'))
      }

      ReactGA.event({
        category: 'Plan',
        action: 'Delete',
        label: 'Chapter',
      })
    } catch (errors) {
      reject(handleErrors(errors))
    }
  })
}

export function selectChapter(value) {
  ReactGA.event({
    category: 'Plan',
    action: 'Select',
    label: 'Chapter',
  })

  return {
    type: SELECTED_CHAPTER,
    payload: value,
  }
}

export function closeEditor() {
  return function (dispatch) {
    ReactGA.event({
      category: 'Plan',
      action: 'Close',
      label: 'Editor',
    })

    dispatch({
      type: DONE_EDITING,
    })

    dispatch(push(`/dashboard/plan`))
  }
}
