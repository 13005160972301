import React from 'react'
import Spinner from 'react-spinkit'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  loader: {
    left: '50%',
    position: 'absolute',
    top: '50%',
    zIndex: 99999,
  },
  small: {
    width: 30,
    height: 30,
  },
  staticLoader: {
    margin: '1rem auto',
  },
})

const LoadingSpinner = props => {
  const { theme, classes, position, size } = props
  const positionClass =
    position === 'static' ? classes.staticLoader : classes.loader
  const sizeClass = size === 'small' ? classes.small : ''

  return (
    <Spinner
      className={classnames(positionClass, sizeClass)}
      color={theme.palette.primary.main}
      fadeIn="quarter"
      name="folding-cube"
    />
  )
}

const Loading = withStyles(styles, { withTheme: true })(LoadingSpinner)

export const ReactTableLoader = ({ loading }) => {
  return loading ? (
    <div className="-loading -active">
      <div className="-loading-inner">
        <Loading />
      </div>
    </div>
  ) : null
}

export default Loading
