import {
  CLOSE_FLASH_MESSAGE,
  CLOSE_INFORMATION_DRAWER,
  OPEN_FLASH_MESSAGE,
  SET_INFORMATION_DRAWER,
  SET_COMPANY,
  SET_ORG,
} from '../actions/types'
import { ENTITY_TYPES } from '../types'

const defaultState = {
  settings: {}
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case SET_COMPANY:
      return {
        ...state,
        currentEntityType: ENTITY_TYPES.COMPANY,
      }
    case SET_ORG:
      return {
        ...state,
        currentEntityType: ENTITY_TYPES.ORG,
      }
    case SET_INFORMATION_DRAWER:
      return {
        ...state,
        isInfoDrawerOpen: true,
        infoDrawerTitle: action.payload.title,
        infoDrawerInfoText: action.payload.infoText,
      }
    case CLOSE_INFORMATION_DRAWER:
      return {
        ...state,
        isInfoDrawerOpen: false,
      }
    case OPEN_FLASH_MESSAGE:
      return {
        ...state,
        openFlash: true,
        flashMessage: action.payload.message,
        flashVariant: action.payload.variant,
      }
    case CLOSE_FLASH_MESSAGE:
      return {
        ...state,
        openFlash: false,
      }
    default:
      return state
  }
}
