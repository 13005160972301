import { defineMessages } from 'react-intl'

const taxes = defineMessages({
  corporate: {
    id: 'taxes.corporate',
    defaultMessage: 'Corporate'
  },
  sales: {
    id: 'taxes.sales',
    defaultMessage: 'Sales'
  },
  corporateTaxRateLabel: {
    id: 'taxes.corporateTaxRateLabel',
    defaultMessage: 'What is your estimated tax rate?'
  },
  corporatePaymentFrequencyLabel: {
    id: 'taxes.corporatePaymentFrequencyLabel',
    defaultMessage: 'How often will you pay your taxes?'
  },
  taxRevenueStreamSalesTaxLabel: {
    id: 'taxes.taxRevenueStreamSalesTaxLabel',
    defaultMessage: 'Which revenue streams have sales tax?'
  },
  salesTaxRateLabel: {
    id: 'taxes.salesTaxRateLabel',
    defaultMessage: 'What is your estimated tax rate?'
  },
  salesPaymentFrequencyLabel: {
    id: 'taxes.salesPaymentFrequencyLabel',
    defaultMessage: 'How often will you pay your taxes?'
  },
})

export default taxes
