import ReconnectingWebSocket from 'reconnecting-websocket'
import { store } from '../index'
import { resourceLockListUpdated } from '../actions/lock.actions';
import { getCard } from '../actions/plan.actions';
import { getCanvasBlock } from '../actions/canvas.actions';
import { CANVAS_BLOCKS } from '../types';

let LPWSService = null

class LaunchPlanWebSocket {
  constructor() {
    this.socket = null
    this.messageListeners = []
    this.isOpen = false
    this.company = null
    this.user = null
    this.resource = null
  }

  static initWSService(options) {
    if (!LPWSService) {
      LPWSService = new LaunchPlanWebSocket()
      LPWSService.initSocket(options)
      return LPWSService
    }

    return LPWSService
  }

  initSocket = (options) => {
    if (options) {
      const { companyId, userId, resourceId } = options
      this.company = companyId
      this.user = userId
      this.resource = resourceId
    }

    this.socket = new ReconnectingWebSocket(process.env.REACT_APP_WEBSOCKET_HOST)
    this.socket.onopen = this.onConnectionOpen
    this.socket.onmessage = this.onMessage
    this.socket.onclose = this.onConnectionClose
  }

  /**
 *  Show connection status to user
 */
  onConnectionOpen = () => {
    this.isOpen = true
    // console.log('Websocket connected!')
    this.joinCompany(this.company, this.user, this.resource)
  }

  /**
   *  Log lost connection for now
   */
  onConnectionClose = () => {
    // console.log('Websocket closed!')
  }

  /**
   *  Used by application to send message to the WebSocket API Gateway
   *  @param routeKey The route key for WebSocket API Gateway
   *  @param message String message
   *  message {
   *    room,
   *    type,
   *    msg,
   *    username,
   *    for
   *  }
   */
  sendMessage = (routeKey, message) => {
    if (this.socket && this.isOpen) {
      // console.log('Websocket is sending ', routeKey, message)
      this.socket.send(JSON.stringify({
        action: routeKey,
        message: JSON.stringify(message)
      }))
    } else {
      // console.log(`Websocket connection not found!!`)
    }
  }

  joinCompany = (companyId, userId, resourceId) => {
    if (companyId && userId) {
      this.sendMessage('changeCompany', {
        companyId,
        userId,
        resourceId,
      })
    }
  }

  /**
   *  Used by application to register different listeners for 
   *  different message types [To be used later]
   *  @param room Room name
   *  @param type Message type ['all', 'pm']
   *  @param listener Function to handle message type
   */
  addMessageListener = (room, type, listener) => {
    if (!type || !room || typeof listener !== 'function') {
      return
    }
    this.messageListeners.push({
      room,
      type,
      listener
    })
  }

  /**
   * Handler that receives the actual messages from the WebSocket API
   * For now it simply returns the parsed message body
   * @param data Message body received from WebSocket 
   */
  onMessage = (data) => {
    // console.log('onMessage hit', data)
    if (data) {
      const message = JSON.parse(data.data)
      // const typeListener = this.messageListeners.find(listener => listener.type === message.type)

      // console.log(`Message Received on websocket: `, message)

      if (message.message === "Internal server error") {
        return
      }

      const prevLocks = store.getState().socket.resourceLocks
      const user = store.getState().user.account

      const delta = prevLocks.filter(prevLock => !message.some(lock => lock.resourceId === prevLock.resourceId || prevLock.userId === user.id))

      if (delta.length) delta.forEach(item => {
        if (CANVAS_BLOCKS.includes(item.resourceId)) {
          const canvasId = store.getState().canvas._id
          store.dispatch(getCanvasBlock(item.resourceId, canvasId))
        } else {
          store.dispatch(getCard(item.resourceId, item.companyId))
        }
      })

      // console.log('resourceLockListUpdated', message)

      store.dispatch(resourceLockListUpdated(message))

      // if (typeListener && typeof typeListener.listener === "function") {
      //   typeListener.listener(message)
      // } else {
      // console.log('No handler found for message type')
      // }
    }
  }
}

export const getWebSocket = LaunchPlanWebSocket.initWSService