import { defineMessages } from 'react-intl'

const FinancialsHelp = defineMessages({
  revenueHelp: {
    id: 'fh.revenue',
    defaultMessage: `Organize your sources of revenue here. Include major products, services, or other sources of revenue and put them into clear groups. If you are running a men’s retail store for example, you could create groups for footwear, casualwear, formalwear, and outerwear.

    Short and sweet is the name of the game. Keep your list concise to avoid a traffic jam of revenue streams that could make your forecast hard to prepare and maintain. Keep in mind that you can compare forecasted values with actual results later on, so make sure your revenue categories are groups you will be comfortable working with later on.
    
    Remember that grants, donations, crowdfunding, and other money that doesn’t involve equity or payback should be added here as well.`
  },
  costsHelp: {
    id: 'fh.costs',
    defaultMessage: `How much does selling actually cost you? Here is where you look at your company’s direct costs, or the costs incurred every time you make sale. Direct costs include the raw materials that go into making your product, wholesale purchases of products you are reselling, and sales commissions. Labor can also be considered a direct cost. 

    Direct costs can be tallied as flat amounts or as a percentage of your revenue, so that they scale evenly as you grow. 
    
    The whole point of separating these direct costs from other expenses is to understand your gross margin, or the portion of revenue that is left over after paying your direct costs, but not your other expenses. From there, it’s much easier to see how much potential profit your company has to work with.`
  },
  expensesHelp: {
    id: 'fh.expenses',
    defaultMessage: `Where are you spending your money? From rent and utilities, to marketing costs and office supplies, lay out all of your company’s regular expenses here. If your company is just getting going, make sure to note any one-time or short-term startup expenses as you kick into gear. 

    There are a few special expenses that don’t need to be included in the expenses section, as they are covered elsewhere in the forecast:
    
    -Loan payments. We calculate these for you in the financing step.
    -Payroll costs or employee benefits. Those are included in the personnel step.
    -Raw materials, sales commissions, or wholesale purchases. Those go in direct costs.
    -Inventory purchases or tax payments. We calculate those for you in the assumptions step.
    -Dividends or distributions. Those go in dividends.
    -Depreciation and amortization. Those are covered in the assets step.
    -Capital equipment, vehicles, or other major purchases. Those go in assets.`
  },
  taxHelp: {
    id: 'fh.taxes',
    defaultMessage: `Taxes can be scary, but don’t let them ruin the party. In the planning stages of your business, taxes are theoretical expenses based on theoretical profits. Keep things simple and set standard rates to make sure your forecast includes basic tax coverage. `
  },
  corporateTaxHelp: {
    id: 'fh.corporateTaxHelp',
    defaultMessage: `When your company turns a profit in a given year, you will have to pay a variety of taxes on that profit. Establish an overall tax rate here to include in your financial plan. This rate will be an estimation that covers all applicable income taxes, including federal, state, local, and so on. If you’re not quite positive on your tax rate, a 20% rate is typically a good place to start. These taxes generally only apply when you are profitable, but unprofitable years may still have a tax burden for any profitable quarters (though the year should still end up with zero taxes).
    
    Remember that this rate is only for income taxes, not for payroll, social welfare, and other employee-related taxes (those will be covered in the personnel section). For other taxes, like property tax, add as regular expenses.`
  },
  salesTaxHelp: {
    id: 'fh.salesTaxHelp',
    defaultMessage: `If you have to collect sales tax from your customers, a piece or all of your revenue streams could be subject to a national general sales tax (GST), value-added tax (VAT), or additional state or local sales taxes. When this applies to you, fill in the details below.

    Since you are required to pay the collected taxes to the government on a set schedule, collecting sales tax will not affect your overall profitability. It will, however, affect your cash flow projections during the period in between when you receive revenue and actually pay the government. Because of this, it’s really important to not confuse that temporary tax money with readily available capital.
    `
  },
  employeesHelp: {
    id: 'fh.employees',
    defaultMessage: `Who do you need to get the job done? Here is where you address the salaries and related costs paid to yourself, your employees, and any contract workers. This can be done listing every employee by name or title, or grouped into common roles or departments depending on how large your company is.

    This is a really good chance to consider your staffing needs—now and in the future.  How much are you paying these employees? When do you need to add new positions? Does it make more sense to hire full-time or use contract employees?
    If your company has no paid employees (all-volunteer non-profit groups, for instance), just skip this step.`
  },
  startingBalancesHelp: {
    id: 'fh.startingBalance',
    defaultMessage: `Whether your company is just starting out, or turning the page into a new fiscal year, your starting balance is the amount of funds in your account at the start of a new financial period.

    This will be the first entry in your accounts, and is an important tool for projecting and monitoring cash flow as well as any other sort of financial forecasting.`
  },
  loanHelp: {
    id: 'fh.loans',
    defaultMessage: `Adding long and short-term loans to your forecast may seem tricky, but we make it easy by automatically calculating your payments and updating your forecast appropriately. Just tell us what you know about each of your funding sources, and let us do the rest. 

    Make sure to include everything loan your company has taken out, including for assets like company cars and equipment.`
  },
  investmentsHelp: {
    id: 'fh.investments',
    defaultMessage: `Just like with your loans, indicate each investment—whether it be outside investments in exchange for equity or your own personal investments into the company—here, and let us adjust your forecast for you.`
  },
  assetsHelp: {
    id: 'fh.assets',
    defaultMessage: `Considered long-lasting purchases, assets are treated differently than regular expenses. Purchases like a company van or an industrial printer are considered assets, while the gas used to power the van and the ink cartridges utilized by the printer are considered regular expenses. These assets are expensive purchases, and, if treated as a one-time expense, could be big hits on a company’s profitability. Then again, they will be around a whole lot longer than a single financial period. Spreading that cost out over time is the idea behind asset purchases.

    Financial statements handle an asset by recording its full value on the balance sheet, then calculating the amount of value it will lose each month over its lifespan. From there, statements expense that asset depreciation each month until the full value of the asset has been exhausted.
    
    This section covers purchases like equipment, intellectual property, and vehicles, but assets like cash and inventory are addressed separately in our forecast.
    
    If you are having trouble thinking of company assets, skip this step.`
  },
  linesOfCreditHelp: {
    id: 'fh.linesOfCredit',
    defaultMessage: `Use this section to add lines of credit or credit cards. The rules are the same: You have a credit limit and can draw money against the limit as you need it. Each payment is applied to any interest charge first, and then on to the outstanding amount that your company owes.`
  },
  dividendsHelp: {
    id: 'fh.dividends',
    defaultMessage: `Here is where you organize the distribution of your profits to shareholders and investors. Who are these shareholders and how much money are they getting? By keeping this information organized, you can organize operating budgets and plan your company’s next move.`
  },
  assumptionsHelp: {
    id: 'fh.assumptions',
    defaultMessage: `It’s essential to highlight assumptions in your financial plan. Assumptions can include anything from your sale price, to cost per unit, to other important expenses and assets.

    Use your judgement when deciding what assumptions are central to your business. A good rule of thumb: If a significant percentage of your expenses are from one thing, explain it—that’s probably important. If something is just making up a small part of your overall income, skip it.
    
    Your marketing plan might include social media or blogging, but if it’s not a key part of your business plan, don’t waste time discussing it. But if your business model is to sell or make your product for less than your consumer, that is an important thing to discuss up front. 
    
    Remember, these are the essential points that will help potential investors with a bigger picture of what you are all about.`
  },
})

export default FinancialsHelp