import { defineMessages } from 'react-intl'

const plan = defineMessages({
  businessPlan: {
    id: 'plan.businessPlan',
    defaultMessage: 'Business Plan',
  },
  exportPlan: {
    id: 'plan.exportPlan',
    defaultMessage: 'Export plan',
  },
  exportCompanyBusinessPlan: {
    id: 'plan.exportCompanyBusinessPlan',
    defaultMessage: `Export {company}'s Business Plan`,
  },
  exportBusinessPlanDescription: {
    id: 'plan.exportBusinessPlanDescription',
    defaultMessage: `Choose what sections you would like included in your business plan. Sections that haven't been started yet are disabled.`,
  },
  viewPlan: {
    id: 'plan.viewPlan',
    defaultMessage: 'View plan',
  },
  exportPDF: {
    id: 'plan.exportPDF',
    defaultMessage: 'Export PDF',
  },
  notStartedYet: {
    id: 'plan.notStartedYet',
    defaultMessage: `You haven't started this section yet.`,
  },
  thisSectionNotStarted: {
    id: 'plan.thisSectionNotStarted',
    defaultMessage: `This section hasn't been completed yet.`,
  },
  addSection: {
    id: 'plan.addSection',
    defaultMessage: 'Add section',
  },
  companyDescription: {
    id: 'plan.companyDescription',
    defaultMessage: `Tell the world what you're here to do and lay out the long-terms of your business.`,
  },
  executiveSummaryDescription: {
    id: 'plan.executiveSummaryDescription',
    defaultMessage: `Highlight your business plan's essential points, minus the nitty gritty.`,
  },
  marketingSalesDescription: {
    id: 'plan.marketingSalesDescription',
    defaultMessage:
      'Show what you know when it comes to your business landscape.',
  },
  operationsDescription: {
    id: 'plan.operationsDescription',
    defaultMessage:
      'Help your investors see what the operations of your business look like.',
  },
  financialsDescription: {
    id: 'plan.financialsDescription',
    defaultMessage:
      'The essentials of your company can guarantee investors, vendors, and stockholders.',
  },
  sectionNotStarted: {
    id: 'plan.sectionNotStarted',
    defaultMessage: `This section isn't started yet.`,
  },
  blastOffStartNow: {
    id: 'plan.blastOffStartNow',
    defaultMessage: `Blast off and start now`,
  },
  deleteCard: {
    id: 'plan.deleteCard',
    defaultMessage: 'Delete card',
  },
  deleteCardConfirmation: {
    id: 'plan.deleteCardConfirmation',
    defaultMessage: 'Are you sure you want to delete this card? This action can not be undone.',
  },
  deleteChapterConfirmation: {
    id: 'plan.deleteChapterConfirmation',
    defaultMessage: 'Are you sure you want to delete this entire plan chapter? This action can not be undone.',
  },
  isCompleted: {
    id: 'plan.isCompleted',
    defaultMessage: 'Completed?',
  },
  completed: {
    id: 'plan.completed',
    defaultMessage: 'Completed!',
  },
  currentSelectedChapterHelp: {
    id: 'plan.currentSelectedChapterHelp',
    defaultMessage: 'The current selected chapter of the business plan',
  },
  planSectionLocked: {
    id: 'plan.planSectionLocked',
    defaultMessage: 'Another team member is editing this section',
  },
  planPlaceholder: {
    id: 'plan.planPlaceholder',
    defaultMessage: 'Enter your plan here...',
  },
  addChapter: {
    id: 'plan.addChapter',
    defaultMessage: 'Add chapter',
  },
  deleteChapter: {
    id: 'plan.deleteChapter',
    defaultMessage: 'Delete chapter',
  },
  noPDFToDisplay: {
    id: 'plan.noPDFToDisplay',
    defaultMessage: 'Your latest export will be displayed here. Exports are available for 24 hours.',
  },
  yourLatestExport: {
    id: 'plan.yourLatestExport',
    defaultMessage: 'Your Latest Export',
  },
  viewRecentExports: {
    id: 'plan.viewRecentExports',
    defaultMessage: 'View Recent Exports',
  },
  expired: {
    id: 'plan.expired',
    defaultMessage: 'Expired',
  },
  backToExport: {
    id: 'plan.backToExport',
    defaultMessage: 'Back to Export',
  },
  businessMdoelCanvasDescription: {
    id: 'plan.businessMdoelCanvasDescription',
    defaultMessage: 'A business model describes the rationale of how an organization creates, delivers, and captures value.',
  },
  keyPartners: {
    id: 'plan.keyPartners',
    defaultMessage: 'Key Partners',
  },
  keyActivities: {
    id: 'plan.keyActivities',
    defaultMessage: 'Key Activities',
  },
  keyResources: {
    id: 'plan.keyResources',
    defaultMessage: 'Key Resources',
  },
  valuePropositions: {
    id: 'plan.valuePropositions',
    defaultMessage: 'Value Propositions',
  },
  customerRelationships: {
    id: 'plan.customerRelationships',
    defaultMessage: 'Customer Relationships',
  },
  channels: {
    id: 'plan.channels',
    defaultMessage: 'Channels',
  },
  customerSegments: {
    id: 'plan.customerSegments',
    defaultMessage: 'Customer Segments',
  },
  costStructure: {
    id: 'plan.costStructure',
    defaultMessage: 'Cost Structure',
  },
  revenueStreams: {
    id: 'plan.revenueStreams',
    defaultMessage: 'Revenue Streams',
  },
  keyPartnersDescription: {
    id: 'plan.keyPartnersDescription',
    defaultMessage: `The Key Partnerships Building Block describes
    the network of suppliers and partners that make
    the business model work.

    Companies forge partnerships for many reasons, and partnerships
    are becoming a cornerstone of many business models. Companies
    create alliances to optimize their business models, reduce risk, or
    acquire resources. `,
  },
  keyActivitiesDescription: {
    id: 'plan.keyActivitiesDescription',
    defaultMessage: `The Key Activities Building Block describes
    the most important things a company must do
    to make its business model work.

    Every business model calls for a number of Key Activities. These
    are the most important actions a company must take to operate
    successfully. Like Key Resources, they are required to create and
    oΩer a Value Proposition, reach markets, maintain Customer
    Relationships, and earn revenues. And like Key Resources, Key
    Activities differ depending on business model type. For software
    maker Microsoft, Key Activities include software development. `,
  },
  keyResourcesDescription: {
    id: 'plan.keyResourcesDescription',
    defaultMessage: `The Key Resources Building Block describes
    the most important assets required to make a
    business model work.

    Every business model requires Key Resources. These resources
    allow an enterprise to create and oΩer a Value Proposition, reach
    markets, maintain relationships with Customer Segments, and
    earn revenues. Different Key Resources are needed depending on
    the type of business model. A microchip manufacturer requires
    capital-intensive production facilities, whereas a microchip designer
    focuses more on human resources.

     Key resources can be physical, financial, intellectual, or human.
    Key resources can be owned or leased by the company or acquired
    from key partners.`,
  },
  valuePropositionsDescription: {
    id: 'plan.valuePropositionsDescription',
    defaultMessage: `The Value Propositions Building Block describes
    the bundle of products and services that create
    value for a specific Customer Segment.

    The Value Proposition is the reason why customers turn to one
    company over another. It solves a customer problem or satisfies
    a customer need. Each Value Proposition consists of a selected
    bundle of products and/or services that caters to the requirements
    of a specific Customer Segment. In this sense, the Value Proposition is an aggregation, or bundle, of benefits that a company
    offers customers.

     Some Value Propositions may be innovative and represent a
    new or disruptive offer. Others may be similar to existing market
    offers, but with added features and attributes.`,
  },
  customerRelationshipsDescription: {
    id: 'plan.customerRelationshipsDescription',
    defaultMessage: `The Customer Relationships Building Block
    describes the types of relationships a company
    establishes with specific Customer Segments.

    A company should clarify the type of relationship it wants to
    establish with each Customer Segment. Relationships can range
    from personal to automated. Customer relationships may be
    driven by the following motivations:
    • Customer acquisition
    • Customer retention
    • Boosting sales (upselling)

    In the early days, for example, mobile network operator Customer
    Relationships were driven by aggressive acquisition strategies
    involving free mobile phones. When the market became saturated,
    operators switched to focusing on customer retention and increasing average revenue per customer.
    The Customer Relationships called for by a company’s business
    model deeply infl uence the overall customer experience.`,
  },
  channelsDescription: {
    id: 'plan.channelsDescription',
    defaultMessage: `The Channels Building Block describes how a
    company communicates with and reaches its
    Customer Segments to deliver a Value Proposition.

    Communication, distribution, and sales Channels comprise a
    company's interface with customers. Channels are customer touch
    points that play an important role in the customer experience.

    Channels serve several functions, including:
    • Raising awareness among customers about a company’s
     products and services
    • Helping customers evaluate a company’s Value Proposition
    • Allowing customers to purchase specific products and services
    • Delivering a Value Proposition to customers
    • Providing post-purchase customer support `,
  },
  customerSegmentsDescription: {
    id: 'plan.customerSegmentsDescription',
    defaultMessage: `The Customer Segments Building Block defines
    the different groups of people or organizations an
    enterprise aims to reach and serve.

    Customers comprise the heart of any business model. Without
    (profitable) customers, no company can survive for long. In order
    to better satisfy customers, a company may group them into
    distinct segments with common needs, common behaviors,
    or other attributes. A business model may define one or several
    large or small Customer Segments. An organization must make
    a conscious decision about which segments to serve and which
    segments to ignore. Once this decision is made, a business model
    can be carefully designed around a strong understanding of
    specific customer needs.`,
  },
  costStructureDescription: {
    id: 'plan.costStructureDescription',
    defaultMessage: `The Cost Structure describes all costs incurred to
    operate a business model.

    This building block describes the most important costs incurred
    while operating under a particular business model. Creating and delivering value, maintaining Customer Relationships, and generating
    revenue all incur costs. Such costs can be calculated relatively easily
    after defining Key Resources, Key Activities, and Key Partnerships.
    Some business models, though, are more cost-driven than others.
    So-called “no frills” airlines, for instance, have built business models
    entirely around low Cost Structures.`,
  },
  revenueStreamsDescription: {
    id: 'plan.revenueStreamsDescription',
    defaultMessage: `The Revenue Streams Building Block represents
    the cash a company generates from each Customer
    Segment (costs must be subtracted from revenues to
    create earnings).

    If customers comprise the heart of a business model, Revenue
    Streams are its arteries. A company must ask itself, For what value
    is each Customer Segment truly willing to pay? Successfully
    answering that question allows the firm to generate one or more
    Revenue Streams from each Customer Segment. Each Revenue
    Stream may have different pricing mechanisms, such as fixed list
    prices, bargaining, auctioning, market dependent, volume dependent, or yield management. `,
  },
  keyPartnersQuestion1: {
    id: 'plan.keyPartnersQuestion1',
    defaultMessage: 'Who are our Key Partners? ',
  },
  keyPartnersQuestion2: {
    id: 'plan.keyPartnersQuestion2',
    defaultMessage: 'Who are our key suppliers? ',
  },
  keyPartnersQuestion3: {
    id: 'plan.keyPartnersQuestion3',
    defaultMessage: 'Which Key Resources are we acquiring from partners? ',
  },
  keyPartnersQuestion4: {
    id: 'plan.keyPartnersQuestion4',
    defaultMessage: 'Which Key Activities do partners perform? ',
  },
  keyActivitiesQuestion1: {
    id: 'plan.keyActivitiesQuestion1',
    defaultMessage: 'What Key Activities do our value propositions require? ',
  },
  keyActivitiesQuestion2: {
    id: 'plan.keyActivitiesQuestion2',
    defaultMessage: 'Our Distribution Channels? ',
  },
  keyActivitiesQuestion3: {
    id: 'plan.keyActivitiesQuestion3',
    defaultMessage: 'Customer Relationships? ',
  },
  keyActivitiesQuestion4: {
    id: 'plan.keyActivitiesQuestion4',
    defaultMessage: 'Revenue Streams? ',
  },
  keyResourcesQuestion1: {
    id: 'plan.keyResourcesQuestion1',
    defaultMessage: 'What Key Resources do our Value Propositions require? ',
  },
  keyResourcesQuestion2: {
    id: 'plan.keyResourcesQuestion2',
    defaultMessage: 'Our Distribution Channels? ',
  },
  keyResourcesQuestion3: {
    id: 'plan.keyResourcesQuestion3',
    defaultMessage: 'Customer Relationships? ',
  },
  keyResourcesQuestion4: {
    id: 'plan.keyResourcesQuestion4',
    defaultMessage: 'Revenue Streams? ',
  },
  valuePropositionsQuestion1: {
    id: 'plan.valuePropositionsQuestion1',
    defaultMessage: 'What value do we deliver to the customer?',
  },
  valuePropositionsQuestion2: {
    id: 'plan.valuePropositionsQuestion2',
    defaultMessage: 'Which one of our customer’s problems are we helping to solve?',
  },
  valuePropositionsQuestion3: {
    id: 'plan.valuePropositionsQuestion3',
    defaultMessage: 'Which customer needs are we satisfying?',
  },
  valuePropositionsQuestion4: {
    id: 'plan.valuePropositionsQuestion4',
    defaultMessage: 'What bundles of products and services are we offering to each Customer Segment?',
  },
  customerRelationshipsQuestion1: {
    id: 'plan.customerRelationshipsQuestion1',
    defaultMessage: 'What type of relationship are we establishing with our Customer Segments?',
  },
  customerRelationshipsQuestion2: {
    id: 'plan.customerRelationshipsQuestion2',
    defaultMessage: 'Which ones have we established?',
  },
  customerRelationshipsQuestion3: {
    id: 'plan.customerRelationshipsQuestion3',
    defaultMessage: 'How costly are they?',
  },
  customerRelationshipsQuestion4: {
    id: 'plan.customerRelationshipsQuestion4',
    defaultMessage: 'How are they integrated with the rest of our business model?',
  },
  channelsQuestion1: {
    id: 'plan.channelsQuestion1',
    defaultMessage: 'Through which Channels do our Customer Segments want to be reached?',
  },
  channelsQuestion2: {
    id: 'plan.channelsQuestion2',
    defaultMessage: 'How are we reaching them now?',
  },
  channelsQuestion3: {
    id: 'plan.channelsQuestion3',
    defaultMessage: 'How are our Channels integrated?',
  },
  channelsQuestion4: {
    id: 'plan.channelsQuestion4',
    defaultMessage: 'Which ones work best?',
  },
  channelsQuestion5: {
    id: 'plan.channelsQuestion5',
    defaultMessage: 'Which ones are most cost-efficient?',
  },
  channelsQuestion6: {
    id: 'plan.channelsQuestion6',
    defaultMessage: 'How are we integrating them with customer routines?',
  },
  customerSegmentsQuestion1: {
    id: 'plan.customerSegmentsQuestion1',
    defaultMessage: 'For whom are we creating value?',
  },
  customerSegmentsQuestion2: {
    id: 'plan.customerSegmentsQuestion2',
    defaultMessage: 'Who are our most important customers?',
  },
  costStructureQuestion1: {
    id: 'plan.costStructureQuestion1',
    defaultMessage: 'What are the most important costs inherent in our business model?',
  },
  costStructureQuestion2: {
    id: 'plan.costStructureQuestion2',
    defaultMessage: 'Which Key Resources are most expensive?',
  },
  costStructureQuestion3: {
    id: 'plan.costStructureQuestion3',
    defaultMessage: 'Which Key Activities are most expensive?',
  },
  revenueStreamsQuestion1: {
    id: 'plan.revenueStreamsQuestion1',
    defaultMessage: 'For what value are our customers really willing to pay?',
  },
  revenueStreamsQuestion2: {
    id: 'plan.revenueStreamsQuestion2',
    defaultMessage: 'For what do they currently pay?',
  },
  revenueStreamsQuestion3: {
    id: 'plan.revenueStreamsQuestion3',
    defaultMessage: 'How are they currently paying?',
  },
  revenueStreamsQuestion4: {
    id: 'plan.revenueStreamsQuestion4',
    defaultMessage: 'How would they prefer to pay?',
  },
  revenueStreamsQuestion5: {
    id: 'plan.revenueStreamsQuestion5',
    defaultMessage: 'How much does each Revenue Stream contribute to overall revenues?',
  },
})

export default plan
