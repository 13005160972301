import { omit } from 'lodash'
import {
  SET_DOCUMENT,
  SET_FINANCIALS,
  SET_FINANCIALS_ITEM,
  SET_CURRENT_WIZARD_ITEM,
  CLEAR_CURRENT_WIZARD_ITEM,
  DELETE_FINANCIALS_ITEM,
  SET_FINANCIALS_OBJECT,
  SET_FORECASTS,
  SET_TIME_PIVOT,
  CLEAR_COMPANY,
} from '../actions/types'
import { FREQUENCY_TYPES } from '../types'

const intialState = {
  document: 'pnl',
  timePivot: FREQUENCY_TYPES.MONTHLY,
}

export default function(state = intialState, action) {
  switch (action.type) {
    case SET_DOCUMENT:
      return {
        ...state,
        document: action.payload,
      }
    case SET_FINANCIALS:
      return {
        ...state,
        ...action.payload,
      }
    case SET_FORECASTS:
      return {
        ...state,
        forecasts: action.payload,
      }
    case SET_FINANCIALS_ITEM:
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          [action.payload.id]: action.payload,
        },
      }
    case SET_FINANCIALS_OBJECT:
      return {
        ...state,
        [action.name]: action.payload,
      }
    case DELETE_FINANCIALS_ITEM:
      return {
        ...state,
        [action.name]: deleteFinancialitem(state[action.name], action),
      }
    case SET_CURRENT_WIZARD_ITEM:
      return {
        ...state,
        selectedWizardItem: action.payload,
      }
    case CLEAR_CURRENT_WIZARD_ITEM:
      return {
        ...state,
        selectedWizardItem: null,
      }
    case SET_TIME_PIVOT:
      return {
        ...state,
        timePivot: action.payload,
      }
    case CLEAR_COMPANY:
      return intialState
    default:
      return state
  }
}

function deleteFinancialitem(state, action) {
  const {
    payload: { id },
  } = action

  const items = omit(state, id)

  return {
    ...items,
  }
}
