import { IntlProvider } from 'react-intl';

// Helper to get access to the intl context, this is so we can call formatMessage api from actions
let cachedIntl = null;
let prevLocale = '';

export const getIntl = ({intl: {locale, messages}}) => {
  if (!cachedIntl || locale !== prevLocale) {
    cachedIntl = new IntlProvider({ locale, messages }).getChildContext().intl;
    prevLocale = locale;
  }
  return cachedIntl;
}
