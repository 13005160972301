import gql from 'graphql-tag'

export const CardFragment = gql`
  fragment CardFragment on Card {
    id
    title
    content
    lastUpdated
    sequence
    stringId
    comments {
      comment
    }
    completed
  }
`

export const ChapterFragment = gql`
  fragment ChapterFragment on Chapter {
    _id
    id
    title
    sequence
    lastUpdated
    cards {
      ...CardFragment
    }
  }
  ${CardFragment}
`

export const PlanFragment = gql`
  fragment PlanFragment on Plan {
    _id
    id
    chapters {
      ...ChapterFragment
    }
  }
  ${ChapterFragment}
`

export const CanvasBlockFragment = gql`
  fragment CanvasBlockFragment on Block {
    _id
    id
    content
    lastUpdated
    completed
    key
    title
    sequence
  }
`

export const CanvasFragment = gql`
  fragment CanvasFragment on Canvas {
    _id
    id
    blocks {
      keyPartners {
        ...CanvasBlockFragment
      }
      keyActivities {
        ...CanvasBlockFragment
      }
      keyResources {
        ...CanvasBlockFragment
      }
      valuePropositions {
        ...CanvasBlockFragment
      }
      customerRelationships {
        ...CanvasBlockFragment
      }
      channels {
        ...CanvasBlockFragment
      }
      customerSegments {
        ...CanvasBlockFragment
      }
      costStructure {
        ...CanvasBlockFragment
      }
      revenueStreams {
        ...CanvasBlockFragment
      }
    }
    lastUpdated
    company {
      _id
      id
    }
  }
  ${CanvasBlockFragment}
`

export const AmountFragment = gql`
  fragment AmountFragment on Amount {
    _id
    id
    billingPeriod
    value
    frequency
    partType
    raises
    series
    start
    streamId
    type
  }
`

export const BaseFinancialItemFragment = gql`
  fragment BaseFinancialItemFragment on BaseFinancialItem {
    _id
    id
    name
    type
    amounts {
      ...AmountFragment
    }
  }
  ${AmountFragment}
`

export const EmployeeFragment = gql`
  fragment EmployeeFragment on Employee {
    _id
    id
    name
    type
    employeeCount
    status
    typeOfLabor
    amounts {
      ...AmountFragment
    }
  }
  ${AmountFragment}
`

export const LoanFragment = gql`
  fragment LoanFragment on Loan {
    _id
    id
    name
    start
    loanAmount
    loanAnnualInterestRate
    loanLength
  }
`

export const TaxFragment = gql`
  fragment TaxFragment on Tax {
    _id
    id
    corporateTaxRate
    salesTaxRate
    corporatePaymentFrequency
    salesPaymentFrequency
    revenues
  }
`

export const AssumptionsFragment = gql`
  fragment AssumptionsFragment on Assumptions {
    _id
    id
    percentSalesOnCredit
    percentPurchasesOnCredit
    accountsReceivableDaysOutstanding
    accountsPayableDaysOutstanding
    inventoryDaysOutstanding
    burdenRate
  }
`

export const StartingBalanceFragment = gql`
  fragment StartingBalanceFragment on StartingBalance {
    _id
    id
    cash
    accountsReceivable
    accountsReceivableDays
    inventory
    longTermAssets
    accumulatedDepreciation
    depreciationPeriod
    amortizationPeriod
    shortTermAssets
    accountsPayable
    accountsPayableDays
    corporateTaxesPayable
    salesTaxesPayable
    paidInCapital
  }
`

export const AssetFragment = gql`
  fragment AssetFragment on Asset {
    _id
    id
    name
    type
    shortTermUsefulLife
    longTermUsefulLife
    salvageValue
    resell
    resellPrice
    resellDate
    amounts {
      ...AmountFragment
    }
  }
  ${AmountFragment}
`

export const LineOfCreditFragment = gql`
  fragment LineOfCreditFragment on LineOfCredit {
    _id
    id
    name
    creditLimit
    creditAnnualInterestRate
    existingBalance
    amounts {
      ...AmountFragment
    }
  }
  ${AmountFragment}
`

export const ForecastPartFragment = gql`
  fragment ForecastPartFragment on ForecastPart {
    name
    values
    yearlyValues
    quarterlyValues
    startingBalance
  }
`

export const ForecastItemFragment = gql`
  fragment ForecastItemFragment on ForecastItem {
    name
    items {
      ...ForecastPartFragment
    }
    values
    yearlyValues
    quarterlyValues
    startingBalance
  }
  ${ForecastPartFragment}
`

export const ForecastFragment = gql`
  fragment ForecastFragment on Forecast {
    name
    items {
      ...ForecastItemFragment
    }
    values
    yearlyValues
    quarterlyValues
    startingBalance
  }
  ${ForecastItemFragment}
`

export const StatsFragment = gql`
  fragment StatsFragment on Stats {
    label
    stat1
    stat2
    stat3
    statLabel1
    statLabel2
    statLabel3
  }
`

export const ProfitLossStatementFragment = gql`
  fragment ProfitLossStatementFragment on ProfitLossStatement {
    revenues {
      ...ForecastItemFragment
    }
    directCosts {
      ...ForecastItemFragment
    }
    grossProfit {
      ...ForecastPartFragment
    }
    grossProfitPercentage {
      ...ForecastPartFragment
    }
    operatingExpenses {
      ...ForecastItemFragment
    }
    assetGainsLosses {
      ...ForecastPartFragment
    }
    ebitda {
      ...ForecastPartFragment
    }
    depreciationAndAmortization {
      ...ForecastPartFragment
    }
    interestExpense {
      ...ForecastPartFragment
    }
    incomeTaxes {
      ...ForecastPartFragment
    }
    totalExpenses {
      ...ForecastPartFragment
    }
    netProfit {
      ...ForecastPartFragment
    }
    netProfitPercentage {
      ...ForecastPartFragment
    }
    stats {
      ...StatsFragment
    }
  }
  ${ForecastItemFragment}
  ${ForecastPartFragment}
  ${StatsFragment}
`

export const BreakEvenAnalysisFragment = gql`
  fragment BreakEvenAnalysisFragment on BreakEvenAnalysis {
    revenue {
      ...ForecastPartFragment
    }
    costs {
      ...ForecastPartFragment
    }
    breakEvenPoint
  }
  ${ForecastPartFragment}
`

export const BalanceSheetFragment = gql`
  fragment BalanceSheetFragment on BalanceSheet {
    assets {
      ...ForecastFragment
    }
    liabilities {
      ...ForecastFragment
    }
    equity {
      ...ForecastItemFragment
    }
    liabilitiesAndEquity {
      ...ForecastItemFragment
    }
    stats {
      ...StatsFragment
    }
  }
  ${ForecastFragment}
  ${ForecastItemFragment}
  ${StatsFragment}
`

export const CashFlowStatementFragment = gql`
  fragment CashFlowStatementFragment on CashFlowStatement {
    operationsCashFlow {
      ...ForecastItemFragment
    }
    investingCashFlow {
      ...ForecastItemFragment
    }
    financingCashFlow {
      ...ForecastItemFragment
    }
    beginningCash {
      ...ForecastPartFragment
    }
    netChangeCash {
      ...ForecastPartFragment
    }
    endingCash {
      ...ForecastPartFragment
    }
    stats {
      ...StatsFragment
    }
  }
  ${ForecastItemFragment}
  ${ForecastPartFragment}
  ${StatsFragment}
`

export const FinancialForecastsFragment = gql`
  fragment FinancialForecastsFragment on FinancialForecasts {
    profitLossStatement {
      ...ProfitLossStatementFragment
    }
    balanceSheet {
      ...BalanceSheetFragment
    }
    cashFlowStatement {
      ...CashFlowStatementFragment
    }
    breakEvenAnalysis {
      ...BreakEvenAnalysisFragment
    }
    ebitda {
      ...ForecastPartFragment
    }
    netProfitMargin {
      ...ForecastPartFragment
    }
    grossProfitMargin {
      ...ForecastPartFragment
    }
    currentRatio {
      ...ForecastPartFragment
    }
    quickRatio {
      ...ForecastPartFragment
    }
  }
  ${ProfitLossStatementFragment}
  ${BalanceSheetFragment}
  ${CashFlowStatementFragment}
  ${BreakEvenAnalysisFragment}
  ${ForecastPartFragment}
`

export const FinancialsFragment = gql`
  fragment FinancialsFragment on Financials {
    _id
    id
    startingBalance {
      ...StartingBalanceFragment
    }
    taxes {
      ...TaxFragment
    }
    assumptions {
      ...AssumptionsFragment
    }
    revenues {
      ...BaseFinancialItemFragment
    }
    expenses {
      ...BaseFinancialItemFragment
    }
    costs {
      ...BaseFinancialItemFragment
    }
    employees {
      ...EmployeeFragment
    }
    loans {
      ...LoanFragment
    }
    assets {
      ...AssetFragment
    }
    linesOfCredit {
      ...LineOfCreditFragment
    }
    dividends {
      ...BaseFinancialItemFragment
    }
    investments {
      ...BaseFinancialItemFragment
    }
  }
  ${BaseFinancialItemFragment}
  ${EmployeeFragment}
  ${LoanFragment}
  ${AssetFragment}
  ${LineOfCreditFragment}
  ${StartingBalanceFragment}
  ${TaxFragment}
  ${AssumptionsFragment}
`

export const FinancialItemPayloadFragment = gql`
  fragment FinancialItemPayloadFragment on FinancialItemPayload {
    item {
      ...BaseFinancialItemFragment
    }
    financials {
      ...FinancialsFragment
    }
    forecasts {
      ...FinancialForecastsFragment
    }
  }
  ${BaseFinancialItemFragment}
  ${FinancialsFragment}
  ${FinancialForecastsFragment}
`

export const EmployeePayloadFragment = gql`
  fragment EmployeePayloadFragment on EmployeePayload {
    item {
      ...EmployeeFragment
    }
    financials {
      ...FinancialsFragment
    }
    forecasts {
      ...FinancialForecastsFragment
    }
  }
  ${EmployeeFragment}
  ${FinancialsFragment}
  ${FinancialForecastsFragment}
`

export const LoanPayloadFragment = gql`
  fragment LoanPayloadFragment on LoanPayload {
    item {
      ...LoanFragment
    }
    financials {
      ...FinancialsFragment
    }
    forecasts {
      ...FinancialForecastsFragment
    }
  }
  ${LoanFragment}
  ${FinancialsFragment}
  ${FinancialForecastsFragment}
`

export const AssetPayloadFragment = gql`
  fragment AssetPayloadFragment on AssetPayload {
    item {
      ...AssetFragment
    }
    financials {
      ...FinancialsFragment
    }
    forecasts {
      ...FinancialForecastsFragment
    }
  }
  ${AssetFragment}
  ${FinancialsFragment}
  ${FinancialForecastsFragment}
`

export const LineOfCreditPayloadFragment = gql`
  fragment LineOfCreditPayloadFragment on LineOfCreditPayload {
    item {
      ...LineOfCreditFragment
    }
    financials {
      ...FinancialsFragment
    }
    forecasts {
      ...FinancialForecastsFragment
    }
  }
  ${LineOfCreditFragment}
  ${FinancialsFragment}
  ${FinancialForecastsFragment}
`

export const TaxPayloadFragment = gql`
  fragment TaxPayloadFragment on TaxPayload {
    item {
      ...TaxFragment
    }
    financials {
      ...FinancialsFragment
    }
    forecasts {
      ...FinancialForecastsFragment
    }
  }
  ${TaxFragment}
  ${FinancialsFragment}
  ${FinancialForecastsFragment}
`

export const AssumptionsPayloadFragment = gql`
  fragment AssumptionsPayloadFragment on AssumptionsPayload {
    item {
      ...AssumptionsFragment
    }
    financials {
      ...FinancialsFragment
    }
    forecasts {
      ...FinancialForecastsFragment
    }
  }
  ${AssumptionsFragment}
  ${FinancialsFragment}
  ${FinancialForecastsFragment}
`

export const StartingBalancePayloadFragment = gql`
  fragment StartingBalancePayloadFragment on StartingBalancePayload {
    item {
      ...StartingBalanceFragment
    }
    financials {
      ...FinancialsFragment
    }
    forecasts {
      ...FinancialForecastsFragment
    }
  }
  ${StartingBalanceFragment}
  ${FinancialsFragment}
  ${FinancialForecastsFragment}
`

export const CompanyFragment = gql`
  fragment CompanyFragment on Company {
    _id
    id
    name
    settings {
      owner {
        _id
        id
        fullName
        email
        accountType
      }
      industry
      currency
      forecastStart
      forecastLength
      logoId
      canvasSharingEnabled
      sharingEnabled
    }
    teamMembers {
      _id
      id
      user {
        fullName
      }
      invite {
        _id
        id
        email
        accepted
        inviteSentTime
      }
      type
    }
    plan {
      ...PlanFragment
    }
    financials {
      ...FinancialsFragment
    }
    forecasts {
      ...FinancialForecastsFragment
    }
    canvas {
      ...CanvasFragment
    }
  }
  ${PlanFragment}
  ${FinancialsFragment}
  ${FinancialForecastsFragment}
  ${CanvasFragment}
`

export const UserFragment = gql`
  fragment UserFragment on User {
    _id
    id
    fullName
    displayName
    accountType
    accountTypeFriendly
    email
    companies {
      _id
      name
      settings {
        industry
        logoId
      }
    }
    verified
    settings {
      accountImageId
    }
    lastLogin
  }
`

export const OrgFragment = gql`
  fragment OrgFragment on Org {
    _id
    id
    name
    orgKey
    admins {
      _id
      id
      fullName
      email
    }
    users {
      _id
      id
      fullName
      email
      lastLogin,
      companies {
        _id
        id
        name
      }
    }
    advisors {
      user {
        _id
        id
        fullName
        email
        lastLogin,
      }
      orgUsers {
        _id
        id
        fullName
      }
      companies {
        _id
        id
        name
      }
    }
    invites {
      _id
      id
      email
      accepted
      inviteSentTime
      accountType
    }
    settings {
      logoId
    }
  }
`

export const MeFragment = gql`
  fragment MeFragment on User {
    _id
    id
    fullName
    displayName
    accountType
    accountTypeFriendly
    email
    companies {
      ...CompanyFragment
    }
    orgs {
      ...OrgFragment
    }
    notifications {
      _id
      title
      message
      key
      type
      read
    }
    verified
    settings {
      accountImageId
      isPlatformAdmin
      freeTrialLength
      toursSeen
      soundEffectsOn
    }
  }
  ${CompanyFragment}
  ${OrgFragment}
`

export const UserPayloadFragment = gql`
  fragment UserPayloadFragment on UserPayload {
    ok
    me {
      ...MeFragment
    }
  }
  ${MeFragment}
`

export const TicketFragment = gql`
  fragment Ticket on Ticket {
    _id
    created
    user {
      _id
    }
    url
    error
    type
  }
`