import { defineMessages } from 'react-intl'

const employees = defineMessages({
  raisesLabel: {
    id: 'employees.raisesLabel',
    defaultMessage: 'Do you want to include annual raises?'
  },
  raisesDescription: {
    id: 'employees.raisesDescription',
    defaultMessage: 'Normally, a successful employee will earn a pay raise over time. To factor in annual raises, establish an annual percentage increase here. If not, leave it at zero.'
  },
  salaryLabel: {
    id: 'employees.salaryLabel',
    defaultMessage: 'How do you want to enter their salary?'
  },
  salary: {
    id: 'employees.salary',
    defaultMessage: 'Salary'
  },
  individual: {
    id: 'employees.individual',
    defaultMessage: 'Individual'
  },
  group: {
    id: 'employees.group',
    defaultMessage: 'Group'
  },
  howManyEmployeesInStartingGroup: {
    id: 'employees.howManyEmployeesInStartingGroup',
    defaultMessage: 'How many employees are in this starting group?'
  },
  employeeNameLabel: {
    id: 'employees.employeeNameLabel',
    defaultMessage: 'What do you want to call them?'
  },
  roleLabel: {
    id: 'employees.roleLabel',
    defaultMessage: 'Is this an on-staff role?'
  },
  roleDescription: {
    id: 'employees.roleDescription',
    defaultMessage: `This is important to make clear because the burden rate (which covers payroll taxes and benefits will not be applied to contract workers.`
  },
  fullTime: {
    id: 'employees.fullTime',
    defaultMessage: 'Full time'
  },
  contract: {
    id: 'employees.contract',
    defaultMessage: 'Contract'
  },
  burdenRateLabel: {
    id: 'employees.burdenRateLabel',
    defaultMessage: 'Change the current burden rate assumption value?'
  },
  burdenRateDescription: {
    id: 'employees.burdenRateDescription',
    defaultMessage: 'The burden rate on employees is the costs that are related to your employees that are not included in their salaries such as payroll taxes and benefits. You can update this value here and also in the assumptions area of the financials along with other assumptions driving your financial projections.'
  },
  regular: {
    id: 'employees.regular',
    defaultMessage: 'Regular'
  },
  direct: {
    id: 'employees.direct',
    defaultMessage: 'Direct'
  },
})

export default employees
