import { schema, normalize } from 'normalizr'
import { omit } from 'lodash'

const makePartTypesKeyId = (value, parent, key) => {
  let newAmounts = {}

  if (value.amounts) {
    value.amounts.map(amount => {
      return (newAmounts[amount.partType] = amount)
    })

    const cleanValues = omit(value, ['amounts'])

    const newValue = {
      ...cleanValues,
      ...newAmounts,
    }

    return newValue
  } else {
    return value
  }
}

const makeResourceId = (value, parent, key) => {
  let newAmounts = {}

  value.forEach(lock => {
    return (newAmounts[lock.resourceId] = lock)
  })

  return newAmounts
}

const lock = new schema.Entity(
  'locks',
  {},
  {
    processStrategy: makeResourceId,
  },
)

export const normalizeLock = item => {
  const data = normalize(item, lock)

  return data.entities.locks[item.resourceId]
}

const card = new schema.Entity('cards', {})

const chapter = new schema.Entity('chapters', {
  cards: [card],
})

export const normalizeChapter = item => {
  const data = normalize(item, chapter)

  return data.entities.chapters[item.id]
}

const plan = new schema.Entity('plans', {
  chapters: [chapter],
})

export const normalizePlan = item => {
  const data = normalize(item, plan)

  return {
    id: data.result,
    chapters: data.entities.chapters,
    cards: data.entities.cards,
  }
}

const company = new schema.Entity('companies', {})
const org = new schema.Entity('orgs', {})

const user = new schema.Entity('users', {
  companies: [company],
  orgs: [org]
})

export const normalizeUser = item => {
  const data = normalize(item, user)

  return {
    ...item,
    companies: data.entities.companies,
    orgs: data.entities.orgs,
  }
}

export const normalizeCompanies = item => {
  const data = normalize(item, company)

  return data.entities.companies
}

const revenue = new schema.Entity(
  'revenues',
  {},
  {
    processStrategy: makePartTypesKeyId,
  },
)

export const normalizeRevenue = item => {
  const data = normalize(item, revenue)

  return data.entities.revenues[item._id]
}

const expense = new schema.Entity(
  'expenses',
  {},
  {
    processStrategy: makePartTypesKeyId,
  },
)

export const normalizeExpense = item => {
  const data = normalize(item, expense)

  return data.entities.expenses[item._id]
}

const cost = new schema.Entity(
  'costs',
  {},
  {
    processStrategy: makePartTypesKeyId,
  },
)

export const normalizeCost = item => {
  const data = normalize(item, cost)

  return data.entities.costs[item._id]
}

const employee = new schema.Entity(
  'employees',
  {},
  {
    processStrategy: makePartTypesKeyId,
  },
)

export const normalizeEmployee = item => {
  const data = normalize(item, employee)

  return data.entities.employees[item._id]
}

const investment = new schema.Entity(
  'investments',
  {},
  {
    processStrategy: makePartTypesKeyId,
  },
)

export const normalizeInvestment = item => {
  const data = normalize(item, investment)

  return data.entities.investments[item._id]
}

const dividend = new schema.Entity(
  'dividends',
  {},
  {
    processStrategy: makePartTypesKeyId,
  },
)

export const normalizeDividend = item => {
  const data = normalize(item, dividend)

  return data.entities.dividends[item._id]
}

const asset = new schema.Entity(
  'assets',
  {},
  {
    processStrategy: makePartTypesKeyId,
  },
)

export const normalizeAsset = item => {
  const data = normalize(item, asset)

  return data.entities.assets[item._id]
}

const lineOfCredit = new schema.Entity(
  'linesOfCredit',
  {},
  {
    processStrategy: makePartTypesKeyId,
  },
)

export const normalizeLineOfCredit = item => {
  const data = normalize(item, lineOfCredit)

  return data.entities.linesOfCredit[item._id]
}

const loan = new schema.Entity('loans', {})

export const normalizeLoan = item => {
  const data = normalize(item, loan)

  return data.entities.loans[item._id]
}

const financials = new schema.Entity('financials', {
  revenues: [revenue],
  expenses: [expense],
  costs: [cost],
  employees: [employee],
  assets: [asset],
  loans: [loan],
  dividends: [dividend],
  investments: [investment],
  linesOfCredit: [lineOfCredit],
})

export const normalizeFinancials = item => {
  const data = normalize(item, financials)

  return {
    id: data.result,
    revenues: data.entities.revenues,
    expenses: data.entities.expenses,
    costs: data.entities.costs,
    employees: data.entities.employees,
    assets: data.entities.assets,
    loans: data.entities.loans,
    dividends: data.entities.dividends,
    investments: data.entities.investments,
    linesOfCredit: data.entities.linesOfCredit,
    taxes: item.taxes,
    startingBalance: item.startingBalance,
    assumptions: item.assumptions,
  }
}

const ticketSchema = new schema.Entity('tickets', {}, { idAttribute: '_id' })

const ticketListSchema = [ticketSchema]

export const normalizeTickets = (tickets) => {
  const data = normalize(tickets, ticketListSchema)

  return data.entities.tickets
}

export default {
  normalizeChapter,
  normalizePlan,
  normalizeFinancials,
  normalizeEmployee,
  normalizeCost,
  normalizeRevenue,
  normalizeExpense,
  normalizeAsset,
  normalizeLineOfCredit,
  normalizeLoan,
  normalizeInvestment,
  normalizeDividend,
  normalizeUser,
  normalizeTickets,
}
