import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { GRAPHQL_URL } from '../config'

const httpLink = createHttpLink({
  uri: GRAPHQL_URL
})

const authMiddleware = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token')
  const refreshToken = localStorage.getItem('refreshToken')

  if (token && refreshToken) {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        'x-token': token,
        'x-refresh-token': refreshToken
      }
    }
  } else {
    return {
      headers
    }
  }
})

const authAfterware = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext()
    const { response: { headers } } = context

    if (headers) {
      const token = headers.get('x-token')
      const refreshToken = headers.get('x-refresh-token')

      if (token) {
        localStorage.setItem('token', token)
      }

      if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken)
      }

    }
    return response
  });
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map((error) => {
      if (error.name === 'AuthenticationRequiredError' || error.message === 'You must be logged in to do this.') {
        window.location.href = '/signout'
      }

      if (error.message === 'You do not have sufficient permissions on this org to take this action.') {
        window.location.href = '/dashboard?not_authorized=true'
      }
      return console.error(`[GraphQl error]: ${error.message}`)
    })
  }

  if (networkError) console.error(`[Network error]: ${networkError}`)
})


const createOmitTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
  }

  return forward(operation)
})

function omitTypename(key, value) {
  return key === '__typename' ? undefined : value
}



const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authMiddleware.concat(authAfterware).concat(errorLink).concat(createOmitTypenameLink).concat(httpLink)
})

export default client